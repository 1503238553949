import React, { useRef, useEffect } from 'react';
import MyMonthInput from "./MyMonthInput";
import SearchResult from "./SearchResult";
import Select from 'react-select';
import {
    MDBInput,
    MDBIcon,
    MDBInputGroup,
    MDBCheckbox,
    MDBTextArea,
} from "mdb-react-ui-kit";

function ExperiencesComponent({
    experiences,
    addNewAction,
    moveExperienceUp,
    moveExperienceDown,
    removeExperience,
    handleExperienceChange,
    calculateNextMonthDate,
    checkboxStates,
    handleCheckboxChange,
    handleExperienceActionChange,
    handleRemoveAction,
    competenceOptions,
    searchTerm,
    setSearchTerm,
    searchResults,
    activeSuggestionIndex,
    handleMouseEnter,
    handleKeyDown,
    addSelectedCompetence,
    translations,
    textColor,
    moveAction,
}) {
    return (
        <>
            {experiences.map((experience, index) => {
                const minDateForDateFin = calculateNextMonthDate(experience.dateDebut);
                return (
                    <div key={experience.id}>
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-end">
                                <div className="d-flex align-items-center">
                                    {index !== 0 && (
                                        <MDBIcon
                                            fas
                                            icon="arrow-up"
                                            className="m-1"
                                            onClick={() => moveExperienceUp(index)}
                                            role="button"
                                            title="Remonter l'expérience"
                                        />
                                    )}
                                    {index !== experiences.length - 1 && (
                                        <MDBIcon
                                            fas
                                            icon="arrow-down"
                                            className="m-1"
                                            onClick={() => moveExperienceDown(index)}
                                            role="button"
                                            title="Descendre l'expérience"
                                        />
                                    )}
                                </div>
                                <MDBIcon
                                    far
                                    icon="trash-alt"
                                    className={`m-1 ${textColor || "text-white"
                                        }`}
                                    onClick={() => removeExperience(index)}
                                    role="button"
                                    size="lg"
                                />
                            </div>
                            <div className="col-4">
                                <div>
                                    <label>{translations.labels.company}</label>
                                    <MDBInput
                                        type="text"
                                        className="form-control-input"
                                        value={experience.societe || ""}
                                        onChange={(event) => handleExperienceChange(event, index, "societe")}
                                    />
                                </div>
                                <div>
                                    <label>{translations.labels.sector}</label>
                                    <select
                                        type="text"
                                        className={`form-select select-input ${!experience.secteur ? 'border-danger' : ''}`}
                                        value={experience.secteur || ""}
                                        onChange={(event) =>
                                            handleExperienceChange(
                                                event,
                                                index,
                                                "secteur"
                                            )
                                        }
                                    >
                                        <option value="">{translations.selectAnOption}</option>
                                        <option value="Agroalimentaire">{translations.sectorActivite.Agroalimentaire}</option>
                                        <option value="Aeronautique">{translations.sectorActivite.Aeronautique}</option>
                                        <option value="ArtsSpectacle">{translations.sectorActivite.ArtsSpectacle}</option>
                                        <option value="Assurance">{translations.sectorActivite.Assurance}</option>
                                        <option value="Automobile">{translations.sectorActivite.Automobile}</option>
                                        <option value="Banque">{translations.sectorActivite.Banque}</option>
                                        <option value="BTP">{translations.sectorActivite.BTP}</option>
                                        <option value="Distribution">{translations.sectorActivite.Distribution}</option>
                                        <option value="Energies">{translations.sectorActivite.Energies}</option>
                                        <option value="Enseignement">{translations.sectorActivite.Enseignement}</option>
                                        <option value="Etudes">{translations.sectorActivite.Etudes}</option>
                                        <option value="Services">{translations.sectorActivite.Services}</option>
                                        <option value="Immobilier">{translations.sectorActivite.Immobilier}</option>
                                        <option value="Pharmaceutique">{translations.sectorActivite.Pharmaceutique}</option>
                                        <option value="Informatiques">{translations.sectorActivite.Informatiques}</option>
                                        <option value="InstitutionsEuropeennes">{translations.sectorActivite.InstitutionsEuropeennes}</option>
                                        <option value="Logistique">{translations.sectorActivite.Logistique}</option>
                                        <option value="Industries">{translations.sectorActivite.Industries}</option>
                                        <option value="Sante">{translations.sectorActivite.Sante}</option>
                                        <option value="SecteurPublic">{translations.sectorActivite.SecteurPublic}</option>
                                        <option value="Restauration">{translations.sectorActivite.Restauration}</option>
                                        <option value="Tourisme">{translations.sectorActivite.Tourisme}</option>
                                        <option value="Transport">{translations.sectorActivite.Transport}</option>
                                    </select>
                                </div>
                                <div>
                                    <label>{translations.labels.location}</label>
                                    <MDBInput
                                        type="text"
                                        className="form-control-input"
                                        value={experience.lieu || ""}
                                        onChange={(event) =>
                                            handleExperienceChange(
                                                event,
                                                index,
                                                "lieu"
                                            )
                                        }
                                    />
                                </div>
                                <div className="label-input-container">
                                    <label>{translations.labels.startDate}</label>
                                    <MyMonthInput
                                        className="transparent-input"
                                        value={experience?.dateDebut ?? ""}
                                        onChange={(event) =>
                                            handleExperienceChange(
                                                event,
                                                index,
                                                "dateDebut"
                                            )
                                        }
                                    />
                                    <label>{translations.labels.endDate}</label>
                                    <MyMonthInput
                                        className={`transparent-input ${experiences[index]?.isCurrentJob ? 'disabled-input' : ''}`}
                                        value={experience?.dateFin ?? ""}
                                        onChange={(event) =>
                                            handleExperienceChange(
                                                event,
                                                index,
                                                "dateFin"
                                            )
                                        }
                                        min={minDateForDateFin ? minDateForDateFin.toISOString().split('T')[0] : undefined}
                                        disabled={experiences[index]?.isCurrentJob ?? false}
                                    />
                                    <div>
                                        <input
                                            className="me-1"
                                            id={`currentJob-${index}`}
                                            type="checkbox"
                                            checked={experiences[index]?.isCurrentJob ?? false}
                                            onChange={(event) => handleExperienceChange(event, index, "isCurrentJob")}
                                        />
                                        <label htmlFor={`currentJob-${index}`}>{translations.labels.now}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8">
                                <div>
                                    <label>{translations.labels.position}</label>
                                    <MDBInput
                                        type="text"
                                        className="form-control-input"
                                        value={experience.poste || ""}
                                        onChange={(event) =>
                                            handleExperienceChange(
                                                event,
                                                index,
                                                "poste"
                                            )
                                        }
                                    />
                                    {checkboxStates[experience.id] && (
                                        <div>
                                            <label>Client</label>
                                            <MDBInput
                                                type="text"
                                                className="form-control-input"
                                                placeholder="Client"
                                                value={experience.client || ""}
                                                onChange={(event) =>
                                                    handleExperienceChange(event, index, "client")
                                                }
                                            />
                                        </div>
                                    )}
                                    <MDBCheckbox
                                        name='flexCheck'
                                        value=''
                                        id={`flexCheckDefault-${experience.id}`}
                                        label="S'agit-il d'une mission de consulting"
                                        checked={checkboxStates[experience.id]}
                                        onChange={() => handleCheckboxChange(experience.id)}
                                    />
                                </div>
                                <div>
                                    <label>{translations.labels.contractType}</label>
                                    <select
                                        type="text"
                                        className={`form-select select-input ${!experience.typeContrat ? 'border-danger' : ''}`}
                                        value={experience.typeContrat || ""}
                                        onChange={(event) => handleExperienceChange(event, index, "typeContrat")}
                                    >
                                        <option value="">{translations.selectAnOption}</option>
                                        <option value="CDI">{translations.contractTypes.CDI}</option>
                                        <option value="CDD">{translations.contractTypes.CDD}</option>
                                        <option value="Stage">{translations.contractTypes.Stage}</option>
                                        <option value="Alternance">
                                            {translations.contractTypes.Alternance}
                                        </option>
                                        <option value="Freelance">{translations.contractTypes.Freelance}</option>
                                    </select>
                                </div>
                                <div>
                                    <span title={translations.infobulle.expContext}>
                                        <label>{translations.labels.context}</label>
                                        <i className="fas fa-info-circle ms-1"></i>
                                    </span>
                                    <MDBTextArea
                                        type="text"
                                        className="form-control-input"
                                        value={experience.contexte || ""}
                                        onChange={(event) =>
                                            handleExperienceChange(
                                                event,
                                                index,
                                                "contexte"
                                            )
                                        }

                                    />
                                </div>
                                <div>
                                    <span title={translations.infobulle.expAchieve}>
                                        <label>{translations.labels.realisation}</label>
                                        <i className="fas fa-info-circle ms-1"></i>
                                    </span>
                                    <MDBTextArea
                                        type="text"
                                        className="form-control-input"
                                        value={experience.realisation || ""}
                                        onChange={(event) =>
                                            handleExperienceChange(
                                                event,
                                                index,
                                                "realisation"
                                            )
                                        }

                                    />
                                </div>
                                <div>
                                    <span>
                                        <label>{translations.labels.responsibilities} </label>
                                        <i className="fas fa-info-circle ms-1" title={translations.infobulle.expActions}></i>
                                    </span>
                                    <div>
                                        {experience.actions?.map(
                                            (action, actionIndex) => (
                                                <div
                                                    key={actionIndex}
                                                    className="d-flex mb-2 input-acv"
                                                >
                                                    <MDBTextArea
                                                        type="text"
                                                        className="form-control-input"
                                                        value={action || ""}
                                                        placeholder={`Action ${actionIndex + 1}`}
                                                        onChange={(event) =>
                                                            handleExperienceActionChange(
                                                                event,
                                                                index,
                                                                actionIndex
                                                            )
                                                        }
                                                    />
                                                    <div className="icons-container d-flex align-items-center" style={{ marginLeft: 'auto', width: '50px' }}>
                                                        <MDBIcon
                                                            far
                                                            icon="trash-alt"
                                                            className="m-2"
                                                            role="button"
                                                            onClick={() => handleRemoveAction(index, actionIndex)}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        {actionIndex > 0 && (
                                                            <MDBIcon
                                                                fas
                                                                icon="arrow-up"
                                                                className="m-1"
                                                                role="button"
                                                                onClick={() => moveAction(index, actionIndex, -1)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        )}
                                                        {actionIndex < experience.actions.length - 1 && (
                                                            <MDBIcon
                                                                fas
                                                                icon="arrow-down"
                                                                className="m-1"
                                                                role="button"
                                                                onClick={() => moveAction(index, actionIndex, 1)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                        {/* <MDBIcon
                                            fas
                                            icon={"plus-circle"}
                                            className="mt-2 ms-2"
                                            onClick={(event) =>
                                                addNewAction(event, index)
                                            }
                                            role="button"
                                            type="button"
                                            title={translations.labels.addAction}
                                        /> */}
                                        <button className="btn_primary" onClick={(event) =>
                                            addNewAction(event, index)
                                        }>
                                            <MDBIcon fas icon="plus-circle" color='light' size='sm' />
                                            <span className='ms-1'>{translations.labels.addAction}</span>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <span title={translations.infobulle.expEnv}>
                                        <label>{translations.labels.techEnvironment}</label>
                                        <i className="fas fa-info-circle ms-1"></i>
                                    </span>
                                    <Select
                                        isMulti
                                        value={
                                            Array.isArray(experience.envTechnique)
                                                ? experience.envTechnique.map(techName => ({
                                                    label: techName,
                                                    value: techName
                                                }))
                                                : []
                                        }
                                        onChange={(selectedOptions) =>
                                            handleExperienceChange(
                                                selectedOptions,
                                                index,
                                                'envTechnique'
                                            )
                                        }
                                        options={competenceOptions.map(option => ({ value: option.name, label: option.name }))}
                                        classNamePrefix="select"
                                        styles={{
                                            multiValue: (base) => ({
                                                ...base,
                                                backgroundColor: '#09B4BF',
                                                borderRadius: '10px',
                                            }),
                                            multiValueLabel: (base) => ({
                                                ...base,
                                                color: 'white',
                                            }),
                                            control: (base) => ({
                                                ...base,
                                                background: "transparent",
                                                borderColor: !experience.envTechnique ? 'red' : "#cccccc",
                                                boxShadow: "none",
                                                "&:hover": {
                                                    borderColor: "#666666"
                                                }
                                            }),
                                            input: (base) => ({
                                                ...base,
                                                color: "white",
                                            }),
                                            option: (base, state) => ({
                                                ...base,
                                                backgroundColor: state.isFocused ? "#09B4BF" : "white",
                                                color: state.isFocused ? "white" : "black"
                                            })
                                        }}
                                    />
                                    <div style={{ position: "relative" }}>
                                        <div className="d-flex gap-1 pt-3">
                                            {translations.labels.skillsOptions}
                                            <MDBInputGroup className='mb-3' noBorder textBefore={<MDBIcon fas icon='search' className='text-white' />}>
                                                <input className='form-control text-white search-skills' style={{ backgroundColor: 'transparent' }} type='text' placeholder={translations.labels.skillsRecherche} value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                    onKeyDown={handleKeyDown} />
                                            </MDBInputGroup>
                                        </div>
                                        <div className="suggestions-box-edition" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                            {searchTerm && searchResults.map((result, index) => (
                                                <SearchResult key={result.id} result={result} onSelect={addSelectedCompetence} isActive={index === activeSuggestionIndex} onMouseEnter={() => handleMouseEnter(index)} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {index !== experiences.length - 1 && (
                            <hr className="border border-5" />
                        )}
                    </div>
                );
            })}
        </>
    );
}

export default React.memo(ExperiencesComponent);
