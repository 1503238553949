import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Link } from 'react-router-dom';
import Parallax from './Parallax';

const Error404Page = () => {
  return (
    <MDBContainer className="my-5">
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="6" className="text-center">
          <h1 className="fw-bold my-4">Erreur 403</h1>
        <Parallax>
          <img src="/bg/error.png" height="300"
            alt="cv"
            loading="lazy" className="animation-apparition-gauche" style={{ filter: 'brightness(120%)' }} />
         </Parallax>
          <p className="lead">Accès refusé</p>
          <Link to="/mon-compte">
            <MDBBtn className="BtnCard">Retourner à mon compte</MDBBtn>
          </Link>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Error404Page;
