import React from "react";
import { useHistory } from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardHeader,
  MDBCardFooter,
} from "mdb-react-ui-kit";
import { useDarkMode } from '../../components/assets/DarkModeContext';
import Parallax from '../../components/assets/Parallax';

export default function TestsTechnique() {
  const history = useHistory();
  const { isDarkMode } = useDarkMode();

  const handleClick = () => {
    history.push("/tests-compliance");
  };

  return (
    <MDBContainer fluid className={isDarkMode ? 'bg-dark-mode' : 'bg-light'}>
      <MDBContainer className="animation-fade-in pb-5">
        <div
          className="p-5 bg-image"
          style={{
            height: "300px",
            backgroundImage: 'url("./bg/bg-accueil-aco.jpg")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
          }}
        ></div>
        <div className="text-center justify-content-center align-items-center mt-5">
          <h1 className={isDarkMode ? 'text-white' : ''}>Tests Techniques</h1>
          <div>
            <img className="loader-image" src="/spinner-alcyone.png" alt="Loading..." />
            <h2 className={isDarkMode ? 'text-white' : ''}>
              Bientôt disponible
            </h2>
          </div>
          <Parallax speed={0.05}>
            <img src="/bg/error.png" height="300"
              alt="cv"
              loading="lazy" className="animation-apparition-gauche" style={{ filter: 'brightness(120%)' }} />
          </Parallax>
        </div>
        <section className="d-flex justify-content-around my-4 row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <MDBCard className="h-100" alignment="center">
              <MDBCardHeader className="fw-bold" style={{ color: "#09B4BF" }}>Développement full stack</MDBCardHeader>
              <MDBCardBody>
                <MDBCardTitle>Test technique IT</MDBCardTitle>
                <MDBCardText>
                  Évaluez vos compétences techniques en informatique avec notre série de tests sur la programmation, les réseaux, le support, etc.
                </MDBCardText>
                <MDBBtn className="BtnCard" disabled>Commencer</MDBBtn>
              </MDBCardBody>
              <MDBCardFooter className="text-muted">25 questions</MDBCardFooter>
            </MDBCard>
          </div>
          <div className="col">
            <MDBCard className="h-100" alignment="center">
              <MDBCardHeader className="fw-bold" style={{ color: "#09B4BF" }}>Compliance</MDBCardHeader>
              <MDBCardBody>
                <MDBCardTitle>Test de conformité</MDBCardTitle>
                <MDBCardText>
                  Testez votre connaissance des réglementations et des normes de conformité en vigueur dans votre domaine d'activité.
                </MDBCardText>
                <MDBBtn className="BtnCard mt-4" disabled onClick={handleClick}>Commencer</MDBBtn>
              </MDBCardBody>
              <MDBCardFooter className="text-muted">25 questions</MDBCardFooter>
            </MDBCard>
          </div>
          <div className="col">
            <MDBCard className="h-100" alignment="center">
              <MDBCardHeader className="fw-bold" style={{ color: "#09B4BF" }}>Régulation financière</MDBCardHeader>
              <MDBCardBody>
                <MDBCardTitle>Test de gestion de projets</MDBCardTitle>
                <MDBCardText>
                  Évaluez votre aptitude à gérer des projets, depuis la planification jusqu'à l'exécution, en passant par la gestion des ressources et la résolution de problèmes.
                </MDBCardText>
                <MDBBtn className="BtnCard" disabled>Commencer</MDBBtn>
              </MDBCardBody>
              <MDBCardFooter className="text-muted">25 questions</MDBCardFooter>
            </MDBCard>
          </div>
          <div className="col">
            <MDBCard className="h-100" alignment="center">
              <MDBCardHeader className="fw-bold" style={{ color: "#09B4BF" }}>Pilotage, Analyse et Testing</MDBCardHeader>
              <MDBCardBody>
                <MDBCardTitle>Test de gestion de projets</MDBCardTitle>
                <MDBCardText>
                  Évaluez votre aptitude à gérer des projets, depuis la planification jusqu'à l'exécution, en passant par la gestion des ressources et la résolution de problèmes.
                </MDBCardText>
                <MDBBtn className="BtnCard" disabled>Commencer</MDBBtn>
              </MDBCardBody>
              <MDBCardFooter className="text-muted">25 questions</MDBCardFooter>
            </MDBCard>
          </div>
          <div className="col">
            <MDBCard className="h-100" alignment="center">
              <MDBCardHeader className="fw-bold" style={{ color: "#09B4BF" }}>Data Science</MDBCardHeader>
              <MDBCardBody>
                <MDBCardTitle>Test de gestion de projets</MDBCardTitle>
                <MDBCardText>
                  Évaluez votre aptitude à gérer des projets, depuis la planification jusqu'à l'exécution, en passant par la gestion des ressources et la résolution de problèmes.
                </MDBCardText>
                <MDBBtn className="BtnCard" disabled>Commencer</MDBBtn>
              </MDBCardBody>
              <MDBCardFooter className="text-muted">25 questions</MDBCardFooter>
            </MDBCard>
          </div>
          <div className="col">
            <MDBCard className="h-100" alignment="center">
              <MDBCardHeader className="fw-bold" style={{ color: "#09B4BF" }}>Infrastructure</MDBCardHeader>
              <MDBCardBody>
                <MDBCardTitle>Test de gestion de projets</MDBCardTitle>
                <MDBCardText>
                  Évaluez votre aptitude à gérer des projets, depuis la planification jusqu'à l'exécution, en passant par la gestion des ressources et la résolution de problèmes.
                </MDBCardText>
                <MDBBtn className="BtnCard" disabled>Commencer</MDBBtn>
              </MDBCardBody>
              <MDBCardFooter className="text-muted">25 questions</MDBCardFooter>
            </MDBCard>
          </div>
        </section>
      </MDBContainer>
    </MDBContainer>
  );
}
