import React from "react";

import {
    MDBContainer,
    MDBIcon,
    MDBCard
} from "mdb-react-ui-kit";
import { useDarkMode } from '../../components/assets/DarkModeContext';
import Parallax from '../../components/assets/Parallax';

export default function Mentions() {
    const { isDarkMode } = useDarkMode();

    return (
        <MDBContainer fluid className={isDarkMode ? 'bg-dark-mode' : 'bg-light'}>
            <MDBContainer className="pb-5">
                <section className="bg-nav animation-fade-in">
                    <div
                        className="p-5 bg-image"
                        style={{
                            height: "300px",
                            backgroundImage: 'url("./bg/creation-cv.jpg")',
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "bottom",
                            backgroundAttachment: "fixed",
                        }}
                    ></div>
                    <MDBCard className="h-100 p-5" alignment="center">
                        <MDBIcon fas icon="gavel" size="3x" style={{ color: "#09B4BF" }} />
                        <div>
                            <h1 className="text-center text-muted pt-2">
                                Conditions générales d'utilisation (CGU) du site cv.alcyone-consulting.lu
                            </h1>
                        </div>
                        <div className="pt-3">
                            <p className="text-start">
                                Toute personne faisant usage des informations, documents, produits, logiciels et divers services (collectivement les "Services") proposés par le Site est réputée avoir pris connaissance et accepté l'intégralité des termes et mentions des présentes conditions générales d'utilisation.
                            </p>
                            <p className="text-start fw-bold">Définitions et objet</p>
                            <p className="text-start">cv.alcyone-consulting.lu (ci-après dénommé "Site") est un site Internet mis en œuvre par Alcyone Consulting (ci-après dénommée la "Société") ayant pour but la gestion de ses processus de recrutement.</p>
                            <p className="text-start">Société propriétaire du Site :</p>
                            <ul className="text-start">
                                <li>
                                    Nom : Alcyone Consulting
                                </li>
                                <li>
                                    Statut juridique : SàRL
                                </li>
                                <li>
                                    Adresse : 2-8 rue Julien Vesque, L-2668 Luxembourg - LUXEMBOURG
                                </li>
                                <li>
                                    Tél : +352 27 86 17 46
                                </li>
                                <li>
                                    E-mail : contact@alcyone-consulting.lu
                                </li>
                                <li>
                                    TVA :
                                </li>
                                <li>
                                    RCS : B 133 438
                                </li>
                            </ul>
                            <p className="text-start">- Créateur :</p>
                            <ul className="text-start">
                                <li>
                                    Alcyone Consulting
                                </li>
                            </ul>
                            <p className="text-start">- Hébergeur :</p>
                            <ul className="text-start">
                                <li>
                                    OVH SAS – 2 rue Kellermann, 59100 ROUBAIX – France
                                </li>
                            </ul>
                            <p className="text-start">- Webmaster : Alexis COLOMBI – contact@alcyone-consulting.lu</p>
                            <p className="text-start">- Responsable de publication : Alexis COLOMBI – contact@alcyone-consulting.lu</p>
                            <p className="text-start fw-bold">Obligations de l'usager</p>
                            <p className="text-start">
                                L'accès au Site se fait par Internet. L'usager déclare en connaître les risques et les accepter. Il doit se prémunir contre les effets de la piraterie informatique en adoptant une configuration informatique adaptée et sécurisée.
                            </p>
                            <p className="text-start">
                                La Société ne saurait être tenue pour responsable d'un quelconque dommage que l'usager subirait directement ou indirectement en relation avec sa navigation sur le Site et l'utilisation de ses services ainsi que les sites Internet auxquels il renvoie.
                            </p>
                            <p className="text-start fw-bold">Gestion des cookies</p>
                            <p className="text-start">
                                Le Site utilise des cookies clients, des petits fichiers texte, pour permettre d'analyser les informations relatives à la navigation de l'utilisateur (cookies de traçage via Google Analytics : fréquences des visites, durée des visites, pages consultées, préférences linguistiques, statistiques, etc.) et fournir à l’utilisateur une meilleure expérience de navigation (cookies fonctionnels nécessaires au bon fonctionnement du Site). Les cookies sont déposés par le Site dans un répertoire prévu à cet effet sur l'ordinateur de l'utilisateur. Ils peuvent aussi être installés par des tiers avec lesquels la Société collabore. Ils contiennent notamment le nom du serveur qui l'a écrit, un identifiant sous forme de numéro unique et une date d'expiration. L'identifiant unique permet au Site de reconnaître l'ordinateur de l'utilisateur à chaque visite. Les cookies de session sont supprimés de l'ordinateur de l'utilisateur lorsque sa session est fermée. Toutefois les cookies persistants restent sur l'ordinateur de l'utilisateur [1 mois] après la fin de la session.
                            </p>
                            <p className="text-start">
                                L'utilisateur peut lui-même décider si le serveur web du Site est autorisé ou non à mémoriser des cookies sur son ordinateur. Il a, à tout moment, le choix de régler les options de son navigateur afin que les cookies ne soient acceptés et mémorisés. Par ailleurs, il peut, à tout moment, effacer, via le navigateur, des cookies déjà mémorisés.
                            </p>
                            <p className="text-start">
                                L'utilisation de certaines fonctionnalités du Site peut être limitée ou désactivée si l'utilisateur refuse les cookies du site. Il est dès lors recommandé à l'utilisateur de régler son navigateur de manière à ce que les cookies du Site soient acceptés.
                            </p>
                            <p className="text-start fw-bold">Modification du Site</p>
                            <p className="text-start">
                                La Société se réserve la liberté de faire évoluer, de modifier ou de suspendre, sans préavis, le Site pour des raisons de maintenance, de mise à jour ou pour tout autre motif jugé nécessaire. La Société pourra notamment à tout moment retirer, ajouter, compléter ou préciser tout ou une partie des informations et services contenus ou proposés sur le Site. Aucune responsabilité pour un quelconque dommage direct ou indirect en relation avec de telles modifications ne pourra être retenue à l'encontre de la Société.
                            </p>
                            <p className="text-start fw-bold">Limite de responsabilité</p>
                            <p className="text-start">
                                La Société assurera au mieux une disponibilité maximale du Site. Toutefois, sa responsabilité ne saurait être retenue en cas d'indisponibilité momentanée ou totale du Site.
                            </p>
                            <p className="text-start">
                                La Société assurera au mieux la sécurité du système informatique.
                            </p>
                            <p className="text-start">
                                La Société assurera au mieux l'exactitude des informations ou Services contenus sur le Site, publiés sur les réseaux sociaux ainsi que des conseils et informations fournis par le Site. Cependant, sa responsabilité ne saurait être retenue notamment, en cas d'omission de mise à jour d'une information ou d'un formulaire, en cas d'erreurs de manipulation du système ou d'encodage, d'inexactitudes, de lacunes dans les informations fournies ou d'éventuelles erreurs, inexactitudes ou omissions dans les réponses données par le Site. En effet, l'objectif est de diffuser des informations exactes et à jour provenant de diverses sources mais La Société ne saurait toutefois éviter tout risque d'erreur matérielle. Aucune des informations publiées sur le Site ou fournies par le Site en réponse à une demande individuelle n'a une vocation d'exhaustivité ou constitue un engagement de la part de La Société. Les vulgarisations et traductions sont proposées à titre informatif. Les informations qui figurent sur le Site sont de nature générale. Les informations ne sont pas adaptées à des circonstances personnelles ou spécifiques et ne peuvent donc pas être considérées comme des conseils personnels, professionnels ou juridiques à l'usager.
                            </p>
                            <p className="text-start">
                                La responsabilité du Site est expressément exclue pour toutes les conséquences directes et indirectes :
                            </p>
                            <ul className="text-start">
                                <li>
                                    Du défaut de compatibilité entre le service offert et les équipements, applications, procédures ou infrastructures de l'usager ou de tiers ;
                                </li>
                                <li>
                                    D’une faille de sécurité provenant de l'usager ou d'un tiers et plus généralement, de toute faille de sécurité non directement imputable au Site ;
                                </li>
                                <li>
                                    Des conséquences des erreurs et/ou des fraudes commises par l'usager ou un tiers ;
                                </li>
                                <li>
                                    D’une éventuelle indisponibilité ou d'un mauvais fonctionnement des systèmes ou réseaux de communications électroniques.
                                </li>
                            </ul>
                            <p className="text-start">
                                Les données transmises à des tiers via des formulaires téléchargeables sur le Site, restent de la responsabilité de l'usager et de celle du destinataire dès lors que les données ont été transmises.
                            </p>
                            <p className="text-start">
                                La Société se réserve le droit de résilier, sans préavis, ni indemnité d'aucune sorte, l'accès à tout espace personnel sur le Site faisant l'objet d'une utilisation illicite ou frauduleuse.
                            </p>
                            <p className="text-start">
                                La Société se réserve encore tous droits et notamment celui de refuser, supprimer ou restreindre, sans préavis, ni indemnité d'aucune sorte, l'accès au Site ainsi qu'à ses services à toute personne, physique ou morale, faisant un usage abusif, illicite ou frauduleux de ces services.
                            </p>
                            <p className="text-start">
                                La Société ne peut être tenue responsable pour avoir collecté et traité des informations et données personnelles de mineurs de moins de 16 ans dont le consentement n’est pas effectivement couvert par celui de leurs représentants légaux ou pour des données incorrectes -notamment concernant l’âge- introduites par des mineurs. En aucun cas, des données personnelles ne seront traitées par le Responsable de traitement si l’usager précise qu’il est âgé de moins de 16 ans.
                            </p>
                            <p className="text-start fw-bold">Liens vers sites liés</p>
                            <p className="text-start">
                                Pour la convenance des usagers, le Site peut contenir des liens vers d'autres sites qui peuvent leur être utiles ou qui peuvent les intéresser. La Société ne contrôle pas systématiquement le contenu de ces sites. Elle ne saurait dès lors être tenue responsable des contenus présentés sur ces sites Internet et ce, que ce soit au niveau de leur légalité ou de l'exactitude des informations qui s'y trouvent.
                            </p>
                            <p className="text-start fw-bold">Propriété intellectuelle</p>
                            <p className="text-start">
                                Le Site, tous ses éléments (y compris la mise en page) ainsi que les informations et Services sont protégés par les lois sur la propriété intellectuelle et sur les droits d'auteur.
                            </p>
                            <p className="text-start">
                                Sauf indication contraire, la Société n'accorde aucune licence ou autorisation relative aux droits de propriété intellectuelle qu'elle a sur le Site, ses éléments ou les Services. De plus, aucune reproduction des informations ou Services, totale ou partielle, sous quelque forme que ce soit et par quelque moyen que ce soit, n'est permise sans l'autorisation écrite préalable de la Société.
                            </p>
                            <p className="text-start">
                                Sauf indication contraire, l'usager est autorisé à consulter, télécharger et imprimer les documents et informations disponibles aux conditions suivantes :
                            </p>
                            <ul className="text-start">
                                <li>
                                    Les documents ne peuvent être utilisés qu'à titre personnel, pour information et dans un cadre strictement privé ;
                                </li>
                                <li>
                                    Les documents et informations ne peuvent être modifiés de quelque manière que ce soit ;
                                </li>
                                <li>
                                    Les documents et informations ne peuvent être diffusés en dehors du Site.
                                </li>
                            </ul>
                            <p className="text-start">
                                Les droits qui sont implicitement ou explicitement accordés à l’usager ci-dessus constituent une autorisation d'utilisation et en aucun cas une cession de droits, de propriété ou autre, relatifs au Site.
                            </p>
                            <p className="text-start fw-bold">Modification des conditions générales d'utilisation</p>
                            <p className="text-start">
                                Les présentes conditions générales d'utilisation pourront être modifiées ou complétées à tout moment, sans préavis, en fonction des modifications apportées au Site, de l'évolution de la législation ou pour tout autre motif jugé nécessaire. Il appartient à l'usager de s'informer des conditions générales d'utilisation du Site, dont seule la version actualisée accessible en ligne est réputée en vigueur. Il est possible qu'entre deux utilisations du Site, les conditions générales d'utilisation particulières soient modifiées et il appartient, dès lors, à l'usager de bien lire ces dernières avant chaque nouvelle utilisation.
                            </p>
                            <p className="text-start fw-bold">Loi applicable et juridictions compétentes</p>
                            <p className="text-start">
                                Tout litige relatif à l'utilisation du Site et ses Services sera soumis à la loi luxembourgeoise et sera de la compétence exclusive des juridictions du Grand-Duché de Luxembourg.
                            </p>
                            <p className="text-start fw-bold">IV. Protection des données à caractère personnel</p>
                            <p className="text-start">
                                Les données à caractère personnel communiquées par l'usager sont traitées en conformité avec le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation des données.
                            </p>
                            <p className="text-start">
                                Des mesures de sécurité techniques et organisationnelles sont mises en place par la Société afin de garantir un niveau approprié de confidentialité, d'intégrité, de disponibilité et de résilience des services offerts via le Site ainsi que d'assurer la protection des droits et libertés de l'usager selon les standards de l'industrie. Seuls les usagers et les utilisateurs autorisés peuvent accéder aux données à caractère personnel et ce, pour des motifs légitimes. Sauf stipulation contraire prévue dans les présentes CGU, les données de l'usager sont stockées dans des pays adéquats.
                            </p>
                            <p className="text-start">
                                L'usager dispose des droits d'accès, de rectification et d'effacement de données qui le concernent. Ces droits sont exerçables auprès du responsable du traitement concerné. Ces droits s'exercent, le cas échéant, via les fonctionnalités existantes dans le Site.
                            </p>
                            <p className="text-start">
                                Si un souci technique empêche l'utilisation de ces fonctionnalités, l'utilisateur peut s'adresser à la Société.
                            </p>
                            <p className="text-start">
                                Dans le cas où la Société est responsable du traitement, l'utilisateur peut adresser sa demande par courrier à l'adresse suivante : 2-8 rue Julien Vesque, L-2668 Luxembourg - LUXEMBOURG.
                            </p>
                            <p className="text-start">
                                L'usager dispose également des droits de limitation du traitement, d'opposition au traitement, de retrait du consentement et de portabilité qui sont exerçables directement auprès des responsables des traitements.
                            </p>
                            <p className="text-start">
                                L'usager peut adresser des réclamations relatives à la protection des données le concernant via les canaux de communication respectifs mis à disposition ainsi qu'auprès du responsable du traitement concerné par sa demande et/ou démarche. L'usager peut, par ailleurs, déposer sa réclamation auprès du délégué à la protection des données de la Société via dpo@alcyone-consulting.lu. L'usager peut, en outre, s'adresser à la Commission Nationale pour la Protection des Données pour tout litige dans ce domaine.
                            </p>
                            <p className="text-start fw-bold">Traitements liés aux journaux d'évènements</p>
                            <p className="text-start">
                                L'usager consent à ce que des données relatives à son activité sur le Site (identifiant, démarche ou fonctionnalité utilisée, date et heure, paramètres) soient conservées et dans le but de permettre la mise à disposition de ces données aux responsables des traitements respectifs ou aux autorités judiciaires dans les cas limitatifs suivants :
                            </p>
                            <ul className="text-start">
                                <li>
                                    De résolution d'incident de sécurité de l'information dans l'intérêt légitime de garantir la sécurité de du Site ;
                                </li>
                                <li>
                                    De la constatation, de l'exercice ou de la défense d'un droit en justice, dans le cadre d'une procédure judiciaire, administrative ou extrajudiciaire en relation avec la démarche auditée.
                                </li>
                            </ul>
                            <p className="text-start">
                                Le responsable du traitement dépend de la fonctionnalité auditée. Seul ce responsable du traitement a accès aux données auditées.
                            </p>
                            <p className="text-start">
                                L'usager est informé que la confidentialité de ces données est assurée selon les standards les plus élevés et que les extractions de telles données ne sont possibles que sur demande expresse des responsables de traitements ou des autorités judiciaires et ce, sur demande motivée.
                            </p>
                            <p className="text-start">
                                Au vu d'une part, de la grande variété et du nombre de démarches ainsi que des bases légales afférentes, et d'autre part, des mesures d'accès et d'extraction très restrictives mises en place, une durée de conservation globale des données de [18 mois] est en place.
                            </p>
                            <p className="text-start fw-bold">Traitements le Site liés à la mesure d'audience</p>
                            <p className="text-start">
                                Certaines données relatives aux matériel et logiciel de l'usager qui ne sont pas de nature à l'identifier sont collectées lors de sa visite sur le Site. Cette collecte a pour seule finalité de disposer de statistiques de fréquentation (type de navigateur, résolution, localisation approximative, etc.) afin de servir au mieux les usagers. L'adresse IP complète de l'usager n'est pas conservée.
                            </p>
                            <p className="text-start">
                                Ces données sont conservées dans une solution fournie par le sous-traitant [nom du sous-traitant] soumis aux mêmes obligations légales concernant la protection des données personnelles.
                            </p>
                            <p className="text-start">
                                La durée de conservation de données n'excède pas celle nécessaire pour observer l'évolution des audiences en fonction de l'évolution des navigateurs, résolutions utilisées, ou autres données statistiques disponibles.
                            </p>
                            <p className="text-start">
                                Le responsable de ces traitements est la Société.
                            </p>
                            <p className="text-start fw-bold">V. Présence sur les réseaux sociaux</p>
                            <p className="text-start text-muted">
                                Politique de modération
                            </p>
                            <p className="text-start">
                                Tout utilisateur qui rejoint la communauté du Site sur les réseaux sociaux s'engage à faire abstraction de toute forme de discrimination liée à la race, la couleur, la religion, le sexe, l'orientation sexuelle, l'âge, l'origine nationale, le handicap ou le statut professionnel ou matrimonial.
                            </p>
                            <p className="text-start">
                                Les propos injurieux, racistes, sexistes ou offensants n'ont pas leur place sur les réseaux sociaux. Ils seront supprimés et signalés.
                            </p>
                            <p className="text-start">
                                Les comptes de la Société sur les réseaux sociaux ont pour objectif principal de relayer les informations et contenus publiés sur le Site. Ils n'ont pas vocation à traiter les demandes, questions et commentaires qui contiennent des données à caractère personnel tels que noms, prénoms, numéro d'identification national, dates de naissance, numéro de téléphone, adresse email, ou des pièces jointes ou scans de documents qui contiennent des données à caractère personnel.
                            </p>
                            <p className="text-start">
                                Pour de telles demandes, questions ou commentaires, les utilisateurs de la communauté du Site doivent utiliser le formulaire de contact disponible sur le Site.
                            </p>
                            <p className="text-start">
                                Les demandes, questions ou commentaires contenant des données à caractère personnel (l'identifiant du compte de la personne sur ce réseau social n'étant généralement pas pris en compte) faits sur les comptes du Site sur les réseaux sociaux ne seront pas traités et seront, dans la mesure du possible, supprimés.
                            </p>
                            <p className="text-start">
                                Les applications Facebook, Twitter, LinkedIn et autres de la Société sont, en principe, surveillées du lundi au vendredi, entre 08.00 et 18.00 heures. En dehors de ces heures, ainsi que durant les week-ends et jours fériés, l'administration des pages n'est pas assurée de façon continue.
                            </p>
                            <p className="text-start fw-bold">Langues</p>
                            <p className="text-start">
                                Sur les réseaux sociaux, le Site communique en langue française.
                            </p>
                        </div>
                    </MDBCard>
                </section>
            </MDBContainer>
        </MDBContainer>
    );
}
