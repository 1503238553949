import React, { createContext, useContext, useReducer } from 'react';

const CvDataContext = createContext();

export const actionTypes = {
    SET_CVDATA: 'SET_CVDATA',
    UPDATE_CVDATA_FIELD: 'UPDATE_CVDATA_FIELD',
};

const cvDataReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_CVDATA:
            return {
                ...state,
                cvData: action.payload || { ...state.cvData },
            };
        case actionTypes.UPDATE_CVDATA_FIELD:
            const updatedValue = action.payload.value !== undefined ? action.payload.value : getDefaultValueForField(action.payload.fieldName);

            return {
                ...state,
                cvData: {
                    ...state.cvData,
                    [action.payload.fieldName]: updatedValue,
                },
            };
        default:
            return state;
    }
};

function getDefaultValueForField(fieldName) {
    const defaultValues = {
        metier: "",
        poste: "",
        mot_cle: "",
        presentation: "",
        langues: "",
        languages: [],
        cvCompetences: [],
        experiences: [],
        formations: [],
        loisirs: [],
    };

    return defaultValues[fieldName] || null;
}

// Componente Provider
export const CvDataProvider = ({ children }) => {
    const initialState = {
        cvData: {
            metier: "",
            poste: "",
            mot_cle: "",
            presentation: "",
            langues: "",
            languages: [],
            cvCompetences: [],
            experiences: [],
            formations: [],
            loisirs: [],
        },
    };

    const [state, dispatch] = useReducer(cvDataReducer, initialState);

    return (
        <CvDataContext.Provider value={{ state, dispatch }}>
            {children}
        </CvDataContext.Provider>
    );
};


export const useCvData = () => useContext(CvDataContext);
