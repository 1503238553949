import React, { useEffect, useState } from 'react';
import { http } from '../services/httpService';
import { toast } from 'react-toastify';
import {
    MDBCard,
    MDBCardHeader,
    MDBIcon,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
} from 'mdb-react-ui-kit';
import { Switch } from "@mui/material";
import NewCompetenceForm from './NewCompetenceForm';
import { useDarkMode } from '../../components/assets/DarkModeContext';

function CompetencesTable() {
    const [competences, setCompetences] = useState([]);
    const [editingCompetence, setEditingCompetence] = useState(null);
    const [editFormData, setEditFormData] = useState({ nameTranslation: '', descriptionTranslation: '', languageCode: '' });
    const [selectedLanguage, setSelectedLanguage] = useState('FR');
    const [searchTerm, setSearchTerm] = useState("");
    const { isDarkMode } = useDarkMode();
    const [categories, setCategories] = useState([]);

    const requestOptions = {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await http('categories?language=FR', requestOptions);
                if (!response.ok) {
                    throw new Error('Error al cargar categorías');
                }
                const categoriesData = await response.json();
                setCategories(categoriesData);
            } catch (error) {
                console.error('Error al cargar categorías:', error);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        http('competences/all', requestOptions)
            .then(response => response.json())
            .then(data => {
                setCompetences(data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [])

    const fetchTranslatedCompetences = async (languageCode) => {
        try {
            toast.info("Chargement en cours, cela peut prendre quelques secondes...", { autoClose: false });

            const updatedCompetences = await Promise.all(competences.map(async (competence) => {
                const response = await http(`competences/translate/${competence.id}/${languageCode}`, requestOptions);
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                const translatedCompetence = await response.json();
                return { ...competence, ...translatedCompetence };
            }));
            setCompetences(updatedCompetences);
        } catch (error) {
            console.error("Erreur:", error);
        } finally {
            toast.dismiss();
        }
    };

    const [selectedCategoryIds, setSelectedCategoryIds] = useState({});

    const handleCategoryChange = (competenceId, newCategoryId) => {
        setSelectedCategoryIds(prev => ({
            ...prev,
            [competenceId]: newCategoryId
        }));
    };

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.checked ? 'EN' : 'FR';
        if (newLanguage !== selectedLanguage) {
            setSelectedLanguage(newLanguage);
            fetchTranslatedCompetences(newLanguage);
        }
    };

    const handleEditClick = (competence) => {
        setEditingCompetence(competence.id);
        setEditFormData({
            nameTranslation: competence.competenceName,
            descriptionTranslation: competence.description
        });
        setSelectedCategoryIds(prevCategoryIds => ({
            ...prevCategoryIds,
            [competence.id]: competence.categorie ? competence.categorie.id.toString() : ''
        }));
    };

    const handleEditFormChange = (e) => {
        setEditFormData({ ...editFormData, [e.target.name]: e.target.value });
    };

    const handleEditSubmit = async (event) => {
        event.preventDefault();

        const categoryId = selectedCategoryIds[editingCompetence];

        const competenceToUpdate = {
            nameTranslation: editFormData.nameTranslation,
            descriptionTranslation: editFormData.descriptionTranslation,
            languageCode: selectedLanguage,
            categoryId: categoryId,
        };

        try {
            const response = await http(`competences/update/${editingCompetence}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(competenceToUpdate),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const updatedCompetence = await response.json();

            // Actualizar el estado local
            const updatedCompetences = competences.map(competence =>
                competence.id === editingCompetence ? { ...competence, ...updatedCompetence } : competence
            );

            setCompetences(updatedCompetences);
            setEditingCompetence(null);
            toast.success("Compétence mise à jour avec succès");
        } catch (error) {
            console.error('Erreur pendant la mise à jour :', error);
            toast.error("Erreur pendant la mise à jour : " + error);
        }
    };

    const handleDeleteClick = async (competenceId) => {
        const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer cette compétition ?");

        if (confirmDelete) {
            try {
                const response = await http(`competences/delete/${competenceId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }

                // Eliminar la competencia del estado local
                const updatedCompetences = competences.filter(competence => competence.id !== competenceId);
                setCompetences(updatedCompetences);

                toast.success("Compétence supprimée avec succès");
            } catch (error) {
                console.error('There was an error deleting the competence:', error);
                toast.error("Erreur lors de la suppression de la compétence, cette compétence est déjà utilisée");
            }
        }
    };

    // const filteredCompetences = competences.filter(competence => {
    //     return competence.competenceName?.toLowerCase().includes(searchTerm.toLowerCase());
    // });

    const filteredCompetences = Array.isArray(competences) ? competences.filter(competence => {
        return competence.competenceName?.toLowerCase().includes(searchTerm.toLowerCase());
    }) : [];

    const handleNewCompetence = (newCompetence) => {
        // Lógica para manejar una nueva competencia (actualizar el estado)
    };

    useEffect(() => {
        const categoryIds = competences.reduce((acc, competence) => {
            acc[competence.id] = competence.categorie ? competence.categorie.id.toString() : '';
            return acc;
        }, {});

        setSelectedCategoryIds(categoryIds);
    }, [competences]);

    return (
        <>
            <div className='my-3'>
                <MDBCardHeader className='py-3'>
                    <h5 className={`text-center py-3 ${isDarkMode ? 'text-white' : ''}`}>
                        <strong>Ajoute une compétence</strong>
                    </h5>
                    <NewCompetenceForm onNewCompetence={handleNewCompetence} />
                    <h5 className={`text-center py-3 ${isDarkMode ? 'text-white' : ''}`}>
                        <strong>Liste de compétences</strong>
                    </h5>
                    <div className='text-center'>
                        <input
                            type="text"
                            placeholder="Recherche par nom..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="d-flex align-items-center me-4 justify-content-center">
                        <i className="flag flag-france"></i>
                        <Switch
                            checked={selectedLanguage === 'EN'}
                            onChange={handleLanguageChange}
                            name="languageSwitch"
                            inputProps={{ 'aria-label': 'language switch' }}
                        />
                        <i className="flag flag-united-kingdom"></i>
                    </div>
                    <MDBTable>
                        <MDBTableHead>
                            <tr className={`${isDarkMode ? 'text-white' : ''}`}>
                                <th>Catégorie</th>
                                <th>Nom de compétence</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody style={{ overflowX: 'auto' }}>
                            {filteredCompetences && filteredCompetences.length > 0 ? (
                                filteredCompetences.map((competence) => (
                                    <tr key={competence.id} className={`${isDarkMode ? 'text-white' : ''}`}>
                                        <td>
                                            {editingCompetence === competence.id ? (
                                                <>
                                                    <select
                                                        value={selectedCategoryIds[competence.id] || ''}
                                                        onChange={(e) => handleCategoryChange(competence.id, e.target.value)}
                                                    >
                                                        {categories.map((category) => (
                                                            <option key={category.id} value={category.id}>
                                                                {category.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </>
                                            ) : (
                                                competence.categorie && competence.categorie.name ? competence.categorie.name : 'Sans categorie'
                                            )}
                                        </td>
                                        <td>
                                            {editingCompetence === competence.id ? (
                                                <input
                                                    type="text"
                                                    name="nameTranslation"
                                                    value={editFormData.nameTranslation}
                                                    onChange={handleEditFormChange}
                                                />
                                            ) : (
                                                competence.competenceName || '-'
                                            )}
                                        </td>
                                        <td>
                                            {editingCompetence === competence.id ? (
                                                <textarea
                                                    name="descriptionTranslation"
                                                    value={editFormData.descriptionTranslation}
                                                    onChange={handleEditFormChange}
                                                    rows="3"
                                                    style={{ width: '100%' }}
                                                />
                                            ) : (
                                                competence.description || '-'
                                            )}
                                        </td>
                                        <td>
                                            {editingCompetence === competence.id ? (
                                                <>
                                                    <MDBIcon fas icon="check-square" size='lg' className='text-success' onClick={handleEditSubmit} />
                                                    <MDBIcon fas icon="window-close" className='ms-2 text-danger' size='lg' onClick={() => setEditingCompetence(null)} />
                                                </>
                                            ) : (
                                                <>
                                                    <MDBIcon fas icon="edit" className='text-info' onClick={() => handleEditClick(competence)} />
                                                    <MDBIcon fas icon="trash-alt" className='ms-3 text-info' onClick={() => handleDeleteClick(competence.id)} />
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">
                                        <div>
                                            <img className="loader-image" src="/spinner-alcyone.png" alt="Loading..." />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </MDBTableBody>
                    </MDBTable>
                </MDBCardHeader>
            </div>
        </>
    );
}

export default CompetencesTable;
