import { useEffect, useState } from 'react';
import { getProfile } from '../services/httpService';

export function useCurrentUser() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      getProfile()
        .then((response) => response.json())
        .then((data) => {
          setUser(data);
        })
        .catch((error) => console.error(error));
    }
  }, []);

  return user;
}
