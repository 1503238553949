import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBTextArea, MDBIcon } from 'mdb-react-ui-kit';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import UserSearch from "../../components/assets/UserSearch";
import { http } from '../../components/services/httpService';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

function EnvoyerMessage() {
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUser, setFilteredUser] = useState({ prenom: "", nom: "", role: "" });
  const [message, setMessage] = useState('');
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  }

  const handleUserSearch = async (searchQuery) => {
    if (searchQuery === "") {
      setFilteredUser({ prenom: "", nom: "", role: "" });
      return;
    }

    if (allUsers.length === 0) {
      const response = await http('user/all');
      const data = await response.json();
      setAllUsers(data);
    }

    const foundUser = allUsers.find(
      (u) =>
        u.prenom.toLowerCase().includes(searchQuery.toLowerCase()) ||
        u.nom.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (foundUser) {
      setFilteredUser(foundUser);
    } else {
      setFilteredUser({ prenom: "", nom: "", role: "" });
    }
  };

  const handleClick = () => {
    const { email, validationToken } = filteredUser;
    http('api/v1/auth/send-message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, message })
    })
      .then(response => {
        if (response.ok) {
          toast.success('Message envoyé avec succès.');
        } else {
          toast.error('Une erreur s\'est produite lors de l\'envoi de l\'e-mail.');
        }
      })
      .catch(error => {
        toast.error('Une erreur s\'est produite lors de l\'envoi de l\'e-mail.');
      });
  }


  return (
    <MDBContainer fluid className="fond-accueil">
      <MDBRow className='d-flex justify-content-center align-items-center text-center text-white pt-5'>
        <h2 className='pt-5'>
          Choisissez un consultant:
        </h2>
        <div className='d-flex justify-content-center pt-4'>
          <UserSearch onSearch={handleUserSearch} className="outline-white" />
        </div>
        <h2 className='pt-5'>
          Aperçu du message :
        </h2>
        <MDBCol md="6" className="form-card">
          <h6>Envoyer a : {filteredUser.prenom !== "" ? ` ${filteredUser.prenom} ${filteredUser.nom.toUpperCase()}` : ''} </h6>

          <form>
            <MDBTextArea value={message} onChange={e => setMessage(e.target.value)} />
          </form>
        </MDBCol>
        <div className='d-flex justify-content-center align-items-center mb-5 gap-5'>
          <Link to="/cv-detail">
            <MDBIcon fas icon="arrow-left" size="2x" style={{ color: "#09B4BF" }} title="Retour Arrière" onClick={goBack}/>
          </Link>
          <MDBBtn onClick={handleClick} className="BtnCard">Envoyer message</MDBBtn>
        </div>
      </MDBRow>
    </MDBContainer>
  );
};

export default EnvoyerMessage;
