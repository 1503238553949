import "./EditionCv.css";
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../components/hooks/useCurrentUser";
import { useParams } from "react-router-dom";
import { getUserInitials, formatPrenom } from "../../components/assets/Fonctions";
import { Switch } from "@mui/material";
import { handlePDFAction } from "../../components/assets/Fonctions";
import { format, parseISO, differenceInYears, differenceInMonths, addYears } from "date-fns";
import { fr } from "date-fns/locale";
import { useTranslations } from "../../components/hooks/useTranslations";
import LanguageSkills from "./LanguageSkills";
import { toast } from 'react-toastify';
import FileUploadForm from "../../components/assets/FileUploadForm";
import CompetencesComponent from "./CompetencesComponent";
import ExperiencesComponent from "./ExperiencesComponent";
import FormationsComponent from './FormationsComponent';
import LoisirsComponent from "./LoisirsComponent";
import Header from './Header';
import MainContent from './MainContent';
import Footer from './Footer';
import { useCvData, actionTypes } from './CvDataContext';
import ReactPDF, { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import CvPdf from "../../components/assets/CvPdf";
import { usePdfContext } from "../../components/assets/PdfContext";
import CVPreviewComponent from './CVPreviewComponent';

import {
  MDBContainer,
  MDBBtn,
  MDBInput,
  MDBTypography,
  MDBIcon,
  MDBTextArea,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBadge,
} from "mdb-react-ui-kit";
import { http } from "../../components/services/httpService";

export default function EditionCv(props) {
  const [user, setUser] = useState({ role: "" });
  const [_cvTitle, setCvTitle] = useState("");
  const [langues, setLangues] = useState("");
  const [languageSkills, setLanguageSkills] = useState([]);
  const [competences, setCompetences] = useState([]);
  const [categories, setCategories] = useState({});
  const [suggestions, setSuggestions] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [formations, setFormations] = useState([{}]);
  const [loisirs, setLoisirs] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const currentUser = useCurrentUser();

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const { state, dispatch } = useCvData();
  const cvData = state.cvData;
  const [experiences, setExperiences] = useState(cvData.experiences || [{ actions: [""] }]);
  const [presentation, setPresentation] = useState(cvData.presentation || "");
  const [metier, setMetier] = useState(cvData.metier || "");
  const [poste, setPoste] = useState(cvData.poste || "");

  const [isAnonymous, setIsAnonymous] = useState(false);
  const [showExp, setShowExp] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState('FR');
  const { translations, updateTranslations } = useTranslations();
  const [isScrolled, setIsScrolled] = useState(false);
  const { userId, cvId } = useParams();
  const [userData, setUserData] = useState();

  //Modal pour telecharger le CV
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);

  //Modal pour partager le CV
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const managerRoles = ["MANAGER", "SUPERADMIN"];
  const candidateRoles = ["CANDIDAT", "CONSULTANT"];
  const clientRole = ["CLIENT"];

  const isManagerRole = managerRoles.includes(currentUser?.role);
  const isCandidateRole = candidateRoles.includes(currentUser?.role);
  const isClientRole = clientRole.includes(currentUser?.role);

  //Pour cacher compteur exp
  const handleSwitchChange = () => {
    setShowExp(!showExp);
  };

  const [blobReady, setBlobReady] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);

  useEffect(() => {
    if (blobReady && pdfBlob) {
      const filename = `ACO_${userData?.prenom}_${userData?.nom.toUpperCase()}_CV_${cvData?.langues?.toUpperCase()}_${cvData?.mot_cle}.pdf`;
      const file = new File([pdfBlob], filename, { type: 'application/pdf' });
      sendPdfToServer(file, filename, userData?.email).then(() => {
        setBlobReady(false);
        setShowModal(true);
      });
    }
  }, [blobReady, pdfBlob, userData]);

  const handleShareClick = () => {
    setBlobReady(true);
  };

  useEffect(() => {
    const checkScroll = () => {
      setIsScrolled(window.pageYOffset > 20);
    };

    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  const normalizeDate = (inputDate) => {
    if (!inputDate) return '';
    return formatBackendDate(inputDate);
  };

  //Setting the local state based on the fetched cvData
  useEffect(() => {
    const cvData = state.cvData;
    if (cvData) {
      //Set states
      setIsAnonymous(cvData?.anonymous || false);
      setPresentation(cvData.presentation);
      setMetier(cvData.metier);
      setPoste(cvData.poste);
      // setLangues(cvData.langues);

      if (cvData.langues && (cvData.langues === "FR" || cvData.langues === "EN")) {
        setLangues(cvData.langues);
      }

      if (cvData && Array.isArray(cvData.languages)) {

        const skills = cvData.languages.map(lang => ({
          id: lang.id,
          name: lang.name || "",
          oralComprehension: lang.oralComprehension || "",
          writtenComprehension: lang.writtenComprehension || "",
          oralExpression: lang.oralExpression || "",
          writtenExpression: lang.writtenExpression || "",
        }));

        //input vide
        if (skills.length === 0 || Object.values(skills[skills.length - 1]).some(value => typeof value === 'string' && value.trim())) {
          skills.push({
            id: null,
            name: "",
            oralComprehension: "",
            writtenComprehension: "",
            oralExpression: "",
            writtenExpression: ""
          });
        }

        setLanguageSkills(skills);
      }

      if (cvData && cvData.cvCompetences) {
        const mappedCompetences = cvData.cvCompetences.map(competence => ({
          id: competence.competences?.id,
          competenceName: competence.competences.competenceName || "",
          rating: competence.rating || 0,
          categorie: {
            id: competence.competences.categorie?.id,
            name: competence.competences.categorie?.name
          }
        }));
        setCompetences(mappedCompetences);
      }

      const sortedExperiences = (cvData.experiences || [])
        .map((exp, index) => {

          let parsedEnvTechnique;
          if (Array.isArray(exp.envTechnique)) {
            parsedEnvTechnique = exp.envTechnique;
          } else if (exp.envTechnique && exp.envTechnique !== 'null' && exp.envTechnique !== '') {
            try {
              parsedEnvTechnique = JSON.parse(exp.envTechnique);
            } catch (error) {
              console.error('Error JSON para envTechnique:', exp.envTechnique, error);
              parsedEnvTechnique = [];
            }
          } else {
            parsedEnvTechnique = [];
          }

          return {
            ...exp,
            id: exp.id,
            dateDebut: normalizeDate(exp.dateDebut),
            dateFin: normalizeDate(exp.dateFin),
            actions: exp.actions && exp.actions.length > 0 ? exp.actions : [""],
            envTechnique: parsedEnvTechnique,
            orderIndex: exp.orderIndex !== undefined ? exp.orderIndex : index,
          };
        })
        .sort((a, b) => a.orderIndex - b.orderIndex);
      // expérience vide à la fin de la liste triée des expériences.
      sortedExperiences.push({
        id: `temp-${Date.now()}`,
        societe: "",
        secteur: "",
        client: "",
        lieu: "",
        dateDebut: "",
        dateFin: "",
        poste: "",
        typeContrat: "",
        actions: [""],
        orderIndex: sortedExperiences.length,
      });

      const formations = cvData.formations?.length ? cvData.formations : [{}];

      if (formations.length && formations[formations.length - 1].ecole) {
        formations.push({
          ecole: "",
          niveau: "",
          intitule: "",
          dateDebut: "",
          dateFin: ""
        });
      }

      setExperiences(sortedExperiences);

      setFormations(
        formations.map((formation) => ({
          ...formation,
          dateDebut: formation.dateDebut,
          dateFin: formation.dateFin,
        }))
      );
      setLoisirs(cvData?.loisirs?.length > 0 ? cvData.loisirs : [""]);
    } else {
      setExperiences([{ actions: [""], orderIndex: 0, envTechnique: [] }]);
      setFormations([{}]);
    }

  }, [state.cvData]);

  useEffect(() => {
    competences.forEach(competence => {
      if (!suggestions[competence.categorie.id]) {
        fetchSuggestionsForCategory(competence.categorie.id);
      }
    });
  }, [competences]);


  useEffect(() => {
    // Charge les traductions sélectionnés
    updateTranslations(cvData?.langues);
  }, [cvData?.langues]);

  useEffect(() => {
    if (cvData?.langues !== currentLanguage) {
      setCurrentLanguage(cvData?.langues);
    }
  }, [cvData?.langues]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await http(`user/find-with-cvs/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok, status: ${response.status}`);
      }
      const data = await response.json();
      const user = data;
      const cv = user.cvs.find(cv => cv.id === parseInt(cvId));

      setUserData(user);

      dispatch({
        type: actionTypes.SET_CVDATA,
        payload: cv || {},
      });
    } catch (error) {
      console.error(`Error al recuperar los datos del CV: ${error}`);
    }
  };

  useEffect(() => {
    fetchData();
  }, [cvId]);


  useEffect(() => {
    setCvTitle(`ACO__${user.prenom}_${user.nom}_CV_${cvData?.langues?.toUpperCase()}`);
  }, [user]);

  const capitalizeFirstLetter = (string) => {
    if (!string || typeof string !== "string") {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    // Gérer spécifiquement le changement de langue
    if (name === "cvLanguage") {
      setCurrentLanguage(value);
      fetchCategories(value);
      updateTranslations(value);
      handleLanguageChange(value);
      if (value) {
        setFormErrors((prevErrors) => ({ ...prevErrors, langues: "" }));
      }

      dispatch({
        type: actionTypes.UPDATE_CVDATA_FIELD,
        payload: {
          fieldName: 'langues',
          value: value,
        },
      });
    } else {

      let newValue = value;

      if (fieldsToCapitalize.includes(name)) {
        newValue = capitalizeFirstLetter(value);
      }

      if (name === "mot_cle" && value.trim()) {
        setFormErrors((prevErrors) => ({ ...prevErrors, mot_cle: "" }));
      }

      if (name === "presentation" && value.trim()) {
        setFormErrors((prevErrors) => ({ ...prevErrors, presentation: "" }));
      }

      dispatch({
        type: actionTypes.UPDATE_CVDATA_FIELD,
        payload: {
          fieldName: name,
          value: newValue,
        },
      });
    }
  };

  const handleLanguageSkillChange = (index, field, value) => {
    const newSkills = [...languageSkills];
    const skillToUpdate = { ...newSkills[index], [field]: value };
    newSkills[index] = skillToUpdate;
    setLanguageSkills(newSkills);
  };

  const handleCompetenceChange = (index, field, value) => {
    if (field === "name") {
      const selectedCategoryForCompetence = competences[index].categorie.id;
      const relevantSuggestions = suggestions[selectedCategoryForCompetence];

      const selected = relevantSuggestions.find(comp => comp.competenceName === value);

      if (selected) {
        setCompetences(prevCompetences => {
          const updatedCompetences = [...prevCompetences];
          updatedCompetences[index] = { ...updatedCompetences[index], id: selected.id, competenceName: selected.competenceName, description: selected.description };
          return updatedCompetences;
        });
      } else {
        console.log("Competence non trouvé", value);
      }
    } else if (field === "rating") {

      setCompetences(prevCompetences => {
        const updatedCompetences = [...prevCompetences];
        updatedCompetences[index] = { ...updatedCompetences[index], rating: value };
        return updatedCompetences;
      });
    }
  };

  const groupBy = (array, key) => {
    return array.reduce((result, currentItem, index) => {
      const keyParts = key.split('.');
      let value = currentItem;

      for (let part of keyParts) {
        if (value[part]) {
          value = value[part];
        } else {
          value = undefined;
          break;
        }
      }

      if (value !== undefined) {
        (result[value] = result[value] || []).push({ ...currentItem, originalIndex: index });
      }

      return result;
    }, {});
  };

  const groupedCompetences = useMemo(() => {
    return competences ? groupBy(competences, 'categorie.name') : {};
  }, [competences]);

  const fetchCategories = async (lang) => {
    try {
      const token = localStorage.getItem("token");
      const response = await http(`categories?language=${lang}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const formattedCategories = {};
        data.forEach(category => {
          formattedCategories[category.name] = category.id;
        });
        setCategories(formattedCategories);
      } else {
        console.error("Erreur lors de la récupération des catégories");
      }
    } catch (error) {
      console.error("Erreur lors de la requête HTTP :", error);
    }
  };

  //Récupere la liste of Categories en 2 langues
  useEffect(() => {
    fetchCategories(currentLanguage);
  }, [currentLanguage]);

  const addCompetence = () => {
    const defaultSuggestion = suggestions[categories[selectedCategory]][0];

    const newCompetence = {
      id: defaultSuggestion ? defaultSuggestion.id : '',
      name: defaultSuggestion ? defaultSuggestion.competenceName : '',
      rating: 0,
      categorie: { id: categories[selectedCategory], name: selectedCategory }
    };

    setCompetences([
      ...competences,
      newCompetence
    ]);

  };

  const fetchSuggestionsForCategory = async (categoryId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await http(`categories/${categoryId}/competences?language=${currentLanguage}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSuggestions(prevSuggestions => ({
        ...prevSuggestions,
        [categoryId]: data
      }));
    } catch (error) {
      console.error("Error al cargar las sugerencias:", error);
    }
  };

  useEffect(() => {
    if (selectedCategory && categories[selectedCategory]) {
      fetchSuggestionsForCategory(categories[selectedCategory]);
    }
  }, [selectedCategory, currentLanguage, categories]);

  const removeCompetence = (index) => {
    setCompetences(prevCompetences => {
      const updatedCompetences = [...prevCompetences];
      updatedCompetences.splice(index, 1);
      return updatedCompetences;
    });
  };

  const [isSearching, setIsSearching] = useState(false);

  // Cette funtion gére l'appel de l'API avec debouncing
  const debouncedSearch = useCallback(
    debounce(async (search) => {
      setIsSearching(true);
      try {
        const token = localStorage.getItem("token");
        const response = await http(`competences/search?query=${search}&language=${currentLanguage}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setSearchResults(data);
        } else {
          console.error("Error al recuperar las competencias");
        }
      } catch (error) {
        console.error("Error en la petición HTTP:", error);
      }
      setIsSearching(false);
    }, 500),
    [currentLanguage]
  );

  // useEffect qui observe 'searchTerm' et appel debouncedSearch
  useEffect(() => {
    if (searchTerm.trim() !== "") {
      debouncedSearch(searchTerm);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, debouncedSearch]);

  // Funtion debounce 
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const addSelectedCompetence = (selectedCompetence) => {
    const isCompetenceAlreadySelected = competences.some(competence => competence.id === selectedCompetence.id);

    if (!isCompetenceAlreadySelected) {
      setCompetences([...competences, selectedCompetence]);
    } else {
      toast.warning('Cette compétence a déjà été sélectionnée');
    }
    setSearchTerm('');
    setSearchResults([]);
  };

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown" && activeSuggestionIndex < searchResults.length - 1) {
      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    } else if (e.key === "ArrowUp" && activeSuggestionIndex > 0) {
      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    } else if (e.key === "Enter" && activeSuggestionIndex !== -1) {
      e.preventDefault();
      addSelectedCompetence(searchResults[activeSuggestionIndex]);
    }
  };

  const handleMouseEnter = (index) => {
    setActiveSuggestionIndex(index);
  };

  const existsEmptyExperienceAfterIndex = (index) => {
    const subsequentExperiences = experiences.slice(index + 1);
    return subsequentExperiences.some((exp) => !exp.societe);
  };

  function handleExperienceChange(event, index, field) {
    const newExperiences = [...experiences];

    if (field === 'envTechnique') {
      const selectedValues = event ? event.map(option => option.value) : [];
      newExperiences[index] = {
        ...newExperiences[index],
        [field]: selectedValues
      };
    } else {
      let value = field === "isCurrentJob" ? event.target.checked : event.target.value;

      if (fieldsToCapitalize.includes(field)) {
        value = capitalizeFirstLetter(value);
      }
      newExperiences[index] = {
        ...newExperiences[index],
        [field]: value,
      };

      if (field === "isCurrentJob") {
        if (value) {

          newExperiences[index].originalDateFin = newExperiences[index].dateFin;
          newExperiences[index].dateFin = '';
        } else {
          newExperiences[index].dateFin = newExperiences[index].originalDateFin ?? newExperiences[index].dateFin;
          newExperiences[index].originalDateFin = null;
        }
      } else if (field === "dateFin") {
        newExperiences[index].dateFin = value;

        newExperiences[index].originalDateFin = null;
      }

      if (field === "societe" && value && !existsEmptyExperienceAfterIndex(index)) {
        newExperiences.push({
          societe: "",
          secteur: "",
          client: "",
          lieu: "",
          dateDebut: "",
          dateFin: "",
          poste: "",
          typeContrat: "",
          actions: [""],
          envTechnique: [],
          orderIndex: newExperiences.length
        });
      }
    }

    newExperiences.forEach((exp, idx) => {
      exp.orderIndex = idx;
    });

    setExperiences(newExperiences);
  }

  function handleExperienceActionChange(event, experienceIndex, actionIndex) {
    const newExperiences = [...experiences];
    const newActions = [...newExperiences[experienceIndex].actions];

    let value = event.target.value;
    value = capitalizeFirstLetter(value);

    newActions[actionIndex] = value;
    newExperiences[experienceIndex].actions = newActions;

    if (actionIndex === newActions.length - 1 && value !== "") {
      newExperiences[experienceIndex].actions.push("");
    }

    setExperiences(newExperiences);
  }

  function removeExperience(indexToRemove) {
    const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette expérience ?");

    if (isConfirmed) {
      const newExperiences = experiences.filter((_, index) => index !== indexToRemove)
        .map((experience, index) => ({
          ...experience,
          orderIndex: index  // Actualiza el orderIndex basado en la nueva posición
        }));

      if (newExperiences.length === 0) {
        newExperiences.push({ actions: [""], orderIndex: 0 });  // Esto lanzaría un error ya que newExperiences es const
      }

      setExperiences(newExperiences);
    }
  }

  function addNewAction(event, experienceIndex) {
    event.preventDefault();
    const newExperiences = [...experiences];
    if (!newExperiences[experienceIndex].actions) {
      newExperiences[experienceIndex].actions = [];
    }
    newExperiences[experienceIndex].actions.push("");
    setExperiences(newExperiences);

    dispatch({
      type: actionTypes.UPDATE_CVDATA_FIELD,
      payload: {
        fieldName: 'experiences',
        value: newExperiences,
      },
    });
  }

  const moveAction = (experienceIndex, actionIndex, direction) => {
    setExperiences((prevExperiences) => {
      const newExperiences = [...prevExperiences];
      const actions = [...newExperiences[experienceIndex].actions];
      const newPosition = actionIndex + direction;

      if (newPosition >= 0 && newPosition < actions.length) {
        // Realizar el intercambio de posiciones
        [actions[actionIndex], actions[newPosition]] = [actions[newPosition], actions[actionIndex]];

        newExperiences[experienceIndex].actions = actions;

        return newExperiences;
      }

      return prevExperiences;
    });
  };

  function handleRemoveAction(experienceIndex, actionIndex) {
    const updatedExperiences = [...experiences];
    updatedExperiences[experienceIndex].actions.splice(actionIndex, 1);
    setExperiences(updatedExperiences);
  }

  const existsEmptyFormationAfterIndex = (index) => {
    const subsequentFormations = formations.slice(index + 1);
    return subsequentFormations.some((formation) => !formation.ecole);
  };

  const handleFormationChange = (event, index, field) => {
    let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

    if (fieldsToCapitalize.includes(field)) {
      value = capitalizeFirstLetter(value);
    }

    setFormations((prevFormations) => {
      const newFormations = [...prevFormations];
      newFormations[index] = {
        ...newFormations[index],
        [field]: value,
      };

      if (field === "isCurrentFormation") {
        if (value) {
          newFormations[index].originalDateFin = newFormations[index].dateFin;
          newFormations[index].dateFin = '';
        } else {
          newFormations[index].dateFin = newFormations[index].originalDateFin ?? newFormations[index].dateFin;
          newFormations[index].originalDateFin = null;
        }
      } else if (field === "dateFin") {
        newFormations[index].dateFin = value;
        newFormations[index].originalDateFin = null;
      }

      if (
        field === "ecole" &&
        value &&
        !existsEmptyFormationAfterIndex(index)
      ) {
        newFormations.push({
          id: "",
          ecole: "",
          dateDebut: "",
          dateFin: "",
          niveau: "",
          intitule: "",
        });
      }

      return newFormations;
    });
  };

  function removeFormation(indexToRemove) {
    const newFormations = formations.filter(
      (_, index) => index !== indexToRemove
    );

    if (newFormations.length === 0) {
      newFormations.push({});
    }

    setFormations(newFormations);

  }

  const handleLoisirChange = (event, index) => {
    let value = event.target.value;

    value = capitalizeFirstLetter(value);

    setLoisirs((prevLoisirs) => {
      const newLoisirs = [...prevLoisirs];
      newLoisirs[index] = value;

      if (value && index === prevLoisirs.length - 1) {
        newLoisirs.push("");
      }

      return newLoisirs;
    });
  };

  function removeLoisir(indexToRemove) {
    const newLoisirs = loisirs.filter((_, index) => index !== indexToRemove);
    if (newLoisirs.length === 0) {
      newLoisirs.push("");
    }

    setLoisirs(newLoisirs);

  }

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = parseISO(dateString);
    return format(date, 'MM/yyyy');
  };

  const fieldsToCapitalize = ["poste", "mot_cle", "presentation", "societe", "lieu", "contexte", "realisation", "envTechnique", "ecole", "intitule"];

  const formatBackendDate = (inputDate) => {
    if (!inputDate) return null;

    const date = new Date(inputDate);
    return format(date, 'yyyy-MM-dd');
  };

  const handleSave = async () => {
    const toastId = toast.info("Chargement en cours, cela peut prendre quelques secondes...", { autoClose: false });

    const updatedLanguages = languageSkills?.map(skill => ({
      id: skill.id,
      name: skill.name,
      oralComprehension: skill.oralComprehension,
      writtenComprehension: skill.writtenComprehension,
      oralExpression: skill.oralExpression,
      writtenExpression: skill.writtenExpression,
    })).filter(skill => {
      return skill.name !== '' || skill.oralComprehension !== '' || skill.writtenComprehension !== '' || skill.oralExpression !== '' || skill.writtenExpression !== '';
    });

    dispatch({
      type: actionTypes.UPDATE_CVDATA_FIELD,
      payload: {
        fieldName: 'languages',
        value: updatedLanguages,
      },
    });

    const updatedCvCompetences = competences.map(competence => ({
      rating: competence.rating,
      competences: {
        id: competence.id,
        categorie: {
          id: competence.categorie.id,
          name: competence.categorie.name,
        },
        competenceName: competence.competenceName,
      },
    }));

    let cvNom = userData?.nom;
    let cvPrenom = formatPrenom(userData?.prenom);

    if (isAnonymous) {
      const initials = getUserInitials(userData);
      cvPrenom = initials.firstNameInitials;
      cvNom = initials.lastNameInitials;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await http(`cv/update/${userId}/${cvId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          nom: cvNom,
          prenom: cvPrenom,
          isAnonymous: isAnonymous,
          anonymous: isAnonymous,
          presentation: presentation,
          metier: metier,
          poste: poste,
          mot_cle: cvData?.mot_cle,
          langues: cvData.langues,
          languages: updatedLanguages,
          cvCompetences: updatedCvCompetences,
          experiences: experiences
            ?.filter((experience) => experience.societe !== "")
            .map((experience) => {
              return {
                societe: experience.societe,
                poste: experience.poste,
                secteur: experience.secteur,
                client: experience.client,
                lieu: experience.lieu,
                dateDebut: formatBackendDate(experience.dateDebut),
                dateFin: experience.isCurrentJob ? null : formatBackendDate(experience.dateFin),
                isCurrentJob: experience.isCurrentJob || false,
                contexte: experience.contexte,
                realisation: experience.realisation,
                typeContrat: experience.typeContrat,
                actions: experience.actions?.filter((action) => action !== ""),
                envTechnique: JSON.stringify(experience.envTechnique),
                orderIndex: experience.orderIndex,
              };
            }),
          formations: formations
            ?.filter((formation) => formation.ecole !== "")
            .map((formation) => ({
              ecole: formation.ecole,
              intitule: formation.intitule,
              niveau: formation.niveau,
              dateDebut: formatBackendDate(formation.dateDebut),
              dateFin: formatBackendDate(formation.dateFin),
              isCurrentFormation: formation.isCurrentFormation || false,
            })),
          loisirs: loisirs,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`La mise à jour du CV a échoué, status: ${response.status}, body: ${errorText}`);
        throw new Error(`La mise à jour du CV a échoué, status: ${response.status}`);
      }

      const updatedCV = await response.json();
      dispatch({
        type: actionTypes.SET_CVDATA,
        payload: updatedCV,
      });
      await fetchData();
      await updateCompetencesToCurrentLanguage();
      toast.success("CV mis à jour avec succès !");
      toast.dismiss(toastId);
    } catch (error) {
      console.error(`Erreur lors de la mise à jour du CV: ${error}`);
      toast.dismiss(toastId);
      toast.error(
        "Une erreur s'est produite lors de la mise à jour du CV. Veuillez réessayer."
      );
    }
  };

  const handleConfirmToManager = async () => {

    await handleSave();

    try {
      const response = await http(`cv/confirm-manager/${userId}/${cvId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Confirmation au MANAGER a échoué, status: ${response.status}`);
      }

      toast.success("Confirmation au MANAGER réussie !");
    } catch (error) {
      console.error(`Erreur lors de la confirmation au MANAGER: ${error}`);
      toast.error("Une erreur s'est produite lors de la confirmation au MANAGER. Veuillez réessayer.");
    }
  };

  const handleAutoSave = async () => {
    const updatedLanguages = languageSkills?.map(skill => ({
      id: skill.id,
      name: skill.name,
      oralComprehension: skill.oralComprehension,
      writtenComprehension: skill.writtenComprehension,
      oralExpression: skill.oralExpression,
      writtenExpression: skill.writtenExpression,
    })).filter(skill => {
      return skill.name !== '' || skill.oralComprehension !== '' || skill.writtenComprehension !== '' || skill.oralExpression !== '' || skill.writtenExpression !== '';
    });

    dispatch({
      type: actionTypes.UPDATE_CVDATA_FIELD,
      payload: {
        fieldName: 'languages',
        value: updatedLanguages,
      },
    });

    const updatedCvCompetences = competences.map(competence => ({
      rating: competence.rating,
      competences: {
        id: competence.id,
        categorie: {
          id: competence.categorie.id,
          name: competence.categorie.name,
        },
        competenceName: competence.competenceName,
      },
    }));

    let cvNom = userData?.nom;
    let cvPrenom = formatPrenom(userData?.prenom);

    if (isAnonymous) {
      const initials = getUserInitials(userData);
      cvPrenom = initials.firstNameInitials;
      cvNom = initials.lastNameInitials;
    }

    try {

      const token = localStorage.getItem("token");
      const response = await http(`cv/update/${userId}/${cvId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          nom: cvNom,
          prenom: cvPrenom,
          isAnonymous: isAnonymous,
          anonymous: isAnonymous,
          presentation: presentation,
          metier: metier,
          poste: poste,
          mot_cle: cvData?.mot_cle,
          langues: cvData.langues,
          languages: updatedLanguages,
          cvCompetences: updatedCvCompetences,
          experiences: experiences
            ?.filter((experience) => experience.societe !== "")
            .map((experience) => {
              return {
                societe: experience.societe,
                poste: experience.poste,
                secteur: experience.secteur,
                client: experience.client,
                lieu: experience.lieu,
                dateDebut: formatBackendDate(experience.dateDebut),
                dateFin: experience.isCurrentJob ? null : formatBackendDate(experience.dateFin),
                isCurrentJob: experience.isCurrentJob || false,
                contexte: experience.contexte,
                realisation: experience.realisation,
                typeContrat: experience.typeContrat,
                actions: experience.actions?.filter((action) => action !== ""),
                envTechnique: JSON.stringify(experience.envTechnique),
                orderIndex: experience.orderIndex,
              };
            }),
          formations: formations
            ?.filter((formation) => formation.ecole !== "")
            .map((formation) => ({
              ecole: formation.ecole,
              intitule: formation.intitule,
              niveau: formation.niveau,
              dateDebut: formatBackendDate(formation.dateDebut),
              dateFin: formatBackendDate(formation.dateFin),
              isCurrentFormation: formation.isCurrentFormation || false,
            })),
          loisirs: loisirs,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`La mise à jour du CV a échoué, status: ${response.status}, body: ${errorText}`);
        throw new Error(`La mise à jour du CV a échoué, status: ${response.status}`);
      }

      const updatedCV = await response.json();
      dispatch({
        type: actionTypes.SET_CVDATA,
        payload: updatedCV,
      });
      await fetchData();
      await updateCompetencesToCurrentLanguage();
      toast.success("CV mis à jour avec succès !");
    } catch (error) {
      console.error(`Erreur lors de la mise à jour du CV: ${error}`);
      toast.error(
        "Une erreur s'est produite lors de la mise à jour du CV. Veuillez réessayer."
      );
    }
  };

  const updateCompetencesToCurrentLanguage = async () => {
    const translatedCompetences = await Promise.all(
      competences.map(async competence => {
        const translatedData = await fetchTranslatedCompetences(competence.id, currentLanguage);
        return { ...competence, ...translatedData };
      })
    );
    setCompetences(translatedCompetences);
  };

  const [areLanguagesVisible, setLanguagesVisible] = useState(false);
  const [areCompetencesVisible, setCompetencesVisible] = useState(false);
  const [areExperiencesVisible, setExperiencesVisible] = useState(false);
  const [areFormationsVisible, setFormationsVisible] = useState(false);
  const [areLoisirsVisible, setLoisirsVisible] = useState(false);

  // const cvPreviewRef = useRef(null);

  const [backgroundImage, setBackgroundImage] = useState("/bg/compliance.webp");
  const [currentIndex, setCurrentIndex] = useState(0);

  const availableBackgrounds = [
    "/bg/projets.jpg",
    "/bg/it.png",
    "/bg/creation-cv.jpg",
    "/bg/compliance.webp",
  ];

  const handleIconClick = () => {
    let nextIndex = (currentIndex + 1) % availableBackgrounds.length;
    setCurrentIndex(nextIndex);
    setBackgroundImage(availableBackgrounds[nextIndex]);
  };

  // const [pdfUrl, setPdfUrl] = useState("");
  const { pdfUrl, setPdfUrl } = usePdfContext();

  function generateMailtoLink(subject, body) {
    return `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  }

  async function sendPdfToServer(pdfFile, filename, email) {
    const formData = new FormData();
    formData.append("file", pdfFile, filename);
    formData.append("email", email);

    const fullPdfUrl = `${baseUrl}cv/pdf-uploads/${filename}`;
    formData.append("pdfUrl", fullPdfUrl);

    const token = localStorage.getItem('token');

    try {
      const response = await http("cv/upload-pdf", {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.text();

      if (!response.ok) {
        throw new Error(data || `Error: ${response.statusText}`);
      }

      const fullPdfUrl = `${baseUrl}cv/pdf-uploads/${filename}`;
      setPdfUrl(fullPdfUrl);
    } catch (error) {
      toast.error(error);
    }
  }

  const [showBadge, setShowBadge] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setShowBadge(true);
      setTimeout(() => {
        setShowBadge(false);
      }, 3000);
    });
  };

  async function handleClone(cvId) {
    const token = localStorage.getItem('token');
    try {
      const response = await http(`cv/clone/${cvId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const clonedCv = await response.json();

      window.open(`/mon-espace-cv/${userId}/${clonedCv.id}`, '_blank');

    } catch (error) {
      console.error("There was a problem with the fetch operation: " + error.message);
    }
  }

  const fetchTranslatedCompetences = async (competenceId, languageCode) => {
    const token = localStorage.getItem('token');
    try {
      const response = await http(`competences/translate/${competenceId}/${languageCode}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const translatedCompetence = await response.json();
      return translatedCompetence;
    } catch (error) {
      console.error("Error al obtener la competencia traducida:", error);
    }
  };

  const handleLanguageChange = async (newLanguage) => {
    setCurrentLanguage(newLanguage);
    const translatedCompetences = await Promise.all(
      competences.map(async competence => {
        const translatedData = await fetchTranslatedCompetences(competence.id, newLanguage);
        return { ...competence, ...translatedData };
      })
    );
    setCompetences(translatedCompetences);
  };

  useEffect(() => {
    const updateCompetences = async () => {
      const translatedCompetences = await Promise.all(
        competences.map(async competence => {
          const translatedData = await fetchTranslatedCompetences(competence.id, currentLanguage);
          return { ...competence, ...translatedData };
        })
      );
      setCompetences(translatedCompetences);
    };

    updateCompetences();
  }, [currentLanguage]);


  const [sortedSuggestions, setSortedSuggestions] = useState({});

  useEffect(() => {
    const newSortedSuggestions = Object.keys(suggestions).reduce((acc, categoryId) => {
      if (Array.isArray(suggestions[categoryId])) {
        acc[categoryId] = suggestions[categoryId]
          .filter(suggestion =>
            !competences.some(comp => comp.competenceName === suggestion.competenceName)
          )
          .sort((a, b) => {
            if (!a.competenceName) return 1;
            if (!b.competenceName) return -1;

            return a.competenceName.localeCompare(b.competenceName);
          });
      }
      return acc;
    }, {});

    setSortedSuggestions(newSortedSuggestions);
  }, [suggestions, competences]);


  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    let errors = {};

    if (!cvData?.langues || cvData.langues === "") {
      errors.langues = "Sélectionnez la langue de votre cv";
    }

    if (!cvData?.mot_cle || cvData.mot_cle.trim() === "") {
      errors.mot_cle = "Définissez un mot clé pour le CV.";
    }

    if (!cvData?.presentation || cvData.presentation.trim() === "") {
      errors.presentation = "Définissez une présentation pour le CV.";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleLanguagesClick = () => {
    if (validateForm()) {
      setLanguagesVisible(!areLanguagesVisible);
    }
  };

  const handleCompetencesClick = () => {
    if (validateForm()) {
      setCompetencesVisible(!areCompetencesVisible);
    }
  };

  const handleExperiencesClick = () => {
    if (validateForm()) {
      setExperiencesVisible(!areExperiencesVisible);
    }
  };

  const handleFormationsClick = () => {
    if (validateForm()) {
      setFormationsVisible(!areFormationsVisible);
    }
  };

  const handleLoisirsClick = () => {
    if (validateForm()) {
      setLoisirsVisible(!areLoisirsVisible);
    }
  };

  const moveExperienceUp = (index) => {
    if (index === 0) return;
    const newExperiences = [...experiences];
    [newExperiences[index - 1], newExperiences[index]] = [newExperiences[index], newExperiences[index - 1]];
    newExperiences.forEach((exp, idx) => {
      exp.orderIndex = idx;
    });
    setExperiences(newExperiences);
  };

  const moveExperienceDown = (index) => {
    if (index === experiences.length - 1) return;
    const newExperiences = [...experiences];
    [newExperiences[index + 1], newExperiences[index]] = [newExperiences[index], newExperiences[index + 1]];
    newExperiences.forEach((exp, idx) => {
      exp.orderIndex = idx;
    });
    setExperiences(newExperiences);
  };

  const moveFormationUp = (index) => {
    if (index === 0) return;
    const newFormations = [...formations];
    [newFormations[index - 1], newFormations[index]] = [newFormations[index], newFormations[index - 1]];
    setFormations(newFormations);
  };

  const moveFormationDown = (index) => {
    if (index === formations.length - 1) return;
    const newFormations = [...formations];
    [newFormations[index + 1], newFormations[index]] = [newFormations[index], newFormations[index + 1]];
    setFormations(newFormations);
  };

  const [suggestionSkills, setSuggestionSkills] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const message = "Bonjour,\r\nJ'aimerais ajouter une nouvelle compétence à la plateforme :";
    const userName = currentUser.prenom;
    const userLastname = currentUser.nom;
    const emailContent = `${message} ${suggestionSkills}\r\nMessage envoyé par ${userName} ${userLastname}`;
    const emailRequest = {
      to: "alexis.colombi@alcyone-consulting.lu",
      subject: "Nouvelle suggestion de compétence",
      content: emailContent
    };

    try {
      const token = localStorage.getItem("token");
      const response = await http('send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(emailRequest),
      });

      const data = await response.text();
      toast.success("Message envoyé avec succès");
      setSuggestionSkills('');
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email", error);
      toast.error("Erreur lors de l'envoi du message");
    }
  };

  const initialCheckboxState = experiences.reduce((acc, experience) => {
    acc[experience.id] = false;
    return acc;
  }, {});

  const [checkboxStates, setCheckboxStates] = useState(initialCheckboxState);

  const handleCheckboxChange = (id) => {
    setCheckboxStates(prevStates => ({
      ...prevStates,
      [id]: !prevStates[id]
    }));
  };

  const competenceOptions = Object.values(groupedCompetences).flat().map(competence => ({
    name: competence.competenceName,
    id: competence.originalIndex,
  }));

  const sumDurationsByCompetence = (experiences) => {
    const durations = {};
    const periodsByCompetence = {};

    // Collecter les périodes pour chaque compétence
    experiences.forEach(experience => {
      if (Array.isArray(experience.envTechnique)) {
        experience.envTechnique.forEach(techName => {
          if (!periodsByCompetence[techName]) {
            periodsByCompetence[techName] = [];
          }
          const endDate = experience.dateFin ? new Date(experience.dateFin) : new Date(); 
          periodsByCompetence[techName].push({
            start: new Date(experience.dateDebut),
            end: endDate
          });
        });
      }
    });

    // Calculer les durées pour chaque compétence
    Object.keys(periodsByCompetence).forEach(techName => {
      let totalDuration = 0; // Stocker la durée totale pour chaque compétence

      // Tri et fusion des périodes
      const sortedPeriods = periodsByCompetence[techName].sort((a, b) => a.start - b.start);
      let lastEnd = null;

      sortedPeriods.forEach(period => {
        if (!lastEnd || period.start > lastEnd) {
          // Pas de chevauchement, ajouter toute la durée de la période
          totalDuration += (period.end - period.start) / (1000 * 60 * 60 * 24 * 30) + 1; // Convertir en mois
          lastEnd = period.end;
        } else if (period.end > lastEnd) {
          // Chevauchement, ajouter seulement la partie non chevauchante
          totalDuration += (period.end - lastEnd) / (1000 * 60 * 60 * 24 * 30); // Convertir en mois
          lastEnd = period.end;
        }
      });

      // Calculer les années et mois à partir de la durée totale en mois
      const years = Math.floor(totalDuration / 12);
      const months = Math.floor(totalDuration % 12);
      durations[techName] = { years, months };
    });

    return durations;
  };

  const competenceDurations = sumDurationsByCompetence(experiences);

  //différence entre deux dates et formater le résultat en années et moi
  const calculateDifference = (startDate, endDate) => {
    const years = differenceInYears(endDate, startDate);
    const startDatePlusYears = addYears(startDate, years);
    //Ajoute un mois pour inclure le mois de fin complet dans le calcul
    let months = differenceInMonths(endDate, startDatePlusYears) + 1;

    //assure que le nombre de mois n'est pas négatif, le mois est réduit à 0
    months = months >= 0 ? months : 0;

    let result = "";
    if (years > 0) {
      result += `${years} ${translations.years}`;
    }
    //Si months est égal à 12, un an complet est ajouté, donc le comptage des années est ajusté et months est remis à 0.
    if (months === 12) {
      result = years > 0 ? `${years + 1} ${translations.years}` : `1 ${translations.years}`;
      months = 0;
    } else if (months > 0) {
      if (result.length > 0) {
        result += ` ${translations.and} `;
      }
      result += `${months} ${translations.months}`;
    }
    return result;
  };

  const handleFileUploadData = (type, data) => {
    switch (type) {
      case 'presentation':
        dispatch({
          type: actionTypes.UPDATE_CVDATA_FIELD,
          payload: {
            fieldName: 'presentation',
            value: data.content,
          },
        });
        break;
      case 'experiences':
        setExperiences(data);
        break;
      case 'formations':
        setFormations(data);
        break;
      case 'loisirs':
        setLoisirs(data);
        break;
      default:
        console.log('Type inconnu:', type);
    }
  };

  function calculateNextMonthDate(dateDebut) {
    if (!dateDebut) return null;

    const dateParts = dateDebut.split('-'); // format "YYYY-MM"
    const year = parseInt(dateParts[0], 10);
    let month = parseInt(dateParts[1], 10) - 1; // Les mois en js son 0-indexés

    const date = new Date(year, month);
    date.setMonth(date.getMonth() + 1); // Augmente le mois a 1

    return date;
  }

  const addCompetenceToCategory = (categoryName) => {
    const categoryId = categories[categoryName];

    if (sortedSuggestions[categoryId] && sortedSuggestions[categoryId].length > 0) {
      const firstSuggestion = sortedSuggestions[categoryId][0];
      const newCompetence = {
        id: firstSuggestion.id,
        competenceName: firstSuggestion.competenceName,
        rating: 0,
        categorie: { id: categoryId, name: categoryName },
      };

      setCompetences(prevCompetences => [...prevCompetences, newCompetence]);
    } else {
      console.log("No hay sugerencias disponibles para esta categoría.");

    }
  };

  const [showPreview, setShowPreview] = useState(false);
  const [openPdfInNewTab, setOpenPdfInNewTab] = useState(false);

  const handlePreview = () => {
    setOpenPdfInNewTab(true);
    setShowPreview(true);
  };

  useEffect(() => {
    if (pdfUrl && openPdfInNewTab) {
      window.open(pdfUrl, '_blank');
      setOpenPdfInNewTab(false);
      setPdfUrl(null);
      setShowPreview(false);
    }
  }, [pdfUrl, openPdfInNewTab]);


  const [experienceYears, setExperienceYears] = useState(0);
  const [experienceMonths, setExperienceMonths] = useState(0);

  useEffect(() => {
    //calcule le nombre total de mois entre deux dates
    const getMonthDifference = (startDate, endDate) => {
      const start = new Date(startDate);
      const end = endDate ? new Date(endDate) : new Date();
      if (!start.getTime() || !end.getTime()) return 0;

      let months = (end.getFullYear() - start.getFullYear()) * 12;
      months -= start.getMonth();
      months += end.getMonth();
      return months <= 0 ? 0 : months + 1;
    };

    //vérifie si deux périodes données se chevauchent
    const isOverlapping = (start1, end1, start2, end2) => {
      return new Date(start1) <= new Date(end2) && new Date(start2) <= new Date(end1);
    };

    //utile pour stocker une collection d'éléments uniques
    //Sans un Set, on devrait manuellement vérifier si un mois a déjà été compté
    //bénéfique pour des applications traitant de grandes quantités de données
    //éviter temps d'exécution et utilisation intensive de la mémoire (bugs)
    const allUniqueMonths = new Set();

    const experiencePeriods = experiences.map(exp => ({
      start: new Date(exp.dateDebut),
      end: exp.isCurrentJob ? new Date() : new Date(exp.dateFin),
      factor: ["Stage", "Alternance"].includes(exp.typeContrat) ? 2 / 3 : 1
    }));

    //Ajout de mois 
    //Les mois de Janvier 2020 à Décembre 2020 sont ajoutés un par un dans le Set
    experiencePeriods.forEach(exp => {
      let currentStart = new Date(exp.start.getFullYear(), exp.start.getMonth(), 1);
      while (currentStart <= exp.end) {
        //Chaque mois est formaté en "YYYY-MM" et ajouté s'il n'est pas déjà présent "2020-01", "2020-02", ..., "2020-12"
        //ACO 1= Les mois 10/2020 à 12/2020 sont également ajoutés
        //ACO 2=Lorsque on ajout oct, nov, et Dec 2020, le Set détecte que ces mois existent déjà (ajoutés par ACO 1) et ne les ajoute pas une deuxième fois
        //ACO 3= Les mois d'Oct 2020 à Dec 2021 sont ajoutés
        //Comme pour ACO 2, Oct à Dec 2020 ne sont pas ajoutés à nouveau car ils existent déjà
        //Les mois de Janv 2021 à Déc 2021 sont ajoutés sans duplication car ils n'étaient pas encore présents
        allUniqueMonths.add(currentStart.toISOString().slice(0, 7)); // Format YYYY-MM
        currentStart.setMonth(currentStart.getMonth() + 1);
      }
    });

    // Calculer le total des mois uniques
    const totalMonths = allUniqueMonths.size;

    setExperienceYears(Math.floor(totalMonths / 12));
    setExperienceMonths(totalMonths % 12);
  }, [experiences]);

  return (
    <MDBContainer
      fluid
      className="fond-accueil"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="row py-5 justify-content-around animation-fade-in">
        <div className={`col-md-6 px-5 scrollable-col ${isClientRole ? 'hide-for-client' : ''}`}>
          <div className="justify-content-center align-content-center text-center">
            <MDBTypography variant="h2" className="text-light pt-5 pb-3">
              {translations.myCvSpace}
            </MDBTypography>
            {currentUser && managerRoles.includes(currentUser.role) && (
              <>
                <FileUploadForm onUploadData={handleFileUploadData} userId={userId} cvId={cvId} />
              </>
            )}
            <div
              className="justify-content-center pt-3"
              style={{ display: "flex", alignItems: "center", color: "white" }}
            >
              <p>{translations.makeMyCvAnonymous}</p>
              <Switch
                checked={isAnonymous}
                onChange={(event) => {
                  setIsAnonymous(event.target.checked);
                }}
                className="mb-3"
              />
              {currentUser && managerRoles.includes(currentUser.role) && (
                <>
                  <p>{translations.makeExpCacher}</p>
                  <Switch className="mb-3" onChange={handleSwitchChange} />
                </>
              )}
            </div>
            {currentUser && managerRoles.includes(currentUser.role) ? (
              <div className="text-center mb-4">
                <MDBBtn type="submit" className="btn_primary" onClick={handleSave} style={{ width: "250px" }}>
                  {translations.confirm}
                </MDBBtn>
              </div>
            ) : (
              currentUser && candidateRoles.includes(currentUser.role) && (
                <div className="d-flex justify-content-center mt-4 mb-3 gap-2">
                  <MDBBtn type="submit" className="btn_primary" onClick={handleSave} style={{ width: "250px" }}>
                    {translations.confirm}
                  </MDBBtn>
                  <MDBBtn type="submit" className="btn_primary" onClick={handleConfirmToManager} style={{ width: "530px" }}>
                    {translations.informManager}
                  </MDBBtn>
                </div>

              )
            )}

            <div className="d-flex justify-content-between gap-2">
              <div>
                <select
                  className={`form-select ${formErrors.langues ? 'border-danger' : ''}`}
                  id="cvLanguage"
                  name="cvLanguage"
                  onChange={handleFormChange}
                  value={cvData?.langues || ''}
                >
                  <option defaultValue>{translations.cvLanguage}</option>
                  <option value="FR">{translations.french}</option>
                  <option value="EN">{translations.english}</option>
                </select>
                {formErrors.langues && (
                  <small className="">
                    <i className="fas fa-exclamation-triangle text-danger"></i> <span className="text-white">{formErrors.langues}</span>
                  </small>
                )}
              </div>
              <div>
                <div className="input-icon-container">
                  <MDBInput
                    type="text"
                    label={translations.labels.jobTitle}
                    id="poste"
                    placeholder="Ex: Développeur Fullstack"
                    className="text-light input-acv form-control-input"
                    contrast
                    name="poste"
                    value={cvData && cvData.poste !== null ? cvData.poste : ""}
                    onChange={handleFormChange}
                  />
                  <span title="Définissez le poste'/ métier visé par le nouveau CV." className="info-icon">
                    <i className="fas fa-info-circle ms-1"></i>
                  </span>
                </div>
              </div>
              <div>
                <div className="input-icon-container">
                  <MDBInput
                    type="text"
                    label={translations.labels.cvName}
                    id="mot_cle"
                    placeholder="Ex: Front-end"
                    className={`text-light input-acv form-control-input ${formErrors.mot_cle ? 'border-danger' : ''}`}
                    contrast
                    name="mot_cle"
                    value={cvData?.mot_cle ?? ""}
                    onChange={handleFormChange}
                  />
                  <span title="Définissez une mot clé à le CV." className="info-icon">
                    <i className="fas fa-info-circle"></i>
                  </span>
                </div>
                <div>
                  {formErrors.mot_cle && (
                    <small className="">
                      <i className="fas fa-exclamation-triangle text-danger"></i><span className="text-white">{formErrors.mot_cle}</span>
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div>
              <MDBTextArea
                id="presentation"
                name="presentation"
                label={translations.labels.presentation}
                className={`mt-3 form-control-input ${formErrors.presentation ? 'border-danger' : ''}`}
                rows={2}
                contrast
                value={cvData?.presentation ?? ""}
                onChange={handleFormChange}
              />
              {formErrors.presentation && (
                <small className="">
                  <i className="fas fa-exclamation-triangle text-danger"></i> <span className="text-white">{formErrors.presentation}</span>
                </small>
              )}
            </div>
          </div>
          <div>
            <div>
              <div className="d-flex flex-column justify-content-center mt-3 gap-2">
                <LanguageSkills
                  languageSkills={languageSkills}
                  setLanguageSkills={setLanguageSkills}
                  handleLanguageSkillChange={handleLanguageSkillChange}
                  translations={translations}
                  areLanguagesVisible={areLanguagesVisible}
                  handleLanguagesClick={handleLanguagesClick}
                />
                <div className="text-white">
                  <div className="subtitre-cv-container text-underline d-flex align-items-center gap-2"
                    onClick={handleCompetencesClick}
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className="text-underline pt-2 text-white">
                      {translations.businessSkills}
                    </h4>
                    <div className="line bg-white"></div>
                    <MDBIcon
                      fas
                      icon={"trophy"}
                      style={{ color: "white" }}
                      size="lg"
                    />
                  </div>
                  {areCompetencesVisible && (
                    <CompetencesComponent
                      categories={categories}
                      suggestions={suggestions}
                      groupedCompetences={groupedCompetences}
                      sortedSuggestions={sortedSuggestions}
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      searchResults={searchResults}
                      activeSuggestionIndex={activeSuggestionIndex}
                      handleMouseEnter={handleMouseEnter}
                      handleKeyDown={handleKeyDown}
                      addSelectedCompetence={addSelectedCompetence}
                      handleCompetenceChange={handleCompetenceChange}
                      removeCompetence={removeCompetence}
                      translations={translations}
                      addCompetence={addCompetence}
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      suggestionSkills={suggestionSkills}
                      setSuggestionSkills={setSuggestionSkills}
                      handleSubmit={handleSubmit}
                      addCompetenceToCategory={addCompetenceToCategory}
                    />
                  )}
                </div>
                <div className="text-white">
                  <div className="subtitre-cv-container text-underline d-flex align-items-center gap-2"
                    onClick={handleExperiencesClick}
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className="text-underline pt-1 text-white">{translations.experiences}</h4>
                    <div className="line bg-white"></div>
                    <MDBIcon
                      fas
                      icon={"briefcase"}
                      style={{ color: "white" }}
                      size="lg"
                    />
                  </div>
                  <div>
                    {areExperiencesVisible && (
                      <ExperiencesComponent
                        experiences={experiences}
                        addNewAction={addNewAction}
                        moveExperienceUp={moveExperienceUp}
                        moveExperienceDown={moveExperienceDown}
                        removeExperience={removeExperience}
                        handleExperienceChange={handleExperienceChange}
                        calculateNextMonthDate={calculateNextMonthDate}
                        checkboxStates={checkboxStates}
                        handleCheckboxChange={handleCheckboxChange}
                        handleExperienceActionChange={handleExperienceActionChange}
                        handleRemoveAction={handleRemoveAction}
                        competenceOptions={competenceOptions}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        searchResults={searchResults}
                        activeSuggestionIndex={activeSuggestionIndex}
                        handleMouseEnter={handleMouseEnter}
                        handleKeyDown={handleKeyDown}
                        addSelectedCompetence={addSelectedCompetence}
                        translations={translations}
                        moveAction={moveAction}
                      />
                    )}
                  </div>
                </div>

                <div className="text-white">
                  <div className="subtitre-cv-container text-underline d-flex align-items-center gap-2"
                    onClick={handleFormationsClick}
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className="text-underline pt-1 text-white">{translations.formations}</h4>
                    <div className="line bg-white"></div>
                    <MDBIcon
                      fas
                      icon={"graduation-cap"}
                      style={{ color: "white" }}
                      size="lg"
                    />
                  </div>
                  {areFormationsVisible && (
                    <FormationsComponent
                      formations={formations}
                      translations={translations}
                      handleFormationChange={handleFormationChange}
                      moveFormationUp={moveFormationUp}
                      moveFormationDown={moveFormationDown}
                      removeFormation={removeFormation}
                    />
                  )}
                </div>
                <div className="text-white">
                  <div className="subtitre-cv-container text-underline d-flex align-items-center gap-2 mb-3"
                    onClick={handleLoisirsClick}
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className="text-underline pt-1 text-white">{translations.hobbies}</h4>
                    <div className="line bg-white"></div>
                    <MDBIcon
                      fas
                      icon={"futbol"}
                      style={{ color: "white" }}
                      size="lg"
                    />
                  </div>
                  {areLoisirsVisible && (
                    <LoisirsComponent
                      loisirs={loisirs}
                      onLoisirChange={handleLoisirChange}
                      onRemoveLoisir={removeLoisir}
                    />
                  )}
                </div>
              </div>
            </div>

            {currentUser && managerRoles.includes(currentUser.role) ? (
              <div className="text-center mt-4">
                <MDBBtn type="submit" className="btn_primary" onClick={handleSave} style={{ width: "250px" }}>
                  {translations.confirm}
                </MDBBtn>
              </div>
            ) : (
              currentUser && candidateRoles.includes(currentUser.role) && (
                <div className="d-flex justify-content-center mt-4 mb-3 gap-2">
                  <MDBBtn type="submit" className="btn_primary" onClick={handleSave} style={{ width: "250px" }}>
                    {translations.confirm}
                  </MDBBtn>
                  <MDBBtn type="submit" className="btn_primary" onClick={handleConfirmToManager} style={{ width: "530px" }}>
                    {translations.informManager}
                  </MDBBtn>
                </div>
              )
            )}
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 cv-preview-container px-5 scrollable-col">
          <div className="d-flex justify-content-end">
            <MDBIcon
              far
              icon="images"
              onClick={handleIconClick}
              size="2x"
              title="Changer fond d'écran"
              style={{ cursor: "pointer", color: "#09B4BF" }}
            />
          </div>
          <div className="text-center">
            <h2 className="text-white">{translations.cvPreview}</h2>
            <div className={isClientRole ? 'hide-for-client' : ''}>
              <MDBBadge pill className="mx-2" color="info" light>
                {`ACO__${userData?.prenom}_${userData?.nom?.toUpperCase() || ""
                  }_CV_${cvData?.langues?.toUpperCase() + ".pdf"}`}
              </MDBBadge>
            </div>
          </div>
          <div className={`row ${isClientRole ? 'hide-for-client' : ''}`}>
            <div className={currentUser && candidateRoles.includes(currentUser.role) ? "col-6" : "col-12 col-md-3 col-lg-3"}>
              <>
                <div
                  className="text-underline d-flex gap-1 align-items-center pt-3 text-white justify-content-center"
                  style={{ cursor: "pointer" }}
                  onClick={handlePreview}
                >
                  <MDBIcon fas icon="eye" size="2x" style={{ color: "#09B4BF" }} />{" "}
                  {translations.preview}
                </div>
                {showPreview && (
                  <BlobProvider
                    document={
                      <CvPdf
                        userData={userData}
                        cvData={cvData}
                        languageSkills={languageSkills}
                        groupedCompetences={groupedCompetences}
                        competenceDurations={competenceDurations}
                        translations={translations}
                        experiences={experiences}
                        isAnonymous={isAnonymous}
                        formatDate={formatDate}
                        calculateDifference={calculateDifference}
                        formations={formations}
                        loisirs={loisirs}
                        showExp={showExp}
                        experienceYears={experienceYears}
                        experienceMonths={experienceMonths}
                      />
                    }
                  >
                    {({ url }) => {
                      if (url) {
                        setPdfUrl(url);
                      }
                      return null;
                    }}
                  </BlobProvider>
                )}
              </>
            </div>
            <div className={currentUser && candidateRoles.includes(currentUser.role) ? "col-6" : "col-12 col-md-3 col-lg-3"}>
              <div className="text-underline pt-3 text-center text-white" style={{ cursor: "pointer" }} onClick={() => handleClone(cvId)}>
                <MDBIcon
                  fas
                  icon="copy"
                  size="2x"
                  style={{ color: "#09B4BF" }}
                />{" "}
                {translations.duplicate}
              </div>
            </div>
            {currentUser && managerRoles.includes(currentUser.role) && (
              <>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="text-underline pt-3 text-center text-white" style={{ cursor: "pointer" }} onClick={toggleShow}>
                    <MDBIcon
                      fas
                      icon="cloud-download-alt"
                      size="2x"
                      style={{ color: "#09B4BF" }}
                    />{" "}
                    {translations.download}
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="text-underline pt-3 text-center text-white" style={{ cursor: "pointer" }} onClick={handleShareClick}>
                    <MDBIcon
                      fas
                      icon="share-square"
                      size="2x"
                      style={{ color: "#09B4BF" }}
                    />{" "}
                    {translations.share}
                  </div>
                  <>
                    {blobReady && (
                      <BlobProvider document={<CvPdf
                        userData={userData}
                        cvData={cvData}
                        languageSkills={languageSkills}
                        groupedCompetences={groupedCompetences}
                        competenceDurations={competenceDurations}
                        translations={translations}
                        experiences={experiences}
                        isAnonymous={isAnonymous}
                        formatDate={formatDate}
                        calculateDifference={calculateDifference}
                        formations={formations}
                        loisirs={loisirs}
                        showExp={showExp}
                        experienceYears={experienceYears}
                        experienceMonths={experienceMonths}
                      />}>
                        {({ blob }) => {
                          setPdfBlob(blob);
                          return null;
                        }}
                      </BlobProvider>
                    )}
                  </>
                </div>
              </>
            )}
          </div>
          <div className={`text-white text-center py-3 ${isClientRole ? 'hide-for-client' : ''}`}>
            <p>{translations.labels.cvName} : {cvData?.mot_cle || ""}</p>
          </div>
          <div>
            <CVPreviewComponent
              isAnonymous={isAnonymous}
              userData={userData}
              cvData={cvData}
              showExp={showExp}
              languageSkills={languageSkills}
              translations={translations}
              groupedCompetences={groupedCompetences}
              competenceDurations={competenceDurations}
              experiences={experiences}
              formatDate={formatDate}
              calculateDifference={calculateDifference}
              formations={formations}
              loisirs={loisirs}
              experienceYears={experienceYears}
              experienceMonths={experienceMonths}
              moveAction={moveAction}
              isManagerRole={isManagerRole}
              isCandidateRole={isCandidateRole}
              isClientRole={isClientRole}
              currentUser={currentUser}
            />
          </div>
          <div className={`row ${isClientRole ? 'hide-for-client' : ''}`}>
            <div className={currentUser && candidateRoles.includes(currentUser.role) ? "col-6" : "col-12 col-md-3 col-lg-3"}>
              <>
                <div
                  className="text-underline d-flex gap-1 align-items-center pt-3 text-white justify-content-center"
                  style={{ cursor: "pointer" }}
                  onClick={handlePreview}
                >
                  <MDBIcon fas icon="eye" size="2x" style={{ color: "#09B4BF" }} />{" "}
                  {translations.preview}
                </div>
                {showPreview && (
                  <BlobProvider
                    document={
                      <CvPdf
                        userData={userData}
                        cvData={cvData}
                        languageSkills={languageSkills}
                        groupedCompetences={groupedCompetences}
                        competenceDurations={competenceDurations}
                        translations={translations}
                        experiences={experiences}
                        isAnonymous={isAnonymous}
                        formatDate={formatDate}
                        calculateDifference={calculateDifference}
                        formations={formations}
                        loisirs={loisirs}
                        showExp={showExp}
                        experienceYears={experienceYears}
                        experienceMonths={experienceMonths}
                      />
                    }
                  >
                    {({ url }) => {
                      if (url) {
                        setPdfUrl(url);
                      }
                      return null;
                    }}
                  </BlobProvider>
                )}
              </>
            </div>
            <div className="col-12 col-md-3 col-lg-3">
              <div className="text-underline pt-3 text-center text-white" style={{ cursor: "pointer" }} onClick={() => handleClone(cvId)}>
                <MDBIcon
                  fas
                  icon="copy"
                  size="2x"
                  style={{ color: "#09B4BF" }}
                />{" "}
                {translations.duplicate}
              </div>
            </div>
            {currentUser && managerRoles.includes(currentUser.role) && (
              <>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="text-underline pt-3 text-center text-white" style={{ cursor: "pointer" }} onClick={toggleShow}>
                    <MDBIcon
                      fas
                      icon="cloud-download-alt"
                      size="2x"
                      style={{ color: "#09B4BF" }}
                    />{" "}
                    {translations.download}
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="text-underline pt-3 text-center text-white" style={{ cursor: "pointer" }} onClick={handleShareClick}>
                    <MDBIcon
                      fas
                      icon="share-square"
                      size="2x"
                      style={{ color: "#09B4BF" }}
                    />{" "}
                    {translations.share}
                  </div>
                  <>
                    {blobReady && (
                      <BlobProvider document={<CvPdf
                        userData={userData}
                        cvData={cvData}
                        languageSkills={languageSkills}
                        groupedCompetences={groupedCompetences}
                        competenceDurations={competenceDurations}
                        translations={translations}
                        experiences={experiences}
                        isAnonymous={isAnonymous}
                        formatDate={formatDate}
                        calculateDifference={calculateDifference}
                        formations={formations}
                        loisirs={loisirs}
                        showExp={showExp}
                        experienceYears={experienceYears}
                        experienceMonths={experienceMonths}
                      />}>
                        {({ blob }) => {
                          setPdfBlob(blob);
                          return null;
                        }}
                      </BlobProvider>
                    )}
                  </>
                </div>
              </>
            )}
          </div>
        </div >
      </div >
      <div className={isClientRole ? 'hide-for-client' : ''}>
        {isScrolled && (
          <MDBIcon
            fas
            icon="save"
            size="2x"
            style={{
              color: "#09B4BF",
              position: "fixed",
              bottom: "60%",
              right: "98,5%",
              zIndex: 1000,
            }}
            title="Sauvegarder"
            className="haut"
            onClick={handleAutoSave}
          />
        )}
      </div>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Téléchargement de votre CV</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              Vous pouvez télécharger votre CV au format PDF en
              cliquant sur le bouton ci-dessous.
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn style={{ background: "#97999C" }} onClick={toggleShow}>
                <MDBIcon fas icon="times" /> Close
              </MDBBtn>
              <PDFDownloadLink document={<CvPdf userData={userData} cvData={cvData} languageSkills={languageSkills} groupedCompetences={groupedCompetences} competenceDurations={competenceDurations} translations={translations} experiences={experiences} isAnonymous={isAnonymous} formatDate={formatDate} calculateDifference={calculateDifference} formations={formations} loisirs={loisirs} showExp={showExp} experienceYears={experienceYears} experienceMonths={experienceMonths} />}
                fileName={`ACO_${userData?.prenom}_${userData?.nom.toUpperCase()}_CV_${cvData?.langues?.toUpperCase()}_${cvData?.mot_cle}.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? 'Préparation du document...' : <MDBBtn style={{ background: "#09B4BF" }}><MDBIcon fas icon="file-pdf" /> PDF</MDBBtn>
                }
              </PDFDownloadLink>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={showModal} setShow={setShowModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Partage de votre CV</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              Vous pouvez partager ce CV en utilisant le lien ci-dessous :
              <p>
                {pdfUrl && (
                  <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                    {pdfUrl}
                  </a>
                )}
              </p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                style={{ background: "#97999C" }}
                onClick={() => copyToClipboard(pdfUrl)}
                position="relative"
              >
                {showBadge && (
                  <MDBBadge
                    color="info"
                    className="position-absolute"
                    style={{ top: "10px", right: "10px" }}
                  >
                    Lien copié !
                  </MDBBadge>
                )}
                <MDBIcon fas icon="copy" /> Copier
              </MDBBtn>
              <MDBBtn
                style={{ background: "#09B4BF" }}
                onClick={() => {
                  const body = `Bonjour,\n\nJe souhaitais partager ce CV avec vous. Vous pouvez le consulter ici: ${pdfUrl}\n\nCordialement,\n`;
                  window.location.href = generateMailtoLink(body);
                }}
              >
                <MDBIcon fas icon="link" /> Partager
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer >
  );
}
