import { MDBIcon } from 'mdb-react-ui-kit';
import React, { useState, useEffect } from 'react';

const TypewriterTitle = () => {
    const subtitles = [  
        "sur la Plateforme ACV",
        "dans la Team ACO",
    ];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentSubtitle, setCurrentSubtitle] = useState(""); 
    const [charIndex, setCharIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (charIndex < subtitles[currentIndex].length) {
                setCurrentSubtitle(prevSubtitle => prevSubtitle + subtitles[currentIndex][charIndex]);  
                setCharIndex(prevIndex => prevIndex + 1);
            } else {
                clearInterval(intervalId);
                setTimeout(() => {
                    setCharIndex(0);
                    setCurrentSubtitle("");
                    setCurrentIndex(prevIndex => (prevIndex + 1) % subtitles.length);
                }, 5000);
            }
        }, 100);

        return () => clearInterval(intervalId);
    }, [currentIndex, charIndex]);

    const renderStyledSubtitle = (subtitle) => { 
        if (subtitle === "sur la Plateforme ACV") {
            return (
                <>

                    sur la Plateforme AC<span style={{ color: "#09B4BF" }}>V</span>
                    <img src="/spinner-alcyone.png" alt="alcyone" style={{ filter: 'brightness(150%)', width: "40px" }} />

                </>
            );
        } else if (subtitle === "dans la Team ACO") {
            return (
                <>
                    dans la <br />Team AC<span style={{ color: "#09B4BF" }}>O</span><MDBIcon fas icon="heart" className='ms-2' />
                </>
            );
        }
        return subtitle;
    }

    return (
        <div className="typewriter-container text-light h1">
            <span>Bienvenue </span>{renderStyledSubtitle(currentSubtitle)}
        </div>
    );
};

export default TypewriterTitle;
