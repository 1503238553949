import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { DarkModeProvider } from './components/assets/DarkModeContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthGuard from './components/SSO/AuthGuard';
import CreatePassword from "./Pages/Inscription/CreatePassword";
import CreatePasswordClient from "./Pages/Inscription/CreatePasswordClient";
import Inscription from './Pages/Inscription/Inscription';
import { CvDataProvider } from './Pages/EditionCv/CvDataContext';
import { PdfProvider } from './components/assets/PdfContext';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <DarkModeProvider>
        <PdfProvider>
          <ToastContainer position="top-center" />
          <Route path="/inscription" component={Inscription} />
          <Route path="/create-password/:token" component={CreatePassword} />
          <Route path="/create-password-client/:token" component={CreatePasswordClient} />
          {/* <Route path="/validation/:token/:dataDuration?"  component={ValidationForm} /> */}
          {/* <Route path="/validation/:token/:dataDuration?" render={(props) => <ValidationForm {...props} user={currentUser} />} /> */}
          <CvDataProvider>
            <AuthGuard>
              <App />
            </AuthGuard>
          </CvDataProvider>
        </PdfProvider>
      </DarkModeProvider>
    </Router>
  </React.StrictMode >
);

serviceWorkerRegistration.register();
reportWebVitals();
