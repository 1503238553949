import React, { useState } from 'react';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

function CustomPDFDisplay({ pdfFile, filteredUser, handleClose, currentUser, overrideRoleBehavior = false }) {
    const [_viewerState, setViewerState] = useState(null);
    const [showPdfViewer, setShowPdfViewer] = useState(true);

    const displayUser = (overrideRoleBehavior || currentUser?.role === 'CANDIDAT' ||
        currentUser?.role === 'CONSULTANT') ? currentUser : filteredUser;
    // const displayUser = currentUser?.role === 'CANDIDAT' ? currentUser : filteredUser;

    return (
        <div className='text-center text-white pt-5'>
            {showPdfViewer && pdfFile ? (
                <div style={{ height: '750px' }}>
                    <h2 className="text center pb-4">CV d'origine : </h2>
                    <p className='h4'>{displayUser?.prenom} {displayUser?.nom}</p>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.10.111/build/pdf.worker.min.js">
                        <Viewer
                            fileUrl={pdfFile}
                            onStateChange={setViewerState}
                            initialZoom={SpecialZoomLevel.PageFit}
                        />
                    </Worker>
                </div>
            ) : (
                <div className="text-center text-white">
                    <p>Aucun fichier PDF sélectionné</p>
                </div>
            )}
        </div>
    );
}

export default CustomPDFDisplay;


