import { useState } from 'react';

import {
    MDBBtn,
    MDBFile,
    MDBInput,
    MDBTextArea
} from 'mdb-react-ui-kit';
import { toast } from 'react-toastify';
import { http } from '../../components/services/httpService';
import { useDarkMode } from '../../components/assets/DarkModeContext';

function AddTestimony() {
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [photo, setPhoto] = useState(null);
    const [_temoignages, setTemoignages] = useState([]);
    const { isDarkMode } = useDarkMode();

    const maxChars = 100; // Maximum de caractères autorisés

    const handleTextChange = (e) => {
        setMessage(e.target.value);
    };

    const isMessageValid = () => {
        return message.length <= maxChars;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('name', name);
        formData.append('message', message);
        if (photo) {
            formData.append('photo', photo);
        }

        try {
            const response = await http(`temoignages/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData
            });

            if (response.ok) {
                const newTemoignage = await response.json();
                toast.success('Témoignage ajouté avec succès!');

                setTemoignages(prevTemoignages => [...prevTemoignages, newTemoignage]);
            } else {
                console.error('Erreur lors de la demande:', response.statusText);
                toast.error('Erreur lors de l’ajout du témoignage. Veuillez réessayer.');
            }
        } catch (error) {
            toast.error('Une erreur est survenue. Veuillez réessayer.');
        }
    }

    return (
        <form className="w-50 m-auto" onSubmit={handleSubmit}>
            <div className='mb-2'>
                <MDBInput contrast={isDarkMode} label='Prénom et nom' id='form1' type='text' value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className='mb-2'>
                <MDBTextArea contrast={isDarkMode} label='Message (max 100 caractères)' id='textAreaExample' rows={4} value={message} onChange={handleTextChange} placeholder="Message" />
                <div className="text-right" style={{ color: message.length > maxChars ? 'red' : 'inherit' }}>
                    {`${message.length}/${maxChars}`}
                </div>
            </div>
            <div className='mb-2'>
                <MDBFile onChange={(e) => setPhoto(e.target.files[0])} id='customFile' />
            </div>
            <div className='text-center'>
                <MDBBtn className="BtnCard" type="submit" disabled={!isMessageValid()}>Ajouter</MDBBtn>
            </div>
        </form>
    );
}

export default AddTestimony;