import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { getUserInitials } from './Fonctions';
import { parseISO } from "date-fns";

const CvPdf = ({ userData, cvData, languageSkills, groupedCompetences, competenceDurations, translations, experiences, isAnonymous, formatDate, calculateDifference, formations, loisirs, showExp, experienceYears,
    experienceMonths }) => {

    const Header = ({ imageUrl }) => (
        <View style={styles.header} fixed>
            <Image style={styles.image} src={imageUrl} />
        </View>
    );

    const Footer = ({ imageUrl, translations }) => (
        <View style={styles.footer} fixed>
            <Image style={styles.image} src={imageUrl} />
            {/* <Text style={[styles.footerTextBold, { top: 10 }]}>{translations.inTouch}</Text> */}
            <Text style={[styles.footerText, { top: 10 }]}>+ 352 27 86 17 46</Text>
            <Text style={[styles.footerText, { top: 25 }]}>contact@alcyone-consulting.lu</Text>
        </View>
    );

    const filteredLoisirs = loisirs.filter(loisir => loisir.trim() !== '');

    const languageMap = {
        "Français": "french",
        "Anglais": "english",
        "Espagnol": "spanish",
        "Luxembourgeois": "luxembourgish",
        "Chinois": "chinese",
        "Arabe": "arabic",
        "Portugais": "portuguese",
        "Russe": "russian",
        "Allemand": "german",
        "Langue maternelle": "motherTongue",
    };

    const translate = (key) => {
        if (translations[key]) {
            return translations[key];
        }
        if (translations.labels && translations.labels[key]) {
            return translations.labels[key];
        }
        const translationKey = languageMap[key] || key.toLowerCase().replace(/\s+/g, '_');
        return translations[translationKey] || translations.labels[translationKey] || key;
    };

    const competenceKeys = Object.keys(groupedCompetences);

    const DashRating = ({ rating }) => {
        const totalDashes = 5;
        const ratingCount = rating;

        return (
            <View style={styles.ratingContainer}>
                {Array.from({ length: totalDashes }).map((_, index) => (
                    <Text
                        key={index}
                        style={[
                            styles.dash,
                            index < ratingCount ? styles.dashActive : styles.dashInactive,
                        ]}
                    >
                        -
                    </Text>
                ))}
            </View>
        );
    };

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Header imageUrl="/logo-cv.png" />
                <View style={[styles.section, { marginTop: -60 }]}>
                    <Image
                        style={styles.imagePage}
                        src="/spinner-alcyone.png" />
                    <Text style={styles.user}>
                        {isAnonymous ? `${getUserInitials(userData).firstNameInitials} ${getUserInitials(userData).lastNameInitials}` : `${userData?.prenom} ${userData?.nom?.toUpperCase()}`}
                    </Text>
                    <Text style={styles.poste}>{cvData?.poste}</Text>
                </View>
                {showExp && (
                    <View style={styles.calculExp}>
                        <Text>{translations.workExperience.replace("{experienceYears}", experienceYears).replace("{experienceMonths}", experienceMonths)}</Text>
                    </View>
                )}
                <View style={styles.section}>
                    <Text>{cvData.presentation}</Text>
                </View>
                <View>
                    <View style={styles.titleContainer}>
                        <Text style={styles.sectionTitle}>{translations.languages}</Text>
                        <View style={styles.line} />
                    </View>
                    <View style={styles.table}>
                        <View style={[styles.section, styles.tableHeader]}>
                            <Text style={styles.row1}></Text>
                            <Text style={styles.tableRow}>{translations.labels.understand}</Text>
                            <Text style={styles.tableRow}>{translations.labels.express}</Text>
                        </View>
                        <View style={styles.sectionTable}>
                            <Text style={styles.row1}></Text>
                            <Text style={styles.row2}>{translations.labels.speak}</Text>
                            <Text style={styles.row2}>{translations.labels.write}</Text>
                            <Text style={styles.row3}>{translations.labels.speak}</Text>
                            <Text style={styles.row3}>{translations.labels.write}</Text>
                        </View>
                        <View style={styles.divider}></View>
                        {languageSkills
                            .filter(skill => skill.name)
                            .map((skill, index, array) => (
                                <React.Fragment key={index}>
                                    <View style={styles.section}>
                                        <Text style={[styles.row1, styles.bold]}>{translate(skill.name)}</Text>
                                        <Text style={styles.row2}>{translate(skill.oralComprehension)}</Text>
                                        <Text style={styles.row2}>{translate(skill.writtenComprehension)}</Text>
                                        <Text style={styles.row3}>{translate(skill.oralExpression)}</Text>
                                        <Text style={styles.row3}>{translate(skill.writtenExpression)}</Text>
                                    </View>
                                    {index < array.length - 1 && (
                                        <View style={styles.divider}></View>
                                    )}
                                </React.Fragment>
                            ))}
                        <View style={styles.divider}></View>
                    </View>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <View style={styles.titleContainer}>
                        <Text style={styles.sectionTitle}>{translations.businessSkills}</Text>
                        <View style={styles.line} />
                    </View>
                    {competenceKeys.length > 0 &&
                        competenceKeys.map((category, index) => (
                            <View key={index}>
                                <View style={styles.sectionCompetence}>
                                    <View style={styles.categoryColumn}>
                                        <Text style={styles.category}>{category}</Text>
                                    </View>
                                    <View style={styles.competencesColumn}>
                                        <View style={styles.sectionCompetence}>
                                            {groupedCompetences[category].map((competence, subIndex) => (
                                                <View key={subIndex} style={styles.competence}>
                                                    <Text style={[styles.competenceName, styles.textStart]}>{competence.competenceName}</Text>
                                                    <DashRating rating={competence.rating} />
                                                    {competence.competenceName && competenceDurations[competence.competenceName] && (
                                                        <View style={styles.competenceDuration}>
                                                            <Text>
                                                                {`${`${competenceDurations[competence.competenceName].years > 0
                                                                    ? `${competenceDurations[competence.competenceName].years} ${translations.years} `
                                                                    : ''}${competenceDurations[competence.competenceName].months > 0
                                                                        ? `${competenceDurations[competence.competenceName].months} ${translations.months}`
                                                                        : ''
                                                                    }`.trim()
                                                                    }`}
                                                            </Text>
                                                        </View>
                                                    )}
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                </View>
                                {index < competenceKeys.length - 1 && groupedCompetences[category].length > 0 && (
                                    <View style={styles.divider} />
                                )}
                            </View>
                        ))}
                </View>
                <View style={styles.experienceSection}>
                    <View style={styles.titleContainer}>
                        <Text style={styles.sectionTitle}>{translations.experiences}</Text>
                        <View style={styles.line} />
                    </View>
                    {experiences.map((experience, index) => {
                        const validActions = experience.actions.filter(action => action.trim());
                        const diff = experience.isCurrentJob
                            ? calculateDifference(parseISO(experience.dateDebut), new Date())
                            : calculateDifference(parseISO(experience.dateDebut), parseISO(experience.dateFin));

                        return (
                            <View key={`experience-${experience.id}-${index}`}>
                                <View style={styles.section}>
                                    <View style={styles.leftColumn}>
                                        <Text style={[styles.bold, styles.textStart]}>
                                            {isAnonymous && experience.societe ? translations.labels.company : experience.societe}
                                        </Text>
                                        <Text style={[styles.bold, styles.textStart]}>
                                            {translations.sectorActivite[experience.secteur]}
                                        </Text>
                                        <Text>{experience.lieu}</Text>
                                        <Text style={styles.textDate}>
                                            {
                                                experience.dateDebut && (experience.dateFin || experience.isCurrentJob) ?
                                                    `${formatDate(experience.dateDebut)} - ${experience.isCurrentJob ? translations.labels.now : formatDate(experience.dateFin)}`
                                                    : formatDate(experience.dateDebut)
                                            }

                                        </Text>
                                        <View>
                                            {diff && (
                                                <Text style={styles.textDate}>{`(${diff})`}</Text>
                                            )}
                                        </View>
                                    </View>
                                    <View style={styles.rightColumn}>
                                        <View style={styles.sectionCompetence}>
                                            <Text style={[styles.client, styles.bold]}>{experience.poste} </Text>
                                            {experience.client && (
                                                <Text style={[styles.missionText, styles.bold]}>
                                                    {` - ${translations.labels.mission} ${isAnonymous && experience.client ? 'Client' : experience.client
                                                        }`}
                                                </Text>
                                            )}
                                        </View>
                                        <Text>
                                            {translations.contractTypes[experience.typeContrat]}
                                        </Text>
                                        {experience.contexte && experience.contexte.trim() !== "" && (
                                            <>
                                                <Text style={styles.category}>
                                                    {translations.labels.context}
                                                </Text>
                                                <Text>
                                                    {experience.contexte}
                                                </Text>
                                            </>
                                        )}
                                        {experience.realisation && experience.realisation.trim() !== "" && (
                                            <>

                                                <Text style={styles.category}>
                                                    {translations.labels.realisation}
                                                </Text>
                                                <Text>
                                                    {experience.realisation}
                                                </Text>
                                            </>
                                        )}
                                        {validActions.length > 0 && (
                                            <>
                                                <Text style={styles.category}>
                                                    {translations.labels.responsibilities}
                                                </Text>
                                                <View>
                                                    {validActions.map((action, actionIndex) => (
                                                        <View key={actionIndex} style={{ flexDirection: "column", marginBottom: 4 }}>
                                                            <View style={{ flexDirection: "row", marginBottom: 4, paddingRight: 20 }}>
                                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                                <Text> {action}</Text>
                                                            </View>
                                                        </View>
                                                    ))}
                                                </View>
                                            </>
                                        )}

                                        {experience.envTechnique && Array.isArray(experience.envTechnique) && experience.envTechnique.length > 0 && (
                                            <>
                                                <Text style={styles.category}>{translations.labels.techEnvironment}</Text>
                                                <View style={styles.section}>
                                                    <Text>{experience.envTechnique.join(", ")}</Text>
                                                </View>
                                            </>
                                        )}
                                    </View>
                                </View>
                                {
                                    index < experiences.length - 1 && (
                                        (experience.societe || experience.contexte) &&
                                        (experiences[index + 1].societe || experiences[index + 1].contexte) && (
                                            <View style={styles.sectionDivider}></View>
                                        )
                                    )
                                }
                            </View>
                        );
                    })}
                </View>
                <View style={styles.formationSection}>
                    <View style={styles.titleContainer} break>
                        <Text style={styles.sectionTitle}>{translations.formations}</Text>
                        <View style={styles.line} />
                    </View>
                    {formations.map((formation, index) => (
                        <View key={`formation-${formation.id}-${index}`}>
                            <View style={styles.section}>
                                <View style={styles.leftColumn}>
                                    <Text style={[styles.bold, styles.textStart]}>
                                        {isAnonymous && formation.ecole ? translations.labels.school : formation.ecole}
                                    </Text>
                                    <Text>
                                        {translations.sectorActivite[formation.secteur]}
                                    </Text>
                                    <Text>{formation.lieu}</Text>
                                    <Text style={styles.textDate}>
                                        {formation.dateDebut ? formatDate(formation.dateDebut) : ""}
                                        {formation.dateDebut && (formation.dateFin || formation.isCurrentFormation)
                                            ? " - "
                                            : ""}
                                        {formation.isCurrentFormation ? translations.labels.ongoing : (formation.dateFin ? formatDate(formation.dateFin) : "")}
                                    </Text>
                                </View>
                                <View style={styles.rightColumn}>
                                    <Text style={styles.category}>
                                        {translations.educationLevels[formation.niveau]}
                                    </Text>
                                    <Text>{formation.intitule}</Text>
                                </View>
                            </View>
                            {index < formations.length - 1 && (
                                (formation.ecole || formation.intitule) &&
                                (formations[index + 1].ecole || formations[index + 1].intitule) && (
                                    <View style={styles.divider} />
                                )
                            )}
                        </View>
                    ))}
                </View>
                <View style={styles.hobbiesSection}>
                    <View style={styles.titleContainer}>
                        <Text style={styles.sectionTitle}>{translations.hobbies}</Text>
                        <View style={styles.line} />
                    </View>
                    <View style={styles.sectionCompetence}>
                        {filteredLoisirs.map((loisir, index) => (
                            <Text key={index} style={styles.loisir}>
                                {loisir}{index < filteredLoisirs.length - 1 && ", "}
                            </Text>
                        ))}
                    </View>
                </View>
                <Footer
                    imageUrl="/footer-gpw-pdf.png"
                    translations={translations}
                />

            </Page>
        </Document >
    )
};

Font.register({
    family: 'Arial',
    fonts: [
        { src: require('./font/arial.ttf') },
        { src: require('./font/G_ari_bd.TTF'), fontWeight: 'bold' },
    ],
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontSize: 10,
        textAlign: 'justify',
        paddingBottom: 80,
        paddingTop: 110,
        paddingHorizontal: 40,
        fontFamily: 'Arial'
    },
    textDate: {
        fontSize: 9,
    },
    bold: {
        fontFamily: 'Arial',
        fontWeight: 'bold',
    },
    section: {
        flexDirection: 'row',
        marginBottom: 10,
        fontSize: 10,
    },
    sectionCompetence: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    textStart: {
        textAlign: 'start',
    },
    category: {
        fontSize: 10,
        color: '#09B4BF',
        marginBottom: 4,
        marginTop: 10,
        textAlign: 'start',
    },
    client: {
        fontSize: 10,
        color: '#09B4BF',
        marginBottom: 4,
        marginTop: 4,
        textAlign: 'start',
    },
    missionText: {
        marginTop: 4,
        marginBottom: 4,
    },
    competence: {
        width: '50%',
        marginBottom: 2,
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
    },
    competenceName: {
        fontSize: 10,
        margin: 3,
        width: "40%",
        marginRight: 10,
        marginLeft: 5,
        textAlign: 'start',
    },
    competenceDuration: {
        fontSize: 8,
        width: "40px",
    },
    ratingContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: "60px"
    },
    dash: {
        fontSize: 12,
        marginRight: 2,
    },
    dashActive: {
        color: '#09B4BF',
    },
    dashInactive: {
        color: '#CCCCCC',
    },
    categoryColumn: {
        width: '20%',
    },
    competencesColumn: {
        width: '80%',
    },
    divider: {
        borderBottomColor: '#09B4BF',
        borderBottomWidth: 1,
        marginTop: 5,
        marginBottom: 5,
    },
    leftColumn: {
        width: '16%',
    },
    rightColumn: {
        width: '80%',
        marginLeft: 12,
    },
    imagePage: {
        height: 50,
    },
    user: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: "18px",
        marginLeft: "-25px",
    },
    poste: {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: "40px",
        marginLeft: "-25px",
        color: '#808080',
        width: '50%',
    },
    calculExp: {
        textAlign: 'right',
        color: '#808080',
        marginTop: 20,
        marginBottom: 10,
    },
    sectionTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 10,
        textTransform: 'uppercase',
        color: '#09B4BF',
        paddingTop: '10px',
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    line: {
        flexGrow: 1,
        height: 2,
        backgroundColor: '#09B4BF',
        marginLeft: 10,
    },
    sectionDivider: {
        height: 1,
        backgroundColor: '#09B4BF',
        marginVertical: 10,
    },
    table: {
        width: '100%',
    },
    tableHeader: {
        backgroundColor: '#09B4BF',
        color: '#FFFFFF',
        fontWeight: 'bold',
    },
    sectionTable: {
        flexDirection: 'row',
        fontSize: 10,
    },
    tableRow: {
        width: '50%',
        textAlign: "center",
        marginTop: 2,
        marginBottom: 2,
    },
    row1: {
        width: '27%',
    },
    row2: {
        width: '25%',
    },
    row3: {
        width: '25%',
    },
    row4: {
        width: '25%',
    },
    row5: {
        width: '25%',
    },
    header: {
        width: 50,
        alignSelf: 'flex-end',
        position: 'absolute',
        top: 40,
        right: 40,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    footerTextBold: {
        color: 'white',
        fontWeight: 'bold',
        position: 'absolute',
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        fontSize: 11,
    },
    footerText: {
        color: 'white',
        position: 'absolute',
        marginTop: 25,
        marginLeft: 20,
        fontSize: 10,
    },
});

export default CvPdf;
