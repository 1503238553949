import "./Inscription.css";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBIcon,
} from "mdb-react-ui-kit";
import { http, getProfile } from "../../components/services/httpService";
import { uniqBy } from 'lodash';

export default function InscriptionClient() {

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const [loggedInManagerId, setLoggedInManagerId] = useState(null);
  const [role, setRole] = useState("CLIENT");
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await http('api/salesforce/aos', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const uniqueClients = uniqBy(data, 'accountId');
        setClients(uniqueClients);
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchLoggedInManager() {
      try {
        const response = await getProfile();
        if (response.ok) {
          const data = await response.json();
          if (data && data.id) {
            setLoggedInManagerId(data.id);
          }
        }
      } catch (error) {
        console.error("Erreur de récuperation de profile", error);
      }
    }

    fetchLoggedInManager();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    const payload = {
      firstname,
      lastname,
      email,
      role,
      entreprise,
      invitingManagerId: loggedInManagerId
    };

    http("api/v1/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then(r => {
      if (!r.ok) {
        throw new Error("Erreur de connexion");
      }

      toast.success("Inscription réussie ! Veuillez vérifier votre e-mail pour activer votre compte.");

      history.push("/client");
    }).catch(error => {
      console.error(error);
      setError("Cet email est déjà utilisé, veuillez en choisir un autre.");
    });
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    console.log("Role selected:", e.target.value);
  };

  return (
    <MDBContainer fluid className="fond-accueil inscription">
      <MDBRow className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "90VH" }}>
        <MDBCol md="6" className="form-card">
          <form onSubmit={handleSubmit}>
            <p className="h5 text-center mb-4">Inscrire un</p>
            <div className="radio-container d-flex justify-content-center">
              <input
                type="radio"
                name="role"
                className="radio-custom"
                value="CLIENT"
                checked={role === "CLIENT"}
                onChange={handleRoleChange}
              />
              <label className="text-white">
                CLIENT
              </label>
            </div>
            <div className="row">
              <div className="col-6 mb-2">
                <MDBInput
                  label="Prénom"
                  name="prenom"
                  type="text"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  required
                  contrast
                />
              </div>
              <div className="col-6">
                <MDBInput
                  label="Nom"
                  name="nom"
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  required
                  contrast
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 mb-2">
                <MDBInput
                  label="Email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  required
                  className="mb-4"
                  contrast
                />
              </div>
              <div className="col-6 mb-2">
                <select
                  name="entreprise"
                  value={entreprise}
                  onChange={(e) => setEntreprise(e.target.value)}
                  className="form-select select-input"
                  required
                >
                  <option value="">Sélectionnez une entreprise</option>
                  {clients.map((client) => (
                    <option key={client.accountId} value={client.accountId}>
                      {client.accountName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-4 gap-3">
              <MDBBtn type="submit" className="BtnCard mb-4 mt-4">Envoyer</MDBBtn>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
          </form>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};
