import {
  MDBIcon
} from "mdb-react-ui-kit";

const Star = (props) => {
  return (
    <span
      style={{
        marginRight: "3px",
        color: props.isSelected ? "#09B4BF" : props.hover ? "#09B4BF" : "#e4e5e9",
        cursor: "pointer",
      }}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClick}
    >
      <MDBIcon fas icon="window-minimize" size="xs" />
    </span>
  );
};

export default Star;
