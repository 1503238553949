import React, { useState, useEffect } from "react";
import {
    MDBIcon,
    MDBBtn,
} from "mdb-react-ui-kit";
import { getProfile } from "../services/httpService";
import useAuth from "../SSO/useAuth";

const SimpleDropdown = () => {
    const [user, setUser] = useState({ nom: "", prenom: "", role: "" });
    const [isOpen, setIsOpen] = useState(false);
    const auth = useAuth();
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (isOpen) setIsAnimating(true);
    }, [isOpen]);

    const toggleDropdown = () => {
        if (isOpen) {
            setIsAnimating(false);
            setTimeout(() => setIsOpen(false), 300); // Délai correspondant à la durée de l'animation
        } else {
            setIsOpen(true);
        }
    };

    useEffect(() => {
        getProfile()
            .then((response) => response.json())
            .then((data) => {
                setUser(data);
            })
            .catch((error) => console.error(error));
    }, []);

    const handleLogout = () => {
        auth.logout();
        console.log("Déconnexion");
    };

    const dropdownStyle = {
        listStyleType: 'none',
        padding: '0px',
        margin: '0',
        position: 'absolute',
        top: '50px',
        left: '50%',
        backgroundColor: '#fff',
        border: '1px solid #09B4BF',
        borderRadius: '10px',
        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
        width: '140px',
        transform: `translateX(-50%) ${isAnimating ? 'translateY(0)' : 'translateY(-10px)'}`,
        opacity: isAnimating ? 1 : 0,
        transition: 'opacity 300ms ease-out, transform 300ms ease-out',
        pointerEvents: isAnimating ? 'auto' : 'none',
    };

    const listItemStyle = { cursor: 'pointer', padding: '10px' };

    return (
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
            <MDBBtn color='dark' onClick={toggleDropdown} style={{ padding: '10px', margin: '10px', backgroundColor: '#09B4BF', cursor: 'pointer', borderRadius: '10px', width: '100px' }}>
                <div className="d-flex justify-content-center align-items-center gap-1">
                    {user.prenom}
                    <MDBIcon fas icon="angle-down" style={{ transition: "transform 0.2s", transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }} />
                </div>
            </MDBBtn>
            {isOpen && (
                <ul style={dropdownStyle}>
                    {user.role !== 'CLIENT' && (
                        <li style={listItemStyle} className="dropdown-item">
                            <a href="/mon-compte" style={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%', width: '100%' }}>Mon espace</a>
                        </li>
                    )}
                    {user.role === 'SUPERADMIN' && (
                        <li style={listItemStyle} className="dropdown-item">
                            <a href="/super-admin" style={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%', width: '100%' }}>Admin</a>
                        </li>
                    )}
                    <li style={listItemStyle} className="dropdown-item" onClick={handleLogout}>Déconnexion</li>
                </ul>
            )}
        </div>
    );
};

export default SimpleDropdown;
