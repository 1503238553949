import React, { useState, useEffect } from 'react';
import { http, getProfile, getAllUsers } from "../../components/services/httpService";
import "../../Pages/Accueil/Accueil.css";
import {
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBBadge,
    MDBCardBody,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
} from "mdb-react-ui-kit";
import { useDarkMode } from "../../components/assets/DarkModeContext";
import UserSearch from "../../components/assets/UserSearch";
import { useHistory } from "react-router-dom";

const ManagersList = () => {
    const [user, setUser] = useState(null);
    const [hierarchy, setHierarchy] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { isDarkMode, isTransitionDisabled } = useDarkMode();
    const noTransitionClass = isTransitionDisabled ? "no-transition" : "";
    const [fillActive, setFillActive] = useState("");
    const [searchText, setSearchText] = useState("");
    const [idMapping, setIdMapping] = useState(new Map());
    const history = useHistory();
    const [globalSearchResults, setGlobalSearchResults] = useState([]);

    const fetchUsers = async () => {
        try {
            const usersResponse = await getAllUsers(); 
            const usersData = await usersResponse.json();
            return usersData; 
        } catch (error) {
            console.error("Error fetching users:", error);
            return [];
        }
    };


    useEffect(() => {
        const fetchUserDataAndHierarchy = async () => {
            try {
                const users = await fetchUsers();
                const userProfileResponse = await getProfile();
                const userProfile = await userProfileResponse.json();
                setUser(userProfile);

                const hierarchyResponse = await http('api/salesforce/hierarchy');
                const hierarchyData = await hierarchyResponse.json();
                setHierarchy(hierarchyData);

                const connectedManagerTab = hierarchyData.managers.find(manager =>
                    manager.firstName.toLowerCase() === userProfile.prenom.toLowerCase() &&
                    manager.lastName.toLowerCase() === userProfile.nom.toLowerCase()
                );

                let reorderedManagers = [...hierarchyData.managers];
                const managerIndex = reorderedManagers.findIndex(manager =>
                    manager.firstName.toLowerCase() === userProfile.prenom.toLowerCase() &&
                    manager.lastName.toLowerCase() === userProfile.nom.toLowerCase()
                );

                if (managerIndex > -1) {
                    const [connectedManager] = reorderedManagers.splice(managerIndex, 1);
                    reorderedManagers = [connectedManager, ...reorderedManagers];
                }

                setHierarchy({ ...hierarchyData, managers: reorderedManagers });

                if (connectedManagerTab) {
                    setFillActive(`tab${connectedManagerTab.id}`);
                } else if (hierarchyData.managers.length > 0) {
                    setFillActive(`tab${hierarchyData.managers[0].id}`);
                }

                const newIdMapping = new Map();
                users.forEach(user => {
                    const key = `${user.prenom.toLowerCase()} ${user.nom.toLowerCase()}`;
                    newIdMapping.set(key, user.id);
                });

                setIdMapping(newIdMapping);

                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchUserDataAndHierarchy();
    }, []);

    if (loading) return <div className='text-center'><img className="loader-image" src="/spinner-alcyone.png" alt="Loading..." />
    </div>;
    if (error) return <div className='text-center'>Error loading data</div>;

    const handleFillClick = (tabId) => {
        setFillActive(tabId);
    };

    const handleSearch = (searchText) => {
        const lowercasedSearchText = searchText.toLowerCase();
        let found = false;
        let globalResults = [];

        for (const manager of hierarchy.managers) {
            const filteredConsultants = manager.consultants.filter(consultant =>
                consultant.firstName.toLowerCase().includes(lowercasedSearchText) ||
                consultant.lastName.toLowerCase().includes(lowercasedSearchText)
            );

            if (filteredConsultants.length > 0) {
                setFillActive(`tab${manager.id}`);
                found = true;
                globalResults = [...globalResults, ...filteredConsultants];
            }
        }

        if (!found) {
            setFillActive("");
        }

        setGlobalSearchResults(globalResults);
        setSearchText(searchText);
    };

    const handleCardClick = (userId) => {
        if (userId) {
            history.push(`/user-cvs/${userId}`);
        }
    };

    return (
        <div>
            <div className="w-50 my-3 m-auto">
                <UserSearch
                    onSearch={(searchText) => handleSearch(searchText)}
                    disableEnter={true}
                    placeholder="Chercher un collaborateur par prénom ou nom"
                />
            </div>
            <MDBTabs fill className={`my-3 ${noTransitionClass}`}>
                {hierarchy.managers.map(manager => (
                    <MDBTabsItem key={manager.id}>
                        <MDBTabsLink
                            onClick={() => handleFillClick(`tab${manager.id}`)}
                            active={fillActive === `tab${manager.id}`}
                            className={`${isDarkMode ? "text-white bg-dark-mode" : ""
                                } ${noTransitionClass} no-uppercase`}
                        >
                            {manager.firstName} {manager.lastName.toUpperCase()}
                        </MDBTabsLink>
                    </MDBTabsItem >
                ))
                }
            </MDBTabs>
            <MDBTabsContent>
                {hierarchy.managers.map(manager => (

                    <MDBTabsPane
                        key={manager.id}
                        show={fillActive === `tab${manager.id}`}
                    >
                        <MDBRow className="animation-fade-in justify-content-around">
                            {manager.consultants
                                .filter(consultant =>
                                    consultant.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
                                    consultant.lastName.toLowerCase().includes(searchText.toLowerCase())
                                )
                                .map(consultant => {
                                    // Obtener el userId del mapa de mapeo
                                    const userId = idMapping.get(`${consultant.firstName.toLowerCase()} ${consultant.lastName.toLowerCase()}`) || '';

                                    return (
                                        <div key={consultant.id} className="col-md-4">
                                            <div onClick={() => handleCardClick(userId)} style={{ cursor: userId ? 'pointer' : 'default' }}>
                                                <MDBCard className="mb-4" style={{
                                                    padding: "10px", fontSize: "14px", border: "1px solid #09B4BF",
                                                    borderRadius: "10px"
                                                }}>
                                                    <MDBCardBody>
                                                        <div className="d-flex align-items-center py-4">
                                                            <img
                                                                src="./spinner-alcyone.png"
                                                                alt=""
                                                                style={{ width: "40px", height: "40px" }}
                                                                className="rounded-circle"
                                                            />
                                                            <div className="flex-grow-1 ms-3">
                                                                <p
                                                                    className="text-muted mb-1"
                                                                    style={{ fontSize: "16px" }}
                                                                >
                                                                    {consultant.firstName} {consultant.lastName}
                                                                </p>
                                                                <h2 className="mb-0" style={{ fontSize: "14px" }}>
                                                                    CONSULTANT
                                                                    <p style={{ fontSize: "12px" }}>{consultant.email}</p>
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </div>
                                        </div>
                                    );
                                })
                            }

                        </MDBRow>

                    </MDBTabsPane>
                ))
                }
            </MDBTabsContent>
            <section>
                {globalSearchResults.length > 0 && (
                    <div>
                        <div className={isDarkMode ? "text-white" : "text-muted"}>
                            <h3 className="py-3 text-center">Résultat global des consultants</h3>
                        </div>
                        <MDBRow className="animation-fade-in justify-content-around">
                            {globalSearchResults.map(consultant => {
                                const userId = idMapping.get(`${consultant.firstName.toLowerCase()} ${consultant.lastName.toLowerCase()}`) || '';
                                return (
                                    <div key={consultant.id} className="col-md-4">
                                        <div onClick={() => handleCardClick(userId)} style={{ cursor: userId ? 'pointer' : 'default' }}>
                                            <MDBCard className="mb-4" style={{
                                                padding: "10px", fontSize: "14px", border: "1px solid #09B4BF",
                                                borderRadius: "10px"
                                            }}>
                                                <MDBCardBody>
                                                    <div className="d-flex align-items-center py-4">
                                                        <img
                                                            src="./spinner-alcyone.png"
                                                            alt=""
                                                            style={{ width: "40px", height: "40px" }}
                                                            className="rounded-circle"
                                                        />
                                                        <div className="flex-grow-1 ms-3">
                                                            <p
                                                                className="text-muted mb-1"
                                                                style={{ fontSize: "16px" }}
                                                            >
                                                                {consultant.firstName} {consultant.lastName}
                                                            </p>
                                                            <h2 className="mb-0" style={{ fontSize: "14px", color: "#09B4BF" }}>
                                                                CONSULTANT
                                                                <p style={{ fontSize: "12px" }}>{consultant.email}</p>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </div>
                                    </div>
                                );
                            })}
                        </MDBRow>
                    </div>
                )}
            </section>
        </div >
    );

};

export default ManagersList;
