import React, { useEffect, useRef } from 'react';
import { parseISO } from "date-fns";

function ExperiencesPreview({ experiences= [], isAnonymous, translations, formatDate, calculateDifference, moveAction }) {
  return (
    <>
      {experiences.map((experience, index) => (
        <React.Fragment key={`experience-${experience.id}-${index}`}>
          <div className="d-flex no-page-break">
            <div style={{ width: '15%', marginRight: '10px' }}>
              <p className="fw-bold mb-0 text-black" style={{ fontSize: "12px" }}>
                {isAnonymous && experience.societe ? translations.labels.company : experience.societe}
              </p>
              <p className="fw-bold mb-0 text-black" style={{ fontSize: "12px" }}>{translations.sectorActivite[experience.secteur]}</p>
              <p className="mb-0 text-black" style={{ color: "#09B4BF", fontSize: "11px" }}> {experience.lieu}</p>
              <p className="text-black" style={{ fontSize: "11px" }}>
                {experience.dateDebut && (experience.dateFin || experience.isCurrentJob) ? (
                  <div>
                    <span>
                      {experience.dateDebut ? formatDate(experience.dateDebut) : ''}
                      {' - '}
                      {experience.isCurrentJob ? translations.labels.now : (experience.dateFin ? formatDate(experience.dateFin) : '')}
                    </span>
                    <div>
                      {experience.isCurrentJob
                        ? (() => {
                          const diff = calculateDifference(parseISO(experience.dateDebut), new Date());
                          return diff ? `(${diff})` : '';
                        })()
                        : (() => {
                          const diff = calculateDifference(parseISO(experience.dateDebut), parseISO(experience.dateFin));
                          return diff ? `(${diff})` : '';
                        })()
                      }
                    </div>
                  </div>
                ) : experience.dateDebut ? (
                  <span>{formatDate(experience.dateDebut)}</span>
                ) : experience.dateFin ? (
                  <span>{formatDate(experience.dateFin)}</span>
                ) : null}
              </p>
            </div>
            <div className="w-75" style={{ fontSize: "12px", textAlign: "justify" }}>
              <span className="fw-bold" style={{ color: "#09B4BF" }}>
                {experience.poste}
              </span>
              {experience.client && (
                <span className="fw-bold text-black">
                  {' - '}{translations.labels.mission}{' '}
                  {isAnonymous && experience.client ? 'Client' : experience.client}
                </span>
              )}
              <p className="fw-bold mb-0">{translations.contractTypes[experience.typeContrat]}</p>
              <div>
                {experience.contexte && experience.contexte.trim() ? (
                  <>
                    <span style={{ color: "#09B4BF" }}>{translations.labels.context}</span>
                    <p className="mb-0 text-black">{experience.contexte}</p>
                  </>
                ) : null}
              </div>
              <div>
                {experience.realisation && experience.realisation.trim() ? (
                  <>
                    <span style={{ color: "#09B4BF" }}>{translations.labels.realisation}</span>
                    <p className="mb-0 text-black">{experience.realisation}</p>
                  </>
                ) : null}
              </div>
              {experience &&
                experience.actions &&
                experience.actions.some((action) => action.trim()) && (
                  <>
                    <p className="mb-0" style={{ color: "#09B4BF" }}>
                      {translations.labels.responsibilities}
                    </p>
                    <ul className="mb-0 text-black">
                      {experience.actions.map((action, actionIndex) =>
                        action.trim() ? (
                          <li key={actionIndex}>{action}</li>
                        ) : null
                      )}
                    </ul>
                  </>
                )}
              {experience.envTechnique && Array.isArray(experience.envTechnique) && experience.envTechnique.length > 0 && (
                <div className="mt-0">
                  <span style={{ color: "#09B4BF" }}>
                    {translations.labels.techEnvironment}
                  </span>
                  <p className="text-black">
                    {' ' + experience.envTechnique.join(", ")}</p>
                </div>
              )}
            </div>
          </div>
          {index < experiences.length - 1 && (
            (experience.societe || experience.contexte) &&
            (experiences[index + 1].societe || experiences[index + 1].contexte) && (
              <hr style={{ color: "#09B4BF" }} />
            )
          )}
        </React.Fragment>
      ))}
    </>
  );
}

export default React.memo(ExperiencesPreview);
