const SearchResult = ({ result, onSelect, isActive, onMouseEnter }) => {
    return (
      <div onClick={() => onSelect(result)} onMouseEnter={onMouseEnter} style={{ maxHeight: '300px', overflowY: 'auto'}}>
        <p className="px-3" style={{ maxHeight: '300px', overflowY: 'auto', backgroundColor: isActive ? '#09B4BF' : 'transparent'}}>{result.competenceName}</p> 
      </div>
    );
};

export default SearchResult;

  