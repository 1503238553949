import "./Inscription.css";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBIcon,
} from "mdb-react-ui-kit";
import { http, getProfile } from "../../components/services/httpService";

export default function InscriptionCandidat() {

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const [loggedInManagerId, setLoggedInManagerId] = useState(null);
  const [role, setRole] = useState("CANDIDAT");

  useEffect(() => {
    async function fetchLoggedInManager() {
      try {
        const response = await getProfile();
        if (response.ok) {
          const data = await response.json();
          if (data && data.id) {
            setLoggedInManagerId(data.id);
          }
        }
      } catch (error) {
        console.error("Error al obtener el perfil del manager", error);
      }
    }

    fetchLoggedInManager();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    const payload = {
      firstname,
      lastname,
      email,
      role,
      invitingManagerId: loggedInManagerId
    };

    http("api/v1/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then(r => {
      if (!r.ok) {
        throw new Error("Erreur de connexion");
      }

      toast.success("Inscription réussie ! Veuillez vérifier votre e-mail pour activer votre compte.");

      history.push("/candidats");
    }).catch(error => {
      console.error(error);
      setError("Cet email est déjà utilisé, veuillez en choisir un autre.");
    });
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    console.log("Role selected:", e.target.value);
  };

  return (
    <MDBContainer fluid className="fond-accueil inscription">
      <MDBRow className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "90VH" }}>
        <MDBCol md="6" className="form-card">
          <form onSubmit={handleSubmit}>
            <p className="h5 text-center mb-4">Inscrire un candidat</p>
            <div className="row">
              <div className="col-6 mb-2">
                <MDBInput
                  label="Prénom"
                  name="prenom"
                  type="text"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  required
                  contrast
                />
              </div>
              <div className="col-6">
                <MDBInput
                  label="Nom"
                  name="nom"
                  icon="user"
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  required
                  contrast
                />
              </div>
            </div>
            <div>
              <MDBInput
                label="Email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}\??$"
                required
                className="mb-4"
                contrast
              />
            </div>
            <div className="d-flex justify-content-center gap-4">
              <div className="radio-container">
                <input
                  type="radio"
                  name="role"
                  className="radio-custom"
                  value="CANDIDAT"
                  checked={role === "CANDIDAT"}
                  onChange={handleRoleChange}
                />
                <label className="text-white">
                  Candidat
                </label>
              </div>
              <div className="radio-container">
                <input
                  type="radio"
                  name="role"
                  className="radio-custom"
                  value="CONSULTANT"
                  checked={role === "CONSULTANT"}
                  onChange={handleRoleChange}
                />
                <label className="text-white">
                  Consultant
                </label>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-4 gap-3">
              <MDBBtn type="submit" className="BtnCard mb-4 mt-4">Envoyer</MDBBtn>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
          </form>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};
