import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import UserSearch from "../../components/assets/UserSearch";
import Parallax from '../../components/assets/Parallax';
import AddTestimony from '../../components/assets/AddTestimony';
import CompetencesTable from '../../components/assets/CompetencesTable';
import DisplayTemoignages from '../../components/assets/DisplayTemoignages';
import { differenceInDays } from 'date-fns';
import { toast } from 'react-toastify';
import {
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBCardHeader,
    MDBCol,
    MDBCardBody,
    MDBCardFooter,
    MDBIcon,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBBtn,
    MDBBadge
} from 'mdb-react-ui-kit';
import { http, uploadImage, getUploadedFiles, deleteImage, getProfile } from '../../components/services/httpService';
import { useDarkMode } from '../../components/assets/DarkModeContext';

function SuperAdmin() {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [acceptedPercentage, setAcceptedPercentage] = useState(0);
    const [renewPercentage, setRenewPercentage] = useState(0);
    const [activeTab, setActiveTab] = useState('gestion Utilisateurs');
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const { isDarkMode } = useDarkMode();
    const [selectedRoles, setSelectedRoles] = useState({});
    const [isUpdating, setIsUpdating] = useState(false);
    const searchTermLower = search.toLowerCase().split(/\s+/);
    const history = useHistory();
    const [selectedFilterRole, setSelectedFilterRole] = useState('');
    const [isRoleSelectVisible, setIsRoleSelectVisible] = useState(false);

    // const filteredUsers = users.filter(user => {
    //     const userFullName = `${user.prenom} ${user.nom}`.toLowerCase();
    //     const matchesName = searchTermLower.every(word => userFullName.startsWith(word));
    //     const matchesRole = selectedFilterRole ? user.role === selectedFilterRole : true;
    //     return matchesName && matchesRole;
    // });

    const filteredUsers = users.filter(user => {
        const userFullName = `${user.prenom} ${user.nom}`.toLowerCase();
        const matchesName = searchTermLower.every(word => userFullName.includes(word));
        const matchesRole = selectedFilterRole ? user.role === selectedFilterRole : true;
        return matchesName && matchesRole;
    });

    const handleTabChange = (newTab) => {
        setActiveTab(newTab);
    };

    const [currentUserID, setCurrentUserID] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const profile = await getProfile();
                const data = await profile.json();
                setCurrentUserID(data.id);
            } catch (error) {
                console.error("Error fetching profile:", error);
            }
        };

        fetchProfile();
    }, []);


    useEffect(() => {
        http("user/all")
            .then(response => response.json())
            .then(data => {

                const validUsers = data.filter(item => typeof item === 'object' && item.id);
                setUsers(validUsers);
                let totalUsers = data.length;
                let acceptedCount = 0;
                let renewCount = 0;

                data.forEach(user => {
                    if (user.dateCreation) {
                        user.dateCreation = new Date(user.dateCreation).toLocaleDateString();
                    }
                    if (user.dateExpiration) {
                        let expirationDate = new Date(user.dateExpiration);
                        user.dateExpiration = expirationDate.toLocaleDateString();

                        let diffInDays = differenceInDays(expirationDate, new Date());

                        if (diffInDays <= 30) {
                            user.renewStatus = true;
                        } else {
                            user.renewStatus = false;
                        }
                    }

                    if (user.estAccepte === 'YES' && !user.renewStatus) {
                        acceptedCount++;
                    }
                    if (user.estAccepte !== 'YES' || user.renewStatus) {
                        renewCount++;
                    }
                });

                setUsers(data);
                setAcceptedPercentage((acceptedCount / totalUsers) * 100);
                setRenewPercentage((renewCount / totalUsers) * 100);
            })
            .catch(error => console.error(error));
    }, []);

    const [selectedImage, setSelectedImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files ? event.target.files[0] : null;
        setSelectedImage(file);
        setPreviewImage(URL.createObjectURL(file));
    };

    const handleImageUpload = async () => {
        if (selectedImage) {
            return uploadImage(selectedImage)
                .then(response => {
                    toast.success("Image uploaded successfully");
                })
                .catch(error => {
                    console.error('An error occurred while uploading the image', error);
                });
        }
    };

    const [files, setFiles] = useState([]);

    useEffect(() => {
        getUploadedFiles()
            .then(response => response.json())
            .then(data => {
                setFiles(data);
            })
            .catch(error => console.error("Erreur lors de la récupération des fichiers :", error));
    }, []);

    const handleDelete = (filename) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
            deleteImage(filename)
                .then(() => {
                    setFiles(files.filter(file => file !== filename));
                })
                .catch((error) => {
                    console.error('Failed to delete image', error);
                });
        }
    };

    const handleChangeRole = (userId, newRole) => {
        setIsUpdating(true);
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ role: newRole })
        };

        http(`user/change-role/${userId}`, requestOptions)
            .then(response => {
                toast.success("Le changement de rôle a été effectué avec succès!");
                setUsers(prevUsers => {
                    return prevUsers.map(user => {
                        if (user.id === userId) {
                            return { ...user, role: newRole };
                        } else {
                            return user;
                        }
                    });
                });

                setIsUpdating(false);
            })
            .catch(error => {
                console.error('An error occurred while changing the role', error);
                setIsUpdating(false);
            });
    };

    const handleDeleteUser = (userId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur?")) {
            const requestOptions = {
                method: 'DELETE'
            };

            http(`user/delete/${userId}`, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete the user');
                    }
                    setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
                    toast.success('User deleted successfully!');
                })
                .catch(error => {
                    console.error('An error occurred while deleting the user', error);
                });
        }
    };

    const dropdownStyle = {
        position: 'absolute',
        zIndex: 1,
        bottom: '15%',
        left: '80px'
    };

    return (
        <MDBContainer fluid className={isDarkMode ? 'bg-dark-mode' : 'bg-light'}>
            <MDBContainer className="animation-fade-in">
                <div
                    className="p-5 bg-image"
                    style={{
                        height: "300px",
                        backgroundImage: 'url("./bg/bg-super-admin.jpg")',
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundAttachment: "fixed",
                    }}
                ></div>
            </MDBContainer>
            <div className="text-center justify-content-center align-items-center my-3">
                <h1 className={`text-center p-2 ${isDarkMode ? 'text-white' : ''}`}>Espace Manager</h1>
            </div>
            <div>
                <ul className="nav nav-tabs mb-2 justify-content-center" id="issuesTabs" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === 'gestion Utilisateurs' ? 'active' : ''} ${isDarkMode ? 'text-white bg-dark-mode' : ''}`}
                            id="tab-1"
                            onClick={() => handleTabChange('gestion Utilisateurs')}
                        >
                            Gestion des utilisateurs
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === 'Gestion logos' ? 'active' : ''} ${isDarkMode ? 'text-white bg-dark-mode' : ''}`}
                            id="tab-2"
                            onClick={() => handleTabChange('Gestion logos')}
                        >
                            Gestion des logos

                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === 'Gestion temoignages' ? 'active' : ''} ${isDarkMode ? 'text-white bg-dark-mode' : ''}`}
                            id="tab-3"
                            onClick={() => handleTabChange('Gestion temoignages')}
                        >
                            Gestion des témoignages

                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === 'Gestion compétences' ? 'active' : ''} ${isDarkMode ? 'text-white bg-dark-mode' : ''}`}
                            id="tab-3"
                            onClick={() => handleTabChange('Gestion compétences')}
                        >
                            Gestion des compétences
                        </button>
                    </li>
                </ul>
            </div>
            {activeTab === 'Gestion logos' && (
                <MDBContainer>
                    <div className='text-center'>
                        <div className=''>
                            <input type="file" accept="image/*" id="file" onChange={handleImageChange} style={{ display: 'none' }} />
                            <label htmlFor="file" className="BtnCard">
                                Ajouter un logo d'un client
                            </label>
                        </div>
                        <div>
                            {previewImage && <img src={previewImage} alt="Image preview" style={{ width: "100px" }} />}
                        </div>
                        <div className='my-2'>
                            <MDBBtn className="btn-secondary" onClick={handleImageUpload}>Confirmer</MDBBtn>
                        </div>
                    </div>
                    <MDBRow className='row-cols-1 row-cols-md-3 g-4 mt-3 pb-5'>
                        {files.map((file, idx) => (
                            <MDBCol key={idx}>
                                <MDBCard className='h-100 align-items-center py-5'>
                                    <img
                                        className="w-50 img-fluid"
                                        src={`${baseUrl}clients/upload/${file}`}
                                        alt={file}
                                    />
                                    <MDBBtn className="btn-danger m-2" onClick={() => handleDelete(file)}>x</MDBBtn>
                                </MDBCard>
                            </MDBCol>
                        ))}
                    </MDBRow>
                </MDBContainer>
            )}
            {activeTab === 'gestion Utilisateurs' && (
                <MDBRow className='justify-content-center pb-5'>
                    <div className="w-50 pb-5">
                        <UserSearch onSearch={setSearch} disableEnter={true} placeholder="Chercher par nom" />
                    </div>
                    <MDBCol md='10' className='mt-2'>
                        <section>
                            <MDBCard>
                                <MDBCardHeader className='py-3'>
                                    <MDBRow>
                                        <MDBCol size='6'>
                                            <p className='text-uppercase small my-2'>
                                                <strong>Gestion des données</strong>
                                            </p>
                                            <h5 className='mb-0'>
                                                <strong>Valide</strong>
                                                <small className='text-success ms-2'>
                                                    <MDBIcon fas size='sm' icon='arrow-up' className='pe-1' />
                                                    {acceptedPercentage.toFixed(2)} %
                                                </small>
                                                <strong className='ms-2'>Renouvellement</strong>
                                                <small className='text-warning ms-2'>
                                                    <MDBIcon fas size='sm' icon='arrows-alt-v' className='pe-1' />
                                                    {renewPercentage.toFixed(2)} %
                                                </small>
                                            </h5>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardHeader>

                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol md='12' className='mb-4' style={{ overflowX: 'auto' }}>
                                            <MDBTable hover>
                                                <MDBTableHead>
                                                    <tr>
                                                        <th>Nom</th>
                                                        <th style={{ position: 'relative' }}>
                                                            Rôle
                                                            <MDBIcon fas icon="filter" onClick={() => setIsRoleSelectVisible(!isRoleSelectVisible)} title="Filtrer" />
                                                            {isRoleSelectVisible && (
                                                                <select
                                                                    style={dropdownStyle}
                                                                    className='py-2'
                                                                    value={selectedFilterRole}
                                                                    onChange={(e) => {
                                                                        setSelectedFilterRole(e.target.value);
                                                                        setIsRoleSelectVisible(false);
                                                                    }}
                                                                    onBlur={() => setIsRoleSelectVisible(false)}
                                                                >
                                                                    <option className='text-white' value="">Tous les rôles</option>
                                                                    <option className='text-white' value="CANDIDAT">Candidat</option>
                                                                    <option className='text-white' value="CONSULTANT">Consultant</option>
                                                                    <option className='text-white' value="MANAGER">Manager</option>
                                                                    <option className='text-white' value="SUPERADMIN">Super Admin</option>
                                                                    <option className='text-white' value="CLIENT">Client</option>
                                                                </select>
                                                            )}
                                                        </th>
                                                        <th>Email</th>
                                                        <th>Date de création</th>
                                                        <th>Date du dernier consentement</th>
                                                        <th>Date de fin</th>
                                                        <th>Consentement valide</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {filteredUsers.map(user => (
                                                        <tr key={user.id}>
                                                            <td>{`${user.prenom || ''} ${user.nom ? user.nom.toUpperCase() : ''}`}</td>
                                                            <td>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}> {/* Contenedor con Flexbox */}
                                                                    <select
                                                                        className='py-2'
                                                                        value={selectedRoles[user.id] || user.role}
                                                                        onChange={(e) => setSelectedRoles(prevRoles => ({
                                                                            ...prevRoles,
                                                                            [user.id]: e.target.value,
                                                                        }))}
                                                                    >
                                                                        <option className='text-white' value="CANDIDAT">Candidat</option>
                                                                        <option className='text-white' value="CONSULTANT">Consultant</option>
                                                                        <option className='text-white' value="MANAGER">Manager</option>
                                                                        <option className='text-white' value="SUPERADMIN">Super Admin</option>
                                                                        <option className='text-white' value="CLIENT">Client</option>
                                                                    </select>
                                                                    <MDBIcon fas icon="check-square" title="Confirmer la sélection" size='lg' className='text-success ms-1' onClick={() => handleChangeRole(user.id, selectedRoles[user.id] || user.role)} disabled={isUpdating} />
                                                                </div>
                                                            </td>
                                                            <td>{user.email}</td>
                                                            <td>{user.dateCreation}</td>
                                                            <td>{user.dateCreation}</td>
                                                            <td>{user.dateExpiration}</td>
                                                            <td>
                                                                {user.estAccepte === 'YES' && !user.renewStatus ?
                                                                    <MDBBadge color='success'>Oui</MDBBadge> :
                                                                    <MDBBadge color='warning'>
                                                                        Renouveler
                                                                    </MDBBadge>
                                                                }
                                                            </td>

                                                            <td><MDBIcon fas icon="trash-alt" className="pt-2 text-info" onClick={() => handleDeleteUser(user.id)} /></td>
                                                        </tr>
                                                    ))}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </section>
                    </MDBCol>
                </MDBRow>
            )}
            {activeTab === 'Gestion temoignages' && (
                <MDBContainer className='pb-5'>
                    <div className={`text-center p-2 ${isDarkMode ? 'text-white' : ''}`}>
                        Ajouter un témoignage
                    </div>
                    <AddTestimony />
                    <div className={`text-center p-2 ${isDarkMode ? 'text-white' : ''}`}>
                        Liste des témoignages
                    </div>
                    <DisplayTemoignages />
                </MDBContainer>
            )}
            {activeTab === 'Gestion compétences' && (
                <MDBContainer className='pb-5'>
                    <CompetencesTable />
                </MDBContainer>
            )}
        </MDBContainer>
    );
}

export default SuperAdmin;
