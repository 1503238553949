import { useState, useEffect } from 'react';
import {
    MDBRow,
    MDBCard,
    MDBBtn,
    MDBCol
} from 'mdb-react-ui-kit';

import { http } from '../../components/services/httpService';

function DisplayTemoignages() {
    const [temoignages, setTemoignages] = useState([]);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        async function fetchData() {
            const response = await http(`temoignages/all`);
            const data = await response.json();
            setTemoignages(data);
        }

        fetchData();
    }, []);

    const deleteTemoignage = async (id) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
            const response = await http(`temoignages/delete/${id}`, {
                method: 'DELETE'
            });

            if (response.ok) {
                setTemoignages(prev => prev.filter(temoignage => temoignage.id !== id));
            } else {
                console.error('Erreur lors de la suppression:', await response.text());
            }
        }
    };

    return (

        <MDBRow className='row-cols-1 row-cols-md-3 g-4 mt-3 text-center'>
            {Array.isArray(temoignages) && temoignages.map(temoignage => (
                <MDBCol key={temoignage.id}>
                    <MDBCard className='h-100 align-items-center p-5'>
                        <img
                            className="w-25 img-fluid"
                            src={
                                temoignage.photopath
                                    ? `${baseUrl}temoignages/image/${temoignage.photopath.split('\\').pop()}`
                                    : './spinner-alcyone.png' 
                            }
                            alt="Temoignage"
                        />
                        <h3>{temoignage.name} {temoignage.lastname}</h3>
                        <p>{temoignage.message}</p>
                        <MDBBtn className="btn-danger m-2" onClick={() => deleteTemoignage(temoignage.id)}>x</MDBBtn>
                    </MDBCard>
                </MDBCol>
            ))}
        </MDBRow>


    );

}

export default DisplayTemoignages;
