import React from "react";
import { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import UserSearch from "../../components/assets/UserSearch";
import UserSearchCv from "../../components/assets/UserSearchCv";
import PDFViewer from "../../components/assets/PDFViewer";
import CustomPDFDisplay from "../../components/assets/CustomPDFDisplay";
import { useCurrentUser } from "../../components/hooks/useCurrentUser";
import useDebounce from "../../components/hooks/useDebounce";
import {
  MDBContainer,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBadge,
  MDBBtn,
  MDBIcon,
} from "mdb-react-ui-kit";
import {
  getAllUsersWithCVs,
  getAllUsers,
  downloadUserPdf,
} from "../../components/services/httpService";
import { useDarkMode } from "../../components/assets/DarkModeContext";

export default function Candidats() {
  const [users, setUsers] = useState([]);
  const [usersWithCvs, setUsersWithCvs] = useState([]);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("cvGeneres");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [suggestions, setSuggestions] = useState([]);
  const debouncedSearchTerm = useDebounce(search, 300);
  const [pdfFile, setPdfFile] = useState(null);
  const currentUser = useCurrentUser();
  const [filteredUser, setFilteredUser] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const { isDarkMode, toggleDarkMode, isTransitionDisabled } = useDarkMode();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const noTransitionClass = isTransitionDisabled ? "no-transition" : "";
  const candidates = useMemo(() => {
    return usersWithCvs.filter(
      (user) => user.role === "CANDIDAT" || user.role === "MANAGER" || user.role === "CONSULTANT"
    );
  }, [usersWithCvs]);

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const [_selectedUser, setSelectedUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const filtered = candidates.filter((user) => {
      const userFullName = `${user.prenom} ${user.nom}`.toLowerCase();
      const searchWords = search.toLowerCase().split(/\s+/);
      return searchWords.every((word) => userFullName.includes(word));
    });

    setFilteredUsers(filtered);
  }, [candidates, search]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const searchWords = debouncedSearchTerm.toLowerCase().split(/\s+/);

      const filteredSuggestions = allUsers.filter((user) => {
        const userFullName =
          user.prenom?.toLowerCase() + " " + user.nom?.toLowerCase();
        return searchWords.every((word) => userFullName.includes(word));
      });

      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearchTerm, allUsers]);

  const candidatesPdf = users.filter(
    (user) => user.role === "CANDIDAT" || user.role === "MANAGER" || user.role === "CONSULTANT"
  );

  const filteredUsersSecondTable = candidatesPdf.filter((user) => {
    const userFullName = `${user.prenom} ${user.nom}`.toLowerCase();
    const searchWords = search.toLowerCase().split(/\s+/);
    return searchWords.every((word) => userFullName.includes(word));
  });

  const getLatestModificationDate = (user) => {
    if (!user.cvs || user.cvs.length === 0) return new Date(0); // retorna una fecha antigua si no hay CVs

    return new Date(
      Math.max(...user.cvs.map((cv) => new Date(cv.date_modification)))
    );
  };

  const sortedFilteredUsers = [...filteredUsers].sort(
    (a, b) => getLatestModificationDate(b) - getLatestModificationDate(a)
  );

  // Liste de toutes les CVs existantes de la bdd
  useEffect(() => {
    getAllUsersWithCVs()
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUsersWithCvs(data);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des utilisateurs et de leurs CVs:",
          error
        );
      });
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    // Ajoute l'écouteur d'événements lorsque le composant est monté
    document.addEventListener("keydown", handleKeyDown);

    // Supprime l'écouteur lorsque le composant est démonté
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //Sauvegarder le cv en PDF récuperé de la liste UserSearch dans cv d'origine
  useEffect(() => {
    const fetchUsersWithPdf = async () => {
      const responseUsers = await getAllUsers();
      const dataUsers = await responseUsers.json();

      const usersWithPdf = await Promise.all(
        dataUsers.map(async (user) => {
          if (!user.docUrl) {
            return user;
          }

          const responsePdf = await downloadUserPdf(user.id);

          if (!responsePdf.ok) {
            return user;
          }

          const pdfBlob = await responsePdf.blob();
          const pdfUrl = URL.createObjectURL(pdfBlob);
          return { ...user, pdfUrl };
        })
      );

      setUsers(usersWithPdf);
      setUsersWithCvs(usersWithPdf);
    };

    fetchUsersWithPdf();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);

    if (tab === "cvOrigine") {
      setPopupVisible(true);
    }
  };

  const [isPopupVisible, setPopupVisible] = useState(true);

  const handleUserSearch = async (searchQuery) => {
    if (searchQuery === "") {
      setSuggestions([]);
      setFilteredUser({ prenom: "", nom: "", role: "" });
      return;
    }

    if (allUsers.length === 0) {
      const response = await getAllUsers();
      const data = await response.json();
      setAllUsers(data);
    }

    const matchingUsers = allUsers.filter(
      (u) =>
        u.prenom?.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
        u.nom?.toLowerCase().startsWith(searchQuery.toLowerCase())
    );

    setSuggestions(matchingUsers);

    if (matchingUsers.length === 1) {
      setFilteredUser(matchingUsers[0]);
    } else {
      setFilteredUser({ prenom: "", nom: "", role: "" });
    }
  };

  const handleOnSearch = (searchQuery) => {
    setSearch(searchQuery);
    handleUserSearch(searchQuery);
  };

  const handleSharePDF = (docUrl) => {
    if (!docUrl) return;
    const link = `${baseUrl}${docUrl}`;

    const body = encodeURIComponent(
      `Bonjour,\n\nVous pouvez voir le PDF via le lien suivant:\n${link}\n\nCordialement,\nVotre Nom`
    );

    window.location.href = `mailto:?&body=${body}`;
  };

  const handleLinkPDF = (docUrl) => {
    if (!docUrl) return;
    const link = `${baseUrl}${docUrl}`;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Lien PDF copié dans le presse-papiers !");
      })
      .catch((err) => {
        console.error("Erreur lors de la copie du lien : ", err);
        alert("Erreur lors de la copie du lien. Veuillez réessayer.");
      });
  };

  function handleOriginPdfPreview(docUrl) {
    if (docUrl) {
      const filename = encodeURIComponent(docUrl);
      const fullPdfUrl = `${baseUrl}user/origin-pdf-uploads/${filename}`;

      const token = localStorage.getItem('token');

      fetch(fullPdfUrl, {
        method: 'GET',
        headers: new Headers({
          'Authorization': `Bearer ${token}`
        })
      })
        .then(response => {
          if (response.ok) {
            return response.blob();
          } else {
            throw new Error(`Error: ${response.statusText}`);
          }
        })
        .then(blob => {
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else {
      alert("Aucun CV n'a été déposé.");
    }
  }

  const updateActiveSuggestionIndex = (newIndex) => {
    setActiveSuggestionIndex(newIndex);
  };

  const handleSuggestionClick = (user) => {
    setFilteredUser(user);
    setSuggestions([]);
  };

  const handleSuggestionClickCv = (user) => {
    setFilteredUser(user);
    setSelectedUser(user);
    setSuggestions([]);
    history.push(`/user-cvs/${user.id}`);
  };

  const handleSelection = () => {
    if (
      activeSuggestionIndex >= 0 &&
      activeSuggestionIndex < suggestions.length
    ) {
      handleSuggestionClick(suggestions[activeSuggestionIndex]);
    }
  };

  function formatPrenom(prenom) {
    if (!prenom) return "";

    return prenom
      .split(/[-\s]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(prenom.includes("-") ? "-" : " ");
  }

  const [totalCVs, setTotalCVs] = useState(0);

  useEffect(() => {
    const total = sortedFilteredUsers.reduce((acc, user) => acc + user.cvs.length, 0);
    setTotalCVs(total);
  }, [sortedFilteredUsers]);

  return (
    <MDBContainer fluid className={isDarkMode ? "bg-dark-mode" : "bg-light"}>
      <MDBContainer>
        <section className="animation-fade-in">
          <div
            className="bg-image"
            style={{
              height: "300px",
              backgroundImage: 'url("./bg/bg-mes-cv.png")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
            }}
          ></div>
        </section>
        <div className="d-flex text-center justify-content-center align-items-center my-3">
          <Link to="/inscription-candidat">
            <MDBBtn className="BtnCard">Inscrire un candidat</MDBBtn>
          </Link>
        </div>
        <section>
          <h1 className={`text-center ${isDarkMode ? "text-white" : ""}`}>
            CVs Candidats
          </h1>
          <div className="search-container w-50 m-auto">
            <UserSearchCv
              onSearch={handleOnSearch}
              activeSuggestionIndex={activeSuggestionIndex}
              updateActiveSuggestionIndex={setActiveSuggestionIndex}
              suggestionsCount={suggestions.length}
              onSelection={handleSelection}
              suggestions={suggestions}
            />
            {(suggestions.length > 0 || (search.length > 0 && sortedFilteredUsers.length === 0)) && (
              <div className="suggestions-box m-auto">
                {suggestions.length === 0 ? (
                  <div className="suggestion">Aucun Résultat</div>
                ) : (
                  suggestions.map((user, idx) => (
                    <div
                      key={idx}
                      className={`suggestion ${activeSuggestionIndex === idx ? "active" : ""}`}
                      onClick={() => handleSuggestionClickCv(user)}
                      onMouseEnter={() => setActiveSuggestionIndex(idx)}
                    >
                      <strong>{user.nom.toUpperCase()}</strong>{" "}
                      {` ${formatPrenom(user.prenom)}`}
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
          <MDBContainer className="pb-5">
            <div>
              <ul
                className="nav nav-tabs mb-5 justify-content-center"
                id="issuesTabs"
                role="tablist"
              >
                <li
                  className={`nav-item ${noTransitionClass}`}
                  role="presentation"
                >
                  <button
                    className={`nav-link ${activeTab === "cvGeneres" ? "active" : ""
                      } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                    id="tab"
                    onClick={() => handleTabChange("cvGeneres")}
                  >
                    CV générés
                  </button>
                </li>
                <li
                  className={`nav-item ${noTransitionClass}`}
                  role="presentation"
                >
                  <button
                    className={`nav-link ${activeTab === "cvOrigine" ? "active" : ""
                      } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                    id="tab2"
                    onClick={() => handleTabChange("cvOrigine")}
                  >
                    CV d'origine
                  </button>
                </li>
                <li
                  className={`nav-item ${noTransitionClass}`}
                  role="presentation"
                >
                  <button
                    className={`nav-link ${activeTab === "testsTechniques" ? "active" : ""
                      } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                    id="tab3"
                    onClick={() => handleTabChange("testsTechniques")}
                  >
                    Tests Techniques
                  </button>
                </li>
              </ul>
            </div>
            {activeTab === "cvGeneres" && (
              <div>
                <div className="text-center">
                  <MDBBadge className="mx-2 mb-2" color="info" >
                    Nombre total de CVs : {totalCVs}
                  </MDBBadge>
                </div>
                <div
                  className="shadow-4 rounded-5 table-container"
                  style={{ overflowX: "auto" }}
                >
                  <MDBTable>
                    <MDBTableHead
                      className={`${isDarkMode ? "bg-dark-mode" : ""}`}
                    >
                      <tr style={{ color: "#09B4BF" }}>
                        <th>Nom et prénom</th>
                        <th>Date de inscription</th>
                        <th>Date de dernière de modification</th>
                        <th>CVs générés</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody
                      style={{ verticalAlign: "middle" }}
                      className={isDarkMode ? "text-white" : ""}
                    >
                      {sortedFilteredUsers.map((user) => (
                        <tr
                          key={user.id}
                          className={isDarkMode ? "text-white" : "text-muted"}
                        >
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src="/spinner-alcyone.png"
                                alt=""
                                style={{ width: "45px", height: "45px" }}
                                className="rounded-circle"
                              />
                              <Link
                                to={`/user-cvs/${user.id}`}
                                className="d-flex align-items-center ms-2"
                              >
                                <p className="noLinkStyle fw-bold mb-1">
                                  <span className="text-uppercase">
                                    {user.nom}
                                  </span>{" "}
                                  {formatPrenom(user.prenom)}
                                </p>
                              </Link>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              {new Date(user.dateCreation).toLocaleDateString(
                                "fr-FR"
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="ms-4">
                              {user.cvs &&
                                user.cvs[0] &&
                                user.cvs[0].date_modification
                                ? new Date(
                                  user.cvs[0].date_modification
                                ).toLocaleDateString("fr-FR")
                                : "-"}
                            </div>
                          </td>
                          <td>
                            <div className="ms-4">{user.cvs.length}</div>
                          </td>
                        </tr>
                      ))}
                    </MDBTableBody>
                  </MDBTable>
                </div>
              </div>
            )}

            {activeTab === "cvOrigine" && (
              <div>
                <div className="d-flex justify-content-between mb-3" style={{ cursor: "pointer" }}>
                  <div></div>
                  <div className="d-flex align-items-center">
                    <MDBBadge className="mx-2" color="info" light onClick={() => {
                      setPopupVisible(true);
                    }}>
                      Déposer PDF
                    </MDBBadge>
                    <MDBIcon
                      fas
                      icon="plus-circle"
                      size="2x"
                      style={{ color: "#09B4BF" }}
                      onClick={() => {
                        setPopupVisible(true);
                      }}
                    />
                  </div>
                </div>
                <div className="">
                  {isPopupVisible && (
                    <div className="popup">
                      <div className="popup-content text-center">
                        <MDBBtn onClick={() => setPopupVisible(false)}>x</MDBBtn>
                        {(currentUser.role === "MANAGER" || currentUser.role === "SUPERADMIN") ? (
                          <div className="m-auto w-50">
                            <p className="text-white" data-tour="step2-manager">
                              Choisissez un candidat
                            </p>
                            <UserSearch
                              onSearch={handleUserSearch} // mise à jour de l'état de recherche
                              activeSuggestionIndex={activeSuggestionIndex}
                              updateActiveSuggestionIndex={
                                updateActiveSuggestionIndex
                              }
                              suggestionsCount={suggestions.length}
                              onSelection={handleSelection} // gestion de la sélection de suggestion
                              suggestions={suggestions} // suggestions à afficher
                            />
                            {/* Affichage conditionnel de la boîte de suggestions */}
                            {(suggestions.length > 0 || search.length > 0) && (
                              <div className="suggestions-box">
                                {/* Affichage conditionnel si aucune suggestion n'est disponible */}
                                {suggestions.length === 0 ? (
                                  <div className="suggestion">Aucun Résultat</div>
                                ) : (
                                  // Mapping des suggestions pour affichage
                                  suggestions.map((user, idx) => (
                                    <div
                                      key={user.id} // Utilisez id pour la clé si disponible
                                      className={`suggestion ${activeSuggestionIndex === idx
                                        ? "active"
                                        : ""
                                        }`}
                                      onClick={() => handleSuggestionClick(user)}
                                      onMouseEnter={() =>
                                        setActiveSuggestionIndex(idx)
                                      }
                                    >
                                      <strong>{user.nom.toUpperCase()}</strong>
                                      {` ${user.prenom
                                        .charAt(0)
                                        .toUpperCase()}${user.prenom
                                          .slice(1)
                                          .toLowerCase()}`}
                                    </div>
                                  ))
                                )}
                              </div>
                            )}

                            <PDFViewer
                              pdfFile={pdfFile}
                              setPdfFile={setPdfFile}
                              filteredUser={filteredUser}
                              overrideRoleBehavior={true}
                            />
                            <div>
                              <CustomPDFDisplay
                                pdfFile={pdfFile}
                                filteredUser={filteredUser}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="text-center">
                            <PDFViewer
                              pdfFile={pdfFile}
                              setPdfFile={setPdfFile}
                              currentUser={currentUser}
                              overrideRoleBehavior={true}
                            />
                            <div>
                              <CustomPDFDisplay
                                pdfFile={pdfFile}
                                currentUser={currentUser}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="shadow-4 rounded-5 table-container"
                  style={{ overflowX: "auto" }}
                >
                  <MDBTable>
                    <MDBTableHead
                      className={`${isDarkMode ? "bg-dark-mode" : ""}`}
                    >
                      <tr style={{ color: "#09B4BF" }}>
                        <th>Nom et prénom</th>
                        <th className="text-center">Fichier</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody style={{ verticalAlign: "middle" }}>
                      {filteredUsersSecondTable.map((user) => (
                        <tr
                          key={user.id}
                          className={isDarkMode ? "text-white" : "text-muted"}
                        >
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src="./spinner-alcyone.png"
                                alt=""
                                style={{ width: "45px", height: "45px" }}
                                className="rounded-circle"
                              />
                              <p className="fw-bold mb-1 ms-2">
                                <span className="text-uppercase">{user.nom}</span>{" "}
                                {formatPrenom(user.prenom)}
                              </p>
                            </div>
                          </td>
                          <td className="text-center">
                            <p className="mb-0">
                              {user.docUrl ? (
                                <MDBIcon
                                  far icon="file-pdf"
                                  size="2x"
                                  style={{ color: "#09B4BF", cursor: "pointer" }}
                                  onClick={() => handleOriginPdfPreview(user.docUrl)}
                                  title="Ouvrir le PDF"
                                />
                              ) : (
                                "Aucun CV n'a été déposé."
                              )}
                            </p>
                          </td>
                          {/* <td className="text-center">
                          <MDBBtn
                            className="fw-bold"
                            color="link"
                            rounded
                            size="sm"
                            rippleColor="dark"
                            onClick={() => handleSharePDF(user.docUrl)}
                          >
                            <MDBIcon fas icon="share-square" />
                          </MDBBtn>
                          <MDBBtn
                            className="fw-bold"
                            color="link"
                            rounded
                            size="sm"
                            rippleColor="dark"
                            title="Copier lien"
                            onClick={() => handleLinkPDF(user.docUrl)}
                          >
                            <MDBIcon far icon="clone" />
                          </MDBBtn>
                        </td> */}
                        </tr>
                      ))}
                    </MDBTableBody>
                  </MDBTable>
                </div>
              </div>
            )}
            {activeTab === "testsTechniques" && (
              <div>
                <div
                  className="shadow-4 rounded-5 table-container"
                  style={{ overflowX: "auto" }}
                >
                  <MDBTable>
                    <MDBTableHead
                      className={`${isDarkMode ? "bg-dark-mode" : ""}`}
                    >
                      <tr style={{ color: "#09B4BF" }}>
                        <th>Nom et prénom</th>
                        <th>Métier</th>
                        <th>Résultat</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody style={{ verticalAlign: "middle" }}>
                      {sortedFilteredUsers.map((user) => (
                        <tr
                          key={user.id}
                          className={isDarkMode ? "text-white" : "text-muted"}
                        >
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src="./spinner-alcyone.png"
                                alt=""
                                style={{ width: "45px", height: "45px" }}
                                className="rounded-circle"
                              />
                              <p className="fw-bold mb-1 ms-2">
                                <span className="text-uppercase">{user.nom}</span>{" "}
                                {formatPrenom(user.prenom)}
                              </p>
                            </div>
                          </td>
                          <td className="">IT</td>
                          <td className="">09/10</td>
                        </tr>
                      ))}
                    </MDBTableBody>
                  </MDBTable>
                </div>
              </div>
            )}
          </MDBContainer>
        </section>
      </MDBContainer>
    </MDBContainer>
  );
}
