import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './Navbar.css';
import "../../Pages/Accueil/Accueil.css";
import {
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbar,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useDarkMode } from '../../components/assets/DarkModeContext';
import { Switch } from "@mui/material";

export default function NavbarGuest() {
  const [scrollNav, setScrollNav] = useState(false);
  const { isDarkMode, toggle } = useDarkMode();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrollNav(true);
      } else {
        setScrollNav(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getNavbarClass = () => {
    if (scrollNav) {
      return "navbar-scroll";
    } else if (isDarkMode) {
      return "navbar-dark";
    }
    return "navbar-transparent";
  };

  const getBrightness = () => {
    if (scrollNav) {
      return "brightness(100%)";
    }
    return "brightness(150%)";
  };


  return (
    <>
      <MDBNavbar
        expand="lg"
        className={`navbar navbar-expand-lg fixed-top ${getNavbarClass()} animation-fade-in`}
        dark={isDarkMode}
      >
        <MDBContainer>
          
          <MDBNavbarBrand>
            <div className="animation-apparition-gauche">
              <Link to="/">
                <img
                  src={isDarkMode ? "/logo_negatif.png" : "/logo-alcyone.png"}
                  height="50"
                  alt="Logo Alcyone"
                  loading="lazy"
                  style={{ filter: getBrightness()}}
                />
              </Link>
            </div>
          </MDBNavbarBrand>
          <div className="d-flex align-items-center">
            <MDBIcon fas icon="sun" className={isDarkMode ? 'text-muted' : 'text-yellow'} />

            <Switch checked={isDarkMode} onChange={toggle} />

            <MDBIcon fas icon="moon" className={isDarkMode ? 'text-blue' : 'text-muted'} />
          </div>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}
