import React, { createContext, useContext, useState, useEffect } from 'react';

const DarkModeContext = createContext();

export const useDarkMode = () => {
  return useContext(DarkModeContext);
};

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedDarkModePreference = localStorage.getItem("darkMode");
    return savedDarkModePreference !== null ? savedDarkModePreference === "true" : false;
  });

  const [isTransitionDisabled, setIsTransitionDisabled] = useState(false);

  // Déplacez la fonction toggle ici, à l'intérieur de DarkModeProvider
  const toggle = () => {
    setIsDarkMode(prev => !prev);
  };

  const toggleDarkMode = () => {
    setIsTransitionDisabled(true); // Désactive les transitions
    setIsDarkMode(prev => !prev); // Change le mode
    setTimeout(() => setIsTransitionDisabled(false), 1000); // Réactive les transitions après 1 seconde
  };

  useEffect(() => {
    localStorage.setItem("darkMode", isDarkMode.toString());
  }, [isDarkMode]);

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode, isTransitionDisabled, toggle }}>
      {children}
    </DarkModeContext.Provider>
  );
};
