import React, { useState, useEffect } from 'react';
import "../../Pages/Accueil/Accueil.css";
import {
  MDBContainer,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBadge,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import { useDarkMode } from "../../components/assets/DarkModeContext";
import UserSearch from "../../components/assets/UserSearch";
import { Link, useLocation, useParams } from "react-router-dom";
import { http } from '../../components/services/httpService';
import { useCurrentUser } from '../../components/hooks/useCurrentUser';

const EspaceClient = () => {
  const { isDarkMode, isTransitionDisabled } = useDarkMode();
  const noTransitionClass = isTransitionDisabled ? "no-transition" : "";
  const [activeItem, setActiveItem] = useState('1');
  const { id, accountName } = useParams();
  const [jobs, setJobs] = useState([]);
  const currentUser = useCurrentUser();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const endpoint = id ? `api/salesforce/aos?id=${id}` : `api/salesforce/aos?accountName=${accountName}`;
      const response = await http(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setJobs(data);
    };

    fetchData();
  }, [id, accountName]);

  const filteredJobs = jobs.filter(job => 
    (job.accountId === id || job.accountName === accountName) &&
    job.description.toLowerCase().includes(searchQuery.toLowerCase())
  );  

  const handleItemClick = value => {
    if (value === activeItem) {
      return;
    }

    setActiveItem(value);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <MDBContainer fluid className={isDarkMode ? "bg-dark-mode" : "bg-light"}>
      <MDBContainer className="animation-fade-in pb-5">
        <div
          className="p-5 bg-image"
          style={{
            height: "300px",
            backgroundImage: 'url("/bg/creation-cv.jpg")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundAttachment: "fixed",
          }}
        ></div>
        <div className="w-50 mt-5 m-auto text-center">
          <MDBBadge pill className="mx-2" color="info" light>{accountName}</MDBBadge>
          <MDBTabs className='my-3 justify-content-center'>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleItemClick('1')}
                active={activeItem === '1'}
                className={`nav-link ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
              >
                Appels d'offres
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </div>
        <MDBTabsContent>
          <MDBTabsPane show={activeItem === '1'}>
            {currentUser?.role !== 'CLIENT' && (
              <div className="w-50 mt-5 m-auto text-center">
                <UserSearch
                  placeholder="Chercher par appel d'offre"
                  disableEnter={true}
                  onSearch={handleSearch}
                />
              </div>
            )}
            <MDBTable align='middle'>
              <MDBTableBody
                className={isDarkMode ? "text-white" : ""}
              >
                {filteredJobs.map((job, index) => (
                  <tr key={index} className={isDarkMode ? "text-white" : "text-muted"}>
                    <td>
                      <Link to={`/appel-offre/${job.id}`} className='d-flex align-items-center'>
                        <div className='d-flex align-items-center'>
                          <div className='noLinkStyle ms-3'>
                            <p className='fw-bold mb-1'>{job.description}</p>
                          </div>
                        </div>
                      </Link>
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          </MDBTabsPane>
        </MDBTabsContent>
      </MDBContainer>
    </MDBContainer>
  );
};

export default EspaceClient;
