import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link, useLocation } from "react-router-dom";
import "../../Pages/Accueil/Accueil.css";
import {
  MDBContainer,
  MDBTable,
  MDBTableHead,
  MDBCard,
  MDBCardBody,
  MDBTableBody,
  MDBBadge,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBIcon,
} from "mdb-react-ui-kit";
import {
  getAllUsers,
} from "../../components/services/httpService";
import { useDarkMode } from "../../components/assets/DarkModeContext";
import UserSearch from "../../components/assets/UserSearch";
import { useCurrentUser } from '../../components/hooks/useCurrentUser';
import { http } from '../../components/services/httpService';

const AppelOffre = () => {
  const { isDarkMode, isTransitionDisabled } = useDarkMode();
  const noTransitionClass = isTransitionDisabled ? "no-transition" : "";
  const [activeItem, setActiveItem] = useState('1');
  const [users, setUsers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const history = useHistory();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const currentUser = useCurrentUser();
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const { accountName } = location.state || {};

  useEffect(() => {
    getAllUsers().then(response => {
      response.json().then(data => {
        setUsers(data);
      }).catch(error => {
        console.error("Error parsing JSON:", error);
      });
    }).catch(error => {
      console.error("Error loading users:", error);
    });
  }, []);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await http('api/salesforce/aos', {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const allJobs = await response.json();

        let specificJobDetails = allJobs.find(job => job.id === id);
        if (!specificJobDetails) {
          specificJobDetails = allJobs.find(job => job.accountName === id);
        }

        setJobDetails(specificJobDetails || null);

      } catch (error) {
        console.error('Error fetching job details:', error);
      }
    };

    if (id) {
      fetchJobDetails();
    }
  }, [id]);

  //Tabs
  const handleItemClick = value => {
    if (value === activeItem) {
      return;
    }

    setActiveItem(value);
  };

  function safeToLower(text) {
    return text ? text.toLowerCase() : '';
  }

  const handleSearch = (query) => {
    if (!query || !Array.isArray(users)) {
      setSuggestions([]);
      setIsExpanded(false);
      return;
    }

    const normalizedQuery = query.toLowerCase();

    const filteredSuggestions = users.reduce((acc, user) => {
      const userMatch = user?.nom.toLowerCase().includes(normalizedQuery) ||
        user?.prenom.toLowerCase().includes(normalizedQuery);
      const cvs = user.cvs || [];

      cvs.forEach(cv => {
        const motCleLower = safeToLower(cv.mot_cle);

        if (userMatch || motCleLower.includes(normalizedQuery)) {
          acc.push({
            userId: user.id,
            nom: user.nom,
            prenom: user.prenom,
            cvId: cv.id,
            cvMotCle: cv.mot_cle
          });
        }
      });

      return acc;
    }, []);

    if (filteredSuggestions.length > 0) {
      setSuggestions(filteredSuggestions);
      setIsExpanded(true);
    } else {
      setSuggestions([]);
      setIsExpanded(false);
    }
  };

  const navigateToCvCandidat = (userId, cvId) => {
    history.push(`/mon-espace-cv/${userId}/${cvId}`);
  };

  const associateUserToJobPosting = async (userId, jobPostingId, cvId) => {
    console.log("Associating user:", userId, "with job posting:", jobPostingId);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      body: JSON.stringify({ userId, jobPostingId, cvId })
    };

    try {
      const response = await http('user-job-postings/associate', requestOptions);
      if (!response.ok) {
        throw new Error(`Network response was not ok, status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Successfully created association", data);
      return data;
    } catch (error) {
      console.error('Error associating user with job offer:', error);
    }
  };

  useEffect(() => {
    const fetchSelectedUsersByJobPosting = async () => {
      try {
        const response = await http(`user-job-postings/job-postings/${id}/selected-users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const users = await response.json();
        setSelectedUsers(users);
      } catch (error) {
        console.error('Failed to fetch selected users:', error);
      }
    };

    if (id) {
      fetchSelectedUsersByJobPosting();
    }
  }, [id]);

  //Pour clients
  useEffect(() => {
    const fetchSelectedUsers = async (jobId) => {
      try {
        const token = localStorage.getItem("token");
        const response = await http(`user-job-postings/job-postings/${jobId}/selected-users`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch selected users');
        }
        const users = await response.json();
        setSelectedUsers(users);
      } catch (error) {
        console.error('Error fetching selected users:', error);
      }
    };

    if (jobDetails) {
      fetchSelectedUsers(jobDetails.id);
    }
  }, [jobDetails]);

  const handleSelection = async (selectedItem) => {
    const userId = selectedItem.userId;
    const cvId = selectedItem.cvId;
    const jobPostingId = id;

    try {
      const associationResult = await associateUserToJobPosting(userId, jobPostingId, cvId);
      console.log("Successfully created association", associationResult);

      setSelectedUsers((prevUsers) => {
        const existingUserIndex = prevUsers.findIndex(user => user.id === userId);
        if (existingUserIndex !== -1) {
          const newUsers = [...prevUsers];
          const existingCVIndex = newUsers[existingUserIndex].selectedCvs.findIndex(cv => cv.id === cvId);

          if (existingCVIndex === -1) {
            newUsers[existingUserIndex].selectedCvs.push({
              id: cvId,
              mot_cle: selectedItem.cvMotCle
            });
          }
          return newUsers;
        } else {
          return [...prevUsers, {
            ...selectedItem,
            selectedCvs: [{ id: cvId, mot_cle: selectedItem.cvMotCle }],
            id: userId
          }];
        }
      });
    } catch (error) {
      console.error("Error creating association", error);
    }

    setIsExpanded(false);
  };

  const handleRemoveCv = async (userId, jobPostingId, cvId) => {
    const confirmDelete = window.confirm("Etes-vous sûr de vouloir supprimer ce CV ?");

    if (confirmDelete) {
      const requestOptions = {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      };

      const url = `user-job-postings/disassociate?userId=${userId}&jobPostingId=${jobPostingId}&cvId=${cvId}`;

      console.log(`UserID: ${userId}, JobPostingID: ${jobPostingId}, CV ID: ${cvId}`);

      try {
        const response = await http(url, requestOptions);
        if (!response.ok) {
          throw new Error(`Network response was not ok, status: ${response.status}`);
        }

        setSelectedUsers(prevUsers => prevUsers.map(user => {
          if (user.id === userId) {
            const filteredCvs = user.selectedCvs.filter(cv => cv.id !== cvId);
            return { ...user, selectedCvs: filteredCvs };
          }
          return user;
        }));
      } catch (error) {
        console.error("Failed to disassociate CV", error);
      }
    } else {
      console.log("CV deletion cancelled by user.");
    }
  };

  return (
    <MDBContainer fluid className={isDarkMode ? "bg-dark-mode" : "bg-light"}>
      <MDBContainer className="animation-fade-in pb-5">
        <div
          className="p-5 bg-image"
          style={{
            height: "300px",
            backgroundImage: 'url("/bg/creation-cv.jpg")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundAttachment: "fixed",
          }}
        ></div>
        <div className="w-50 mt-5 m-auto text-center">
          <MDBBadge pill className="mx-2" color="info" light> {jobDetails && <span>{jobDetails.accountName}</span>} - {jobDetails && <span>{jobDetails.description}</span>}</MDBBadge>
          <MDBTabs className='my-3 justify-content-center'>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleItemClick('1')}
                active={activeItem === '1'}
                className={`nav-link ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
              >
                Candidats
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleItemClick('3')}
                active={activeItem === '3'}
                className={`nav-link ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
              >
                Description
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleItemClick('2')}
                active={activeItem === '2'}
                className={`nav-link ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
              >
                Interlocuteurs
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </div>
        <MDBTabsContent>
          <MDBTabsPane show={activeItem === '3'}>
            <MDBTable align='middle'>
              <MDBTableBody>
                <tr>
                  <MDBCard className="mb-4" style={{
                    padding: "10px", fontSize: "14px", border: "1px solid #09B4BF",
                    borderRadius: "10px"
                  }}>
                    <MDBCardBody>
                      <div className="text-center py-4">
                        <div className="ms-3">
                          <p
                            className="text-muted mb-1"
                            style={{ fontSize: "16px" }}
                          >
                            {jobDetails && <p>{jobDetails.description}</p>}
                          </p>
                        </div>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBTabsPane>
          <MDBTabsPane show={activeItem === '2'}>
            <div className="w-50 my-3 m-auto text-center">
              <UserSearch
                placeholder="Chercher par nom"
              />
            </div>
            <MDBTable align='middle'>
              <MDBTableHead>
                <tr>
                  <th scope='col'>Nom</th>
                  <th scope='col'>Fonction</th>
                  <th scope='col'>Adresse e-mail</th>
                  <th scope='col'>Numéro de téléphone</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {jobDetails && jobDetails.nameContact1 && (
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <img
                          src="/spinner-alcyone.png"
                          alt=""
                          style={{ width: "40px", height: "40px" }}
                          className="rounded-circle"
                        />
                        <div className='ms-3'>
                          <p className='fw-bold mb-1'>{jobDetails && <p>{jobDetails.nameContact1}</p>}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className='fw-normal mb-1'>-</p>
                    </td>
                    <td>
                      <p className='text-muted mb-0'>-</p>
                    </td>
                    <td>-</td>
                  </tr>
                )}
                {jobDetails && jobDetails.nameContact2 && (
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <img
                          src="/spinner-alcyone.png"
                          alt=""
                          style={{ width: "40px", height: "40px" }}
                          className="rounded-circle"
                        />
                        <div className='ms-3'>
                          <p className='fw-bold mb-1'>{jobDetails && <p>{jobDetails.nameContact2}</p>}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className='fw-normal mb-1'>-</p>
                    </td>
                    <td>
                      <p className='text-muted mb-0'>-</p>
                    </td>
                    <td>-</td>
                  </tr>
                )}
              </MDBTableBody>
            </MDBTable>
          </MDBTabsPane>
          <MDBTabsPane show={activeItem === '1'}>
            {currentUser?.role !== 'CLIENT' && (
              <div className="w-50 my-3 m-auto text-center">
                <UserSearch
                  onSearch={handleSearch}
                  onSelection={handleSelection}
                  suggestions={suggestions}
                  disableEnter={true}
                  placeholder="Chercher par nom"
                />
                {isExpanded && suggestions.length > 0 && (
                  <div className="suggestions-box">
                    {suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          handleSelection(suggestion);
                          setIsExpanded(false);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {suggestion.nom} {suggestion.prenom} - {suggestion.cvMotCle}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            <MDBTable align='middle'>
              <MDBTableBody>
                {selectedUsers.map((user, userIndex) => (
                  <React.Fragment key={userIndex}>
                    {user.selectedCvs && user.selectedCvs.length > 0 ? (
                      user.selectedCvs.map((cv, cvIndex) => (
                        <tr key={`cv-${cvIndex}`}>
                          <td onClick={() => navigateToCvCandidat(user.id, cv.id)} style={{ cursor: "pointer" }}>
                            <div className='d-flex align-items-center'>
                              <img
                                src="/spinner-alcyone.png"
                                alt=""
                                style={{ width: "40px", height: "40px" }}
                                className="rounded-circle"
                              />
                              <p className='fw-bold mb-1'>{user.prenom} {user.nom}</p>
                            </div>
                          </td>
                          <td onClick={() => navigateToCvCandidat(user.id, cv.id)} style={{ cursor: "pointer" }}>
                            <p>{cv.mot_cle || 'None'}</p>
                          </td>
                          {currentUser?.role !== 'CLIENT' && (
                            <td>
                              <td><MDBIcon fas icon="trash-alt" className="pt-2 text-info" onClick={() => handleRemoveCv(user.id, id, cv.id)}></MDBIcon></td>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <p>Aucun CV disponible</p>
                    )}
                  </React.Fragment>
                ))}
              </MDBTableBody>
            </MDBTable>
          </MDBTabsPane>
        </MDBTabsContent>
      </MDBContainer>
    </MDBContainer>
  );
};

export default AppelOffre;
