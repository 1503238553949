import React, { useState, useEffect } from 'react';
import "../../Pages/Accueil/Accueil.css";
import UserSearch from "../../components/assets/UserSearch";
import EspaceClient from "./EspaceClient";
import { http, getProfile } from '../../components/services/httpService';
import { Link } from "react-router-dom";
import { uniqBy } from 'lodash';
import {
  MDBContainer,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn
} from "mdb-react-ui-kit";
import { useDarkMode } from "../../components/assets/DarkModeContext";

export default function Client() {
  const [user, setUser] = useState(null);
  const { isDarkMode, isTransitionDisabled } = useDarkMode();
  const [clients, setClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await http('api/salesforce/aos', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const uniqueClients = uniqBy(data, 'accountId');
        setClients(uniqueClients);
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const filteredClients = searchQuery
    ? clients.filter(client =>
      client.accountName.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : clients;

  return (
    <MDBContainer fluid className={isDarkMode ? "bg-dark-mode" : "bg-light"}>
      <MDBContainer className="animation-fade-in pb-5">
        <div
          className="p-5 bg-image"
          style={{
            height: "300px",
            backgroundImage: 'url("./bg/creation-cv.jpg")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundAttachment: "fixed",
          }}
        ></div>
        <MDBRow className="text-center pt-3">
          <div className="d-flex text-center justify-content-center align-items-center my-3">
            <Link to="/inscription-client">
              <MDBBtn className="BtnCard">Inscrire un client</MDBBtn>
            </Link>
          </div>
          <div className={isDarkMode ? "text-white" : "text-muted"}>
            <h1>Clients</h1>
          </div>
          <div className="w-50 m-auto text-center">
            <UserSearch
              placeholder="Chercher par entreprise"
              onSearch={handleSearch}
            />
          </div>
          <MDBTable align='middle'>
            <MDBTableBody
              className={isDarkMode ? "text-white" : ""}
            >
              {filteredClients.map((client, index) => (
                <tr key={index} className={isDarkMode ? "text-white" : "text-muted"}>
                  <td>
                    <Link to={`/espace-client/id/${client.accountId}`}
                      className="d-flex align-items-center ms-2"
                    >
                      <div className='d-flex align-items-center'>
                        <div className='noLinkStyle ms-3'>
                          <p className='fw-bold mb-1'>{client.accountName}</p>
                        </div>
                      </div>
                    </Link>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </MDBRow>
      </MDBContainer>
    </MDBContainer >
  );
}
