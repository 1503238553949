import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link, useHistory } from "react-router-dom";
import "../../Pages/Accueil/Accueil.css";
import { NavLink } from "react-router-dom";
import UserSearch from "../../components/assets/UserSearch";
import useDebounce from "../../components/hooks/useDebounce";
import { useDarkMode } from "../../components/assets/DarkModeContext";
import { Switch } from "@mui/material";
import useAuth from "../SSO/useAuth";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
  MDBIcon,
} from "mdb-react-ui-kit";
import jwt_decode from "jwt-decode";
import { getProfile, getAllUsers } from "../services/httpService";
import SimpleDropdown from "./Dropdown";

export default function NavbarManager({ setUserRole }) {
  const [showNav, setShowNav] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState({ nom: "", prenom: "", role: "" });
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const debouncedSearchTerm = useDebounce(search, 300);
  const { isDarkMode, toggleDarkMode, isTransitionDisabled } = useDarkMode();
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const noTransitionClass = isTransitionDisabled ? "no-transition" : "";
  const auth = useAuth();

  useEffect(() => {
    getProfile()
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
      })
      .catch((error) => console.error(error));

    getAllUsers()
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const searchWords = debouncedSearchTerm.toLowerCase().split(/\s+/);

      const filteredSuggestions = users.filter((user) => {
        const userFullName =
          user.prenom?.toLowerCase() + " " + user.nom?.toLowerCase();
        return searchWords.every((word) => userFullName.includes(word));
      });

      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearchTerm, users]);

  const handleSuggestionClick = (user) => {
    history.push(`/user-cvs/${user.id}`);
    setSearch("");
    setSuggestions([]);
  };

  const handleLogout = () => {
    auth.logout();
    console.log("Déconnexion");
  };

  const [hovered, setHovered] = useState(false);

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };

  const iconStyle = {
    color: "white",
    filter: hovered ? "brightness(90%)" : "brightness(100%)",
  };

  const updateActiveSuggestionIndex = (newIndex) => {
    setActiveSuggestionIndex(newIndex);
  };

  const handleUserSearch = (searchQuery) => {
    setSearch(searchQuery);
  };

  const handleSelection = () => {
    if (
      activeSuggestionIndex >= 0 &&
      activeSuggestionIndex < suggestions.length
    ) {
      handleSuggestionClick(suggestions[activeSuggestionIndex]);
    }
  };

  return (
    <MDBNavbar
      expand="lg"
      className={`bg-nav animation-fade-in ${isDarkMode ? "navbar-dark-mode" : ""
        }`}
      style={{
        zIndex: 1000,
        transition: isTransitionDisabled
          ? "none"
          : "background-color 0.5s ease",
      }}
      dark={isDarkMode}
      light={!isDarkMode}
    >
      <MDBContainer fluid>
        <MDBNavbarBrand className="animation-apparition-gauche">
          <div className="d-flex">
            <a href="https://www.greatplacetowork.lu/fr/certification/profils/alcyone-consulting">
              <img
                src={isDarkMode ? "/logo_1_blanc.png" : "/logo_1.png"}
                height="50"
                alt=""
                loading="lazy"
                style={{ marginBottom: "-13px", marginTop: "-14px" }}
              />
            </a>
            <a href="https://www.greatplacetowork.lu/fr/certification/palmar%C3%A8s-best-workplaces%C2%AE-luxembourg/2024?category=moyennes">
              <img
                src="/logo_gpw_2.png"
                height="50"
                alt=""
                loading="lazy"
                style={{ marginBottom: "-13px", marginTop: "-14px" }}
              />
            </a>
            <a href="https://resources.ecovadis.com/fr/documentation-solutions-ecovadis/ecovadis-medals-and-badges-fr">
              <img
                src="/logo_eco_3.png"
                height="50"
                alt=""
                loading="lazy"
                style={{ marginBottom: "-13px", marginTop: "-14px" }}
              />
            </a>
          </div>
          <Link to="/">
            <img
              src={isDarkMode ? "/logo_negatif.png" : "/logo-alcyone.png"}
              height="50"
              alt=""
              loading="lazy"
              style={{ marginLeft: "22px" }}
            />
          </Link>
        </MDBNavbarBrand>
        <MDBNavbarToggler
          type="button"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowNav(!showNav)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showNav}>
          <MDBNavbarNav className="d-flex align-items-center">
            <MDBNavbarItem>
              <NavLink
                to="/candidats"
                className="navbar-link"
                activeClassName="active"
              >
                Candidats
              </NavLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <NavLink
                to="/tests-techniques"
                activeClassName="active"
                className="navbar-link"
              >
                Tests techniques
              </NavLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <NavLink
                to="/mon-equipe"
                className="navbar-link"
                activeClassName="active"
              >
                Mon équipe
              </NavLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <NavLink
                to="/client"
                className="navbar-link"
                activeClassName="active"
              >
                Clients
              </NavLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <NavLink
                to="/recherche-avance"
                className="navbar-link"
                activeClassName="active"
              >
                Recherche avancée
              </NavLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
          <MDBNavbarItem className={`list-unstyled ${noTransitionClass}`}>
            <div className={`search-navbar ${noTransitionClass}`}>
              <UserSearch
                onSearch={handleUserSearch}
                activeSuggestionIndex={activeSuggestionIndex}
                updateActiveSuggestionIndex={setActiveSuggestionIndex}
                suggestionsCount={suggestions.length}
                onSelection={handleSelection}
                suggestions={suggestions}
                isTransitionDisabled={isTransitionDisabled}
              />

              {(suggestions.length > 0 || search.length > 0) && (
                <div className={`suggestions-box ${noTransitionClass}`}>
                  {suggestions.length === 0 ? (
                    <div className={`suggestion ${noTransitionClass}`}>
                      Aucun Résultat
                    </div>
                  ) : (
                    suggestions.map((user, idx) => (
                      <div
                        key={idx}
                        className={`suggestion ${activeSuggestionIndex === idx ? "active" : ""
                          } ${noTransitionClass}`}
                        onClick={() => handleSuggestionClick(user)}
                        onMouseEnter={() => setActiveSuggestionIndex(idx)}
                      >
                        <strong>{user.nom.toUpperCase()}</strong>{" "}
                        {` ${user.prenom.charAt(0).toUpperCase() +
                          user.prenom.slice(1).toLowerCase()
                          }`}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          </MDBNavbarItem>
          <div className="d-flex align-items-center me-4 justify-content-center">
            <MDBIcon
              fas
              icon="sun"
              className={isDarkMode ? "text-muted" : "text-yellow"}
            />

            <Switch checked={isDarkMode} onChange={toggleDarkMode} />

            <MDBIcon
              fas
              icon="moon"
              className={isDarkMode ? "text-blue" : "text-muted"}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <SimpleDropdown />
          </div>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar >
  );
}
