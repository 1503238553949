import html2pdf from "html2pdf.js";

export function formatPrenom(prenom) {
  if (!prenom) return "";

  return prenom
    .split(/[-\s]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(prenom.includes("-") ? "-" : " ");
}

export function getUserInitials(user) {
  if (!user) return { firstNameInitials: '', lastNameInitials: '' };

  const firstNameInitials = user.prenom ? user.prenom[0] : '';
  const lastNameInitials = user.nom ? user.nom[0] + user.nom.slice(-1) : '';

  return { firstNameInitials, lastNameInitials };
}

//Pour creationCv
export function getUserInitialsAdd(user) {
  let prenomInitial = '';
  let nomInitials = '';

  if (user.prenom) {
    prenomInitial = user.prenom[0];
  }

  if (user.nom) {
    const lastNameInitial = user.nom[0];
    const lastNameLastLetter = user.nom.slice(-1);
    nomInitials = `${lastNameInitial}${lastNameLastLetter}`;
  }

  return {
    prenomInitial: prenomInitial.toUpperCase(),
    nomInitials: nomInitials.toUpperCase()
  };
}

function generatePdfFilename(user, langue, mot_cle) {
  return `ACO_${user.prenom}_${user.nom.toUpperCase()}_CV_${langue?.toUpperCase()}_${mot_cle}.pdf`;
}

export function handlePDFAction(download, user, cvPreviewElement, userData, langue, openInNewWindow = false, mot_cle, translations) {

  const managerRoles = ["MANAGER", "SUPERADMIN"];
  const candidateRoles = ["CANDIDAT", "CONSULTANT"];

  const isManagerRole = managerRoles.includes(user?.role);
  const isCandidateRole = candidateRoles.includes(user?.role);

  return new Promise((resolve, reject) => {
    const clonedElement = cvPreviewElement.cloneNode(true);
    clonedElement.classList.add("pdf-generation");
    document.body.appendChild(clonedElement);

    const filename = generatePdfFilename(userData, langue, mot_cle);

    const options = {
      margin: [30, 5, 39, 5],
      filename: filename,
      image: { type: "jpeg", quality: 0.95 },
      html2canvas: { scale: 3, dpi: 192, letterRendering: true, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'], before: '.avoid-page-break-before', after: '.avoid-page-break-after', inside: '.no-page-break' }

    };

    const clonedFooter = clonedElement.querySelector(".cv-footer");
    if (clonedFooter) {
      clonedFooter.remove();
    }

    const clonedHeader = clonedElement.querySelector(".header-cv");
    if (clonedHeader) {
      clonedHeader.remove();
    }

    const clonedSpinner = clonedElement.querySelector(".spinner-cv");
    if (clonedSpinner) {
      clonedSpinner.remove();
    }

    const headerImage = '/header-image.png';
    const footerImage = '/footer-gpw.png';
    const spinnerImage = '/spinner-alcyone.png';

    html2pdf().from(clonedElement).set(options).toPdf().get('pdf').then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages - 1; i++) {
        pdf.setPage(i);

        if (i === 1) {
          // const spinnerImage = '/spinner-alcyone.png';
          const spinnerX = 17;
          const spinnerY = 18;
          const spinnerWidth = 30;
          const spinnerHeight = 30;
          pdf.addImage(spinnerImage, 'PNG', spinnerX, spinnerY, spinnerWidth, spinnerHeight);
        }

        pdf.setFontSize(10);
        pdf.setTextColor(255, 255, 255);

        pdf.addImage(headerImage, 'JPEG', 5, 0, pdf.internal.pageSize.getWidth(), 30, undefined, 'FAST');

        pdf.addImage(footerImage, 'JPEG', 0, pdf.internal.pageSize.getHeight() - 38, pdf.internal.pageSize.getWidth(), 40, undefined, 'FAST');

        const footerStartHeight = pdf.internal.pageSize.getHeight() - 25;
        pdf.setFont("helvetica", "bold");
        pdf.text(translations.inTouch, 10, footerStartHeight);

        pdf.setFont("helvetica", "normal");
        const marginAfterTitle = 7;
        const verticalSpacing = 4;

        if (isManagerRole) {
          pdf.text(`${user?.prenom || ''} ${user?.nom.toUpperCase() || ''}`, 10, footerStartHeight + marginAfterTitle);
          pdf.text(`${user?.managerDetails?.poste || ''}`, 10, footerStartHeight + marginAfterTitle + verticalSpacing);
          pdf.text(`${user?.phone || ''}`, 10, footerStartHeight + marginAfterTitle + 2 * verticalSpacing);
          pdf.text(`${user?.email || ''}`, 10, footerStartHeight + marginAfterTitle + 3 * verticalSpacing);
        } else if (isCandidateRole) {
          pdf.text(`${userData?.manager?.prenom || ''} ${userData?.manager?.nom.toUpperCase() || ''}`, 10, footerStartHeight + marginAfterTitle);
          pdf.text(`${userData?.manager?.managerDetails?.poste || ''}`, 10, footerStartHeight + marginAfterTitle + verticalSpacing);
          pdf.text(`${userData?.manager?.phone || ''}`, 10, footerStartHeight + marginAfterTitle + 2 * verticalSpacing);
          pdf.text(`${userData?.manager?.email || ''}`, 10, footerStartHeight + marginAfterTitle + 3 * verticalSpacing);
        }
      }

      if (download) {
        pdf.save(filename);
        resolve();
      } else if (openInNewWindow) {
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
        resolve(pdf);
      } else {
        resolve(pdf);
      }

      document.body.removeChild(clonedElement); // Remover el clon del body

    }).catch((error) => {
      console.error("Error al generar el PDF: ", error);
      if (clonedElement && clonedElement.parentNode) {
        document.body.removeChild(clonedElement); // Asegurarse de remover el clon en caso de error
      }
      reject(error);
    });
  });
}

