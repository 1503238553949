import React, { createContext, useContext, useState } from 'react';

const PdfContext = createContext();

export const usePdfContext = () => useContext(PdfContext);

export const PdfProvider = ({ children }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  return (
    <PdfContext.Provider value={{ pdfUrl, setPdfUrl }}>
      {children}
    </PdfContext.Provider>
  );
};
