import React, { useState } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody, MDBIcon } from 'mdb-react-ui-kit';
import SelectLevelLanguage from './SelectLevelLanguage';
import SelectLanguage from './SelectLanguage';

function LanguageSkills({ languageSkills, setLanguageSkills, handleLanguageSkillChange, translations, areLanguagesVisible, handleLanguagesClick }) {

    const removeLanguageSkill = (index) => {
        const updatedSkills = languageSkills.filter((_, i) => i !== index);
        setLanguageSkills(updatedSkills);
    };

    return (
        <>
            <div className="subtitre-cv-container text-underline d-flex align-items-center gap-2"
                onClick={handleLanguagesClick}
                style={{ cursor: "pointer" }}
            >
                <h4 className="text-underline pt-2 text-white">
                    {translations.languages}
                </h4>
                <div className="line bg-white"></div>
                <MDBIcon
                    fas
                    icon={"globe"}
                    style={{ color: "white" }}
                    size="lg"
                />
            </div>
            {areLanguagesVisible && (
                <>
                    <div className="shadow-4 rounded-5" style={{ overflowX: 'auto' }}>
                        <MDBTable>
                            <MDBTableHead>
                                <tr className="text-center text-white bg-gradient">
                                    <th></th>
                                    <th colSpan="2">{translations.labels.understand}</th>
                                    <th colSpan="2">{translations.labels.express}</th>

                                </tr>
                                <tr className="text-white">
                                    <th>{translations.labels.selectALanguage}</th>
                                    <th className="text-center">{translations.labels.speak}</th>
                                    <th className="text-center">{translations.labels.write}</th>
                                    <th className="text-center">{translations.labels.speak}</th>
                                    <th className="text-center">{translations.labels.write}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody style={{ verticalAlign: 'middle' }}>
                                {languageSkills.map((skill, index) => (
                                    <tr key={skill.id ? `skill-${skill.id}` : `index-${index}`}>
                                        <th colSpan="1">
                                            <SelectLanguage
                                                id={`language-${skill.id}`}
                                                selectedLanguage={skill.name}
                                                handleLanguageChange={(e) => handleLanguageSkillChange(index, 'name', e.target.value)}
                                                translations={translations}
                                            />
                                        </th>
                                        <td>
                                            <SelectLevelLanguage
                                                id={`oral-comprehension-${skill.id}`}
                                                selectedValue={skill.oralComprehension}
                                                handleChange={(e) => handleLanguageSkillChange(index, 'oralComprehension', e.target.value)}
                                                translations={translations}
                                            />
                                        </td>
                                        <td>
                                            <SelectLevelLanguage
                                                id={`written-comprehension-${skill.id}`}
                                                selectedValue={skill.writtenComprehension}
                                                handleChange={(e) => handleLanguageSkillChange(index, 'writtenComprehension', e.target.value)}
                                                translations={translations}
                                            />
                                        </td>
                                        <td>
                                            <SelectLevelLanguage
                                                id={`oral-expression-${skill.id}`}
                                                selectedValue={skill.oralExpression}
                                                handleChange={(e) => handleLanguageSkillChange(index, 'oralExpression', e.target.value)}
                                                translations={translations}
                                            />
                                        </td>
                                        <td>
                                            <SelectLevelLanguage
                                                id={`written-expression-${skill.id}`}
                                                selectedValue={skill.writtenExpression}
                                                handleChange={(e) => handleLanguageSkillChange(index, 'writtenExpression', e.target.value)}
                                                translations={translations}
                                            />
                                        </td>
                                        <td className='border-bottom-0 border-top-0'>
                                            <MDBIcon fas icon="trash-alt" className="py-5" color='light' onClick={() => removeLanguageSkill(index)} />
                                        </td>
                                    </tr>
                                ))}

                            </MDBTableBody>
                        </MDBTable>
                        <button className="btn_primary" onClick={() => setLanguageSkills([...languageSkills, {
                            name: '',
                            oralComprehension: '',
                            writtenComprehension: '',
                            oralExpression: '',
                            writtenExpression: '',
                        }])} >
                            <MDBIcon fas icon="plus-circle" color='light' size='sm' />
                            <span className='ms-1'>{translations.labels.addLanguage}</span>
                        </button>
                    </div>
                </>
            )}

        </>

    );
}

export default LanguageSkills;
