import React, { useState, useEffect } from "react";
import "./Accueil.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Parallax from "../../components/assets/Parallax";
import ShuffleTitle from "../../components/assets/ShuffleTitle";
import TypewriterTitle from "../../components/assets/TypewriterTitle";
import ImageSwitcher from "../../components/assets/ImageSwitcher";
import useAuth from "../../components/SSO/useAuth";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  getProfile,
  http,
  getUploadedFiles,
} from "../../components/services/httpService";
import { useDarkMode } from "../../components/assets/DarkModeContext";

export default function Accueil({ setUserRole }) {
  const [user, setUser] = useState({ nom: "", prenom: "", role: "" });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [activeTab, setActiveTab] = useState("temoignages");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { isDarkMode, toggleDarkMode, isTransitionDisabled } = useDarkMode();
  const [temoignages, setTemoignages] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const auth = useAuth();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const noTransitionClass = isTransitionDisabled ? "no-transition" : "";

  useEffect(() => {
    getProfile()
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
      })
      .catch((error) => {
        console.error(error);
        auth.logout();
      });
  }, []);

  const handleLogout = () => {
    auth.logout();
    console.log("Déconnexion");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwt_decode(token);
      if (decoded.exp < Date.now() / 1000) {
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        auth.displayLogin();
      } else {
        setIsAuthenticated(true);
      }
    }
  }, []);

  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);

  useEffect(() => {
    const targetCount1 = 50;
    const targetCount2 = 18;

    const duration = 3000; // Durée totale de l'animation en millisecondes
    const interval = 50; // Intervalle entre chaque mise à jour de nombre en millisecondes
    const steps = Math.ceil(duration / interval); // Nombre d'étapes de mise à jour

    const stepValue1 = Math.ceil(targetCount1 / steps);
    const stepValue2 = Math.ceil(targetCount2 / steps);

    let currentValue1 = 0;
    let currentValue2 = 0;

    const timeout = setTimeout(() => {
      const intervalId = setInterval(() => {
        currentValue1 += stepValue1;
        currentValue2 += stepValue2;

        if (currentValue1 >= targetCount1) {
          currentValue1 = targetCount1;
          clearInterval(intervalId);
        }

        if (currentValue2 >= targetCount2) {
          currentValue2 = targetCount2;
        }

        setCount1(currentValue1);
        setCount2(currentValue2);
      }, interval);
    }, interval);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  const [files, setFiles] = useState([]);

  useEffect(() => {
    getUploadedFiles()
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Erreur HTTP ${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setFiles(data);
        } else {
          console.warn("La réponse n'est pas un tableau:", data);
        }
      })
      .catch((error) =>
        console.error("Erreur lors de la récupération des fichiers :", error)
      );
  }, []);


  useEffect(() => {
    async function fetchData() {
      try {
        const response = await http('temoignages/all');
        if (!response.ok) {
          throw new Error(`Erreur HTTP ${response.status}: ${response.statusText}`);
        }
        const data = await response.json();
        setTemoignages(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    }

    fetchData();
  }, []);

  function formatPrenom(prenom) {
    if (!prenom) return "";

    return prenom
      .split(/[-\s]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(prenom.includes("-") ? "-" : " ");
  }

  return (
    <>
      <MDBContainer
        fluid
        className="d-flex justify-content-center fond-accueil-index align-items-center py-5"
      >
        {isAuthenticated ? (
          <div className="row align-items-center py-5 my-5 text-light">
            <div className="col-12 col-lg-6 animation-apparition-haut text-center text-lg-start mb-5">
              <p className="h5">
                Bonjour{" "}
                <span className="emoji-animation" role="img" aria-label="Hello">
                  <img
                    src="/hi.png"
                    alt="hi"
                    style={{
                      width: "20px",
                      filter: "invert(1)",
                    }}
                    loading="lazy"
                  />
                </span>
              </p>
              <h2 className="pt-3">
                {formatPrenom(user.prenom)}{" "}
                <span className="text-uppercase">{user.nom}</span>
              </h2>

              <p className="h5 py-3">
                Vous êtes connecté(e) à votre compte. <br />
                <br />
                Vous pouvez vous déconnecter <br />
                en utilisant le bouton <br />
                ci-dessous.
              </p>

              {/* <MDBBtn className="BtnCard" onClick={handleRedirect}>
                Accéder
              </MDBBtn> */}
              <MDBBtn className="BtnCardSecondary" onClick={handleLogout}>
                Se déconnecter
              </MDBBtn>
            </div>
            <div className="col-12 col-lg-6 text-md-center">
              {/* <Parallax speed={0.1}>
                <img
                  src="./bg/img-accueil-cv.png"
                  width={350}
                  alt="cv"
                  loading="lazy"
                  className="animation-apparition-gauche img-fluid pb-3"
                />
              </Parallax> */}
              <ImageSwitcher />
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-around align-items-center py-5">
            <div className="container">
              <MDBRow className="align-items-center justify-content-center">
                <MDBCol sm="6" className="animation-apparition-gauche">
                  <div className="py-5">
                    <div className="d-flex gap-2">
                      <TypewriterTitle />
                    </div>
                  </div>
                  <div className="text-light py-5 pb-5 mb-5">
                    <ShuffleTitle />
                  </div>
                  <div className="row text-center pt-5 mt-5">
                    <div className="col">
                      <h2
                        style={{ color: "#09B4BF", filter: "brightness(120%)" }}
                      >
                        {count1}
                      </h2>
                      <p className="text-white h5">Nombre de collaborateurs</p>
                    </div>
                    <div className="col">
                      <h2
                        style={{ color: "#09B4BF", filter: "brightness(120%)" }}
                      >
                        {count2}
                      </h2>
                      <p className="text-white h5">
                        Clients qui nous font confiance
                      </p>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            </div>
          </div>
        )}
      </MDBContainer>
      <div className={`divider ${isDarkMode ? "divider-dark" : ""}`}></div>
      <div className={
        isDarkMode ? "bg-divider-dark-mode" : "bg-white"
      }></div>
      {isAuthenticated ? (
        <div className={
          isDarkMode ? "bg-dark-mode" : "bg-white"
        }>
          <ul
            className={`nav nav-tabs mb-5 justify-content-center ${noTransitionClass}`}
            id="issuesTabs"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === "temoignages" ? "active" : ""
                  } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                id="temoignages-tab"
                onClick={() => handleTabChange("temoignages")}
              >
                Témoignages
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === "nosClients" ? "active" : ""
                  } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                id="clients-tab"
                onClick={() => handleTabChange("nosClients")}
              >
                Nos clients
              </button>
            </li>
          </ul>
          {activeTab === "nosClients" && (
            <div
              className={`text-center ${isDarkMode ? "bg-gradient-carousel" : ""}`}
              id="clients"
              role="tabpanel"
              aria-labelledby="clients-tab"
            >
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlay
                autoPlaySpeed={1000}
                centerMode={false}
                containerClass="container"
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 3,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 2,
                  },
                }}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
                className="py-5"
              >
                {files.map((file, idx) => (
                  <div key={idx}>
                    <img
                      className="img-fluid"
                      src={`${baseUrl}clients/upload/${file}`}
                      alt={file}
                      style={{ width: "100px" }}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          )}
          {activeTab === "temoignages" && (
            <div
              className="text-center pb-5"
              id="temoignages"
              role="tabpanel"
              aria-labelledby="temoignages-tab"
            >
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlay
                autoPlaySpeed={3000}
                centerMode={false}
                containerClass="container"
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 3,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 2,
                  },
                }}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {Array.isArray(temoignages) &&
                  temoignages.map((temoignage) => (
                    <div key={temoignage.id} style={{ color: "#757575" }}>
                      <img
                        className="rounded-circle shadow-1-strong mb-4"
                        src={
                          temoignage.photopath && temoignage.photopath.split("\\").length > 0
                            ? `${baseUrl}temoignages/image/${temoignage.photopath.split("\\").pop()}`
                            : './spinner-alcyone.png'
                        }
                        alt={temoignage.name}
                        style={{ width: "100px" }}
                      />
                      <h5 className="mb-3">{temoignage.name}</h5>
                      <p style={{ width: "425px" }}>
                        <i
                          className="fas fa-quote-left pe-2"
                          style={{ color: "#09B4BF" }}
                        ></i>
                        {temoignage.message}
                      </p>
                    </div>
                  ))}
              </Carousel>
            </div>
          )}
        </div>
      ) : (
        <div>
          {/* <div className="text-center pb-5"> */}
          <div
            className={`text-center py-5 ${isDarkMode ? "bg-dark-mode" : "bg-white"
              }`}
          >
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlay
              autoPlaySpeed={3000}
              centerMode={false}
              containerClass="container"
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 3,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 2,
                },
              }}
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {Array.isArray(temoignages) &&
                temoignages.map((temoignage) => (
                  <div key={temoignage.id} style={{ color: "#757575" }}>
                    <img
                      className="rounded-circle shadow-1-strong mb-4"
                      src={`${baseUrl}temoignages/image/${temoignage.photopath
                        .split("\\")
                        .pop()}`}
                      alt={temoignage.name}
                      style={{ width: "100px" }}
                    />
                    <h5 className="mb-3">{temoignage.name}</h5>
                    <p>
                      <i
                        className="fas fa-quote-left pe-2"
                        style={{ color: "#09B4BF" }}
                      ></i>
                      {temoignage.message}
                    </p>
                  </div>
                ))}

              <div style={{ color: "#757575" }}>
                <img
                  className="rounded-circle shadow-1-strong mb-4"
                  src="./spinner-alcyone.png"
                  alt="avatar"
                  style={{ width: "100px" }}
                />
                <h5 className="mb-3">Carla GROSSI</h5>
                <p>
                  <i
                    className="fas fa-quote-left pe-2"
                    style={{ color: "#09B4BF" }}
                  ></i>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod
                  id officiis hic tenetur quae quaerat ad velit.
                </p>
              </div>
              <div style={{ color: "#757575" }}>
                <img
                  className="rounded-circle shadow-1-strong mb-4"
                  src="./spinner-alcyone.png"
                  alt="avatar"
                  style={{ width: "100px" }}
                />
                <h5 className="mb-3">Xavier Aubert</h5>
                <p>
                  <i
                    className="fas fa-quote-left pe-2"
                    style={{ color: "#09B4BF" }}
                  ></i>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod
                  id officiis hic tenetur quae quaerat ad velit.
                </p>
              </div>
              <div style={{ color: "#757575" }}>
                <img
                  className="rounded-circle shadow-1-strong mb-4"
                  src="./spinner-alcyone.png"
                  alt="avatar"
                  style={{ width: "100px" }}
                />
                <h5 className="mb-3">Marine Zielowski</h5>
                <p>
                  <i
                    className="fas fa-quote-left pe-2"
                    style={{ color: "#09B4BF" }}
                  ></i>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod
                  id officiis hic tenetur quae quaerat ad velit.
                </p>
              </div>
            </Carousel>
          </div>
        </div>
      )}
    </>
  );
}
