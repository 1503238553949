import { languageMap } from "../../components/assets/languageMap";

const SelectLanguage = ({ id, selectedLanguage, handleLanguageChange, translations, className }) => {
  const languages = [
    translations.french,
    translations.english,
    translations.spanish,
    translations.luxembourgish,
    translations.chinese,
    translations.arabic,
    translations.portuguese,
    translations.russian,
    translations.german,
    translations.italien,
    translations.neerlandais,
    translations.danois,
    translations.grec,
    translations.polonais,
    translations.hindi,
    translations.turc
  ];

  const translate = (key) => {

    if (translations[key]) {
      return translations[key];
    }

    if (translations.labels && translations.labels[key]) {
      return translations.labels[key];
    }

    const translationKey = languageMap[key] || key.toLowerCase().replace(/\s+/g, '_');
    return translations[translationKey] || translations.labels[translationKey] || key;
  };

  return (
    <select id={id} className={`form-select select-input ${className}`} value={translate(selectedLanguage)} onChange={handleLanguageChange}>
      <option value="">{translations.labels.selectALanguage}</option>
      {languages.map((language, index) => {
        const key = language && typeof language === 'string' ? language + index : index;
        return <option value={language} key={key}>{language}</option>;
      })}
    </select>
  );
};

export default SelectLanguage;
