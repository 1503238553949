import React from "react";
import { Link } from "react-router-dom";
import "../../Pages/Accueil/Accueil.css";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useDarkMode } from '../../components/assets/DarkModeContext';

export default function Footer() {
  const { isDarkMode } = useDarkMode();

  return (
    <MDBFooter className={`text-center text-lg-start text-muted ${isDarkMode ? 'dark-mode-class' : 'light-mode-class'}`}
      style={{ backgroundColor: isDarkMode ? 'rgb(22,32,44)' : '#FFF' }}>

      <section className="d-flex justify-content-center align-items-center p-4 border-bottom animation-apparition-haut">
        <div className="me-5 d-none d-lg-block">
          <span>Rejoignez-nous sur LinkedIn :</span>
        </div>

        <div>
          <MDBBtn
            floating
            className="m-1"
            style={{ backgroundColor: "#09B4BF" }}
            href="https://www.linkedin.com/company/alcyone-consulting/"
            target="_blank"
            rel="noreferrer"
            role="button"
          >
            <MDBIcon fab icon="linkedin-in" />
          </MDBBtn>
        </div>
      </section>

      <section className="pb-5">
        <MDBContainer className="text-center text-md-start mt-5 animation-apparition-haut">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <img
                  src={isDarkMode ? "/logo_negatif.png" : "/logo-alcyone.png"}
                  height="75"
                  alt=""
                  loading="lazy"
                />
              </h6>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Liens Utiles</h6>
              <p>
                <a
                  href="https://alcyone-consulting.lu/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#757575" }}
                >
                  Notre Activité
                </a>
              </p>
              <Link to="/mentions-legales">
                <p style={{ color: "#757575" }}>
                  Mentions légales
                </p>
              </Link>
              <Link to="/politique">
                <p style={{ color: "#757575" }}>
                  Politique de confidentialité
                </p>
              </Link>
              <Link to="/cgu">
                <p style={{ color: "#757575" }}>
                  CGU
                </p>
              </Link>
              <Link to="/aide">
                <p style={{ color: "#757575" }}>
                  Contactez-nous
                </p>
              </Link>
            </MDBCol>

            <MDBCol md="4" lg="4" xl="4" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <a className="d-md-flex d-block mb-2" href="https://www.google.com/maps/search/?api=1&query=2-8,+rue+Julien+Vesque+L2668+LUXEMBOURG+VILLE" target="_blank" rel="noopener noreferrer">
                <MDBIcon
                  icon="home"
                  className="homeFooter m-1"
                  style={{ color: "#09B4BF" }}
                />
                <span style={{ color: "#757575" }}>2-8 rue Julien Vesque <br />
                  L-2668 LUXEMBOURG</span>
              </a>
              <a className="d-md-flex d-block mb-2" style={{ color: "#757575" }} href="mailto:contact@alcyone-consulting.lu">
                <MDBIcon
                  icon="envelope"
                  className="mailFooter m-1"
                  style={{ color: "#09B4BF" }}
                />
                contact@alcyone-consulting.lu
              </a>
              <a href="tel:+35227861746" style={{ color: "#757575" }} className="mb-2">
                <MDBIcon icon="phone" className="phoneFooter m-1" style={{ color: "#09B4BF" }} />+ 352 27 86 17
                46
              </a>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center text-light p-3"
        style={{ backgroundColor: "#09B4BF" }}
      >
        © 2020 Copyright :&nbsp;
        <a className="text-light" href="/mentions-legales">
          © Alcyone Consulting | Autorisation d’établissement 118 414 | RCS
          Luxembourg B 133 438 | Mentions légales
        </a>
      </div>
    </MDBFooter>
  );
}
