export const languageMap = {
    "Français": "french",
    "Anglais": "english",
    "Espagnol": "spanish",
    "Luxembourgeois": "luxembourgish",
    "Chinois": "chinese",
    "Arabe": "arabic",
    "Portugais": "portuguese",
    "Russe": "russian",
    "Allemand": "german",
    "Italien": "italian",
    "Néerlandais": "dutch",
    "Danois": "danish",
    "Grec": "greek",
    "Polonais": "polish",
    "Hindi": "hindi",
    "Langue maternelle": "motherTongue",
  };
