import React from 'react';
import {
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

function Footer({ isManagerRole, isCandidateRole, isClientRole, translations, currentUser, userData }) {
  return (
    <footer className="cv-footer" style={{ fontSize: "10px" }}>
      <MDBRow className="mx-5 pt-4">
        <MDBCol style={{marginTop: "33px"}}>
          <div className="manager-preview text-start text-white">
            {
              isManagerRole ? (
                <>
                  {/* <p className="fw-bold mt-2">{translations.inTouch}</p> */}
                  <p className="mb-0">
                    {currentUser?.prenom} {currentUser?.nom.toUpperCase()}
                  </p>
                  <p className="mb-0">{currentUser?.managerDetails?.poste}</p>
                  <p className="mb-0">{currentUser?.phone}</p>
                  <p className="mb-0">{currentUser?.email}</p>
                </>
              ) : isCandidateRole ? (
                <>
                  {/* <p className="fw-bold mt-2">{translations.inTouch}</p> */}
                  <p className="mb-0">
                    {userData?.manager?.prenom} {userData?.manager?.nom.toUpperCase()}
                  </p>
                  <p className="mb-0">{userData?.manager?.managerDetails?.poste}</p>
                  <p className="mb-0">{userData?.manager?.phone}</p>
                  <p className="mb-0">{userData?.manager?.email}</p>
                </>
              ) : isClientRole ? (
                <>
                  {/* <p className="fw-bold mt-2">{translations.inTouch}</p> */}
                  <p className="">+ 352 27 86 17 46</p>
                  <p className="">contact@alcyone-consulting.lu</p>
                </>
              ) : null
            }
          </div>
        </MDBCol>
        <MDBCol>
          <div className="d-flex gap-3 justify-content-end align-items-center">
            <div>
              <img
                src={"/logo-footer.png"}
                height="60"
                alt="Alcyone-logo"
                className="logo-footer"
                style={{marginTop: "20px"}}
              />
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </footer>
  );
}

export default Footer;
