import React, { useState } from 'react';
import { http } from '../services/httpService';
import { MDBBtn, MDBFile, MDBContainer } from 'mdb-react-ui-kit';
import { useCvData } from '../../Pages/EditionCv/CvDataContext';

function FileUploadForm({ onUploadData, userId, cvId }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const { dispatch } = useCvData();

    const token = localStorage.getItem("token")

    const uploadFile = async (file, extractionType) => {
        setLoading(true);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userId);
        formData.append('cvId', cvId);

        // const endpointMap = {
        //     presentation: 'api/v1/extractor',
        //     experiences: 'api/v1/extractor/extract-experiences',
        //     formations: 'api/v1/extractor/extract-formations',
        //     loisirs: 'api/v1/extractor/extract-loisirs'
        // };



        try {
            const response = await http('api/v1/extractor', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                onUploadData(extractionType, data);
            } else {
                console.log(`Error to upload fichier ${extractionType}:`, response.statusText);
            }
        } catch (error) {
            console.error(`Exception to upload fichier for ${extractionType}:`, error);
        }
    };

    const fetchCvData = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await http(`user/find-with-cvs/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
 
            if (!response.ok) {
                throw new Error(`Network response was not ok, status: ${response.status}`);
            }
 
            const data = await response.json();
            const user = data;
            const cv = user.cvs.find(cv => cv.id === parseInt(cvId));
 
            dispatch({
                type: 'SET_CVDATA',
                payload: cv || {},
            });
 
        } catch (error) {
            console.error(`Error fetching CV data: ${error}`);
        }
        finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (selectedFile) {
            await uploadFile(selectedFile);
            // await uploadFile(selectedFile, 'experiences');
            // await uploadFile(selectedFile, 'formations');
            // await uploadFile(selectedFile, 'loisirs');
            await fetchCvData();
        }
    };

    const handleFileChange = event => {
        setSelectedFile(event.target.files[0]);
    };

    return (
        <MDBContainer className=''>
            <div className=''>
                <form onSubmit={handleSubmit} className='d-flex justify-content-center gap-1'>
                    <div>
                        <MDBFile onChange={handleFileChange} />
                    </div>
                    <div>
                        <MDBBtn className='mb-2 btn btn-secondary text-light' type="submit" style={{ backgroundColor: "#09B4BF" }}>
                            Extraire des données
                        </MDBBtn>
                    </div>
                </form>
                {loading && ( // Affichage conditionnel du spinner
                    <div className="text-center mt-3">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}
            </div>
        </MDBContainer>
    );
}

export default FileUploadForm;
