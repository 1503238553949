import { useState } from 'react';

export const useTranslations = () => {
    const [translations, setTranslations] = useState({
        cvPreview: "CV Preview",
        businessSkills: "Compétences & Certifications",
        languages: "Langues",
        experiences: "Expériences",
        formations: "Formations",
        hobbies: "Loisirs",
        duplicate: "Dupliquer",
        download: "Télécharger",
        share: "Partager",
        cvTitle: "CV",
        labels: {
            jobTitle: "Intitulé de poste",
            cvName: "Nom du CV",
            presentation: "Présentation en quelques lignes",
            company: "Employeur",
            sector: "Secteur d'activité",
            location: "Lieu",
            startDate: "Date de début",
            endDate: "Date de fin",
            position: "Poste",
            mission: "en mission chez",
            contractType: "Type de Contrat",
            context: "Contexte",
            realisation: "Réussites",
            responsibilities: "Actions",
            techEnvironment: "Environnement technique",
            skillsOptions: "Avez-vous oublié d'inclure une compétence ? Trouvez-la ici :",
            skillsRecherche: "Recherche une compétence",
            school: "Ecole",
            level: "Niveau",
            title: "Intitulé",
            now: "Maintenant",
            ongoing: "En cours",
            understand: "Comprendre",
            express: "S'exprimer",
            motherTongue: "Langue maternelle",
            selectALanguage: "Sélectionnez une langue",
            addLanguage: "Ajouter langue",
            addCategory: "Ajouter catégorie",
            addSkill: "Ajouter compétence",
            addAction: "Ajouter action",
            speak: "Oral",
            write: "Écrit"
        },
        contractTypes: {
            CDI: "CDI",
            CDD: "CDD",
            Stage: "Stage",
            Alternance: "Alternance",
            Freelance: "Freelance",
        },
        educationLevels: {
            BAC: "Bac",
            BAC1: "Bac +1",
            BAC2: "Bac +2 DUT / BTS",
            BAC3: "Bac +3 Licence / Bachelor",
            BAC4: "Bac +4 Bachelor / Maitrise",
            BAC5: "Bac +5 Master",
            BAC67: "Bac +6/+7 Master spécialisé",
            BAC8: "Bac +8 et plus Doctorat",
        },
        sectorActivite: {
            Agroalimentaire: "Agroalimentaire",
            Aeronautique: "Aéronautique & Aérospatial",
            ArtsSpectacle: "Arts & Spectacle",
            Assurance: "Assurance",
            Automobile: "Automobile",
            Banque: "Banque & Gestion de Fortune",
            BTP: "BTP",
            Distribution: "Distribution",
            Energies: "Énergies",
            Enseignement: "Enseignement & Formation",
            Etudes: "Études & conseils",
            Services: "Services Financiers",
            Immobilier: "Immobilier",
            Pharmaceutique: "Industrie pharmaceutique",
            Informatiques: "Services Informatiques",
            InstitutionsEuropeennes: "Institutions Européennes",
            Logistique: "Logistique",
            Industries: "Industries",
            Sante: "Santé",
            SecteurPublic: "Secteur public",
            Restauration: "Hôtellerie & Restauration",
            Tourisme: "Tourisme",
            Transport: "Transport de passagers",
        },
        myCvSpace: "Mon espace CV",
        makeMyCvAnonymous: "Rendre mon CV anonyme",
        makeExpCacher: "Cacher l'expérience professionnelle",
        selectAnOption: "Sélectionnez une option",
        confirm: "Sauvegarder en brouillon",
        selectACategory: "Sélectionnez une catégorie",
        selectASkill: "Choisir une compétence",
        workExperience: "Expérience professionnelle : {experienceYears} an(s) et {experienceMonths} mois",
        informManager: "Sauvegarder et informer le Manager que mon CV est prêt",
        cvLanguage: "Langue de CV",
        french: "Français",
        english: "Anglais",
        spanish: "Espagnol",
        luxembourgish: "Luxembourgeois",
        chinese: "Chinois",
        arabic: "Arabe",
        portuguese: "Portugais",
        russian: "Russe",
        german: "Allemand",
        italien: "Italien",
        neerlandais: "Néerlandais",
        danois: "Danois",
        grec: "Grec",
        polonais: "Polonais",
        hindi: "Hindi",
        turc: "Turc",
        preview: "Prévisualiser",
        inTouch: "Restons en contact",
        years: "ans",
        months: "mois",
        and: 'et',
        titreSkills: "Catégorie compétences",
        search: "Recherche avancée",
        searchBy: "Recherche par",
        filterBy: "Filtrer par",
        levelExp: "Niveau d'expérience",
        levelForm: "Niveau de formation",
        countryCo: "Pays de rattachement",
        metier: "Métier",
        role: "Rôle",
        suisse: "Suisse",
        belgium: "Belgique",
        criteria: "Plus de critères",
        result: "Résultat",
        clear: "Tout effacer",
        trouver: "Vous ne trouvez pas une compétence ?",
        traiter: "Un manager va traiter votre demande.",
        infobulle: {
            rechercheAvance: "Aucun résultat trouvé pour les critères de recherche spécifiés.",
            expContext: "Contexte dans lequel vous avez travaillé",
            expAchieve: "Précisez les résultats positifs que vous avez obtenus.",
            expActions: "Énumérez les tâches, actions, responsabilités quotidiennes.",
            expEnv: "Listez vos compétences techniques que vous avez utilisés.",
        },
    });

    const updateTranslations = (language) => {
        if (language === "EN") {
            setTranslations({
                cvPreview: "CV Preview",
                businessSkills: "Skills and certifications",
                languages: "Languages",
                experiences: "Experiences",
                formations: "Education",
                hobbies: "Hobbies",
                duplicate: "Duplicate",
                download: "Download",
                share: "Share",
                cvTitle: "Resume",
                labels: {
                    jobTitle: "Job Title",
                    cvName: "CV Name",
                    presentation: "Brief presentation",
                    company: "Employer",
                    sector: "Industry sector",
                    location: "Location",
                    startDate: "Start date",
                    endDate: "End date",
                    position: "Position",
                    mission: "assignment at",
                    contractType: "Contract type",
                    context: "Context",
                    realisation: "Achievements",
                    responsibilities: "Actions",
                    techEnvironment: "Technical environment",
                    skillsOptions: "Did you forget to include a skill? Find it here:",
                    skillsRecherche: "Search for a skill",
                    school: "School",
                    level: "Level",
                    title: "Title",
                    now: "Now",
                    ongoing: "Ongoing",
                    motherTongue: "Mother tongue",
                    selectALanguage: "Select a language",
                    addLanguage: "Add language",
                    addCategory: "Add catégory",
                    addSkill: "Add skill",
                    addAction: "Add action",
                    understand: "Understanding",
                    express: "Expression",
                    speak: "Speaking",
                    write: "Writing"
                },
                contractTypes: {
                    CDI: "Permanent contract",
                    CDD: "Fixed-term contract",
                    Stage: "Internship",
                    Alternance: "Apprenticeship",
                    Freelance: "Freelance",
                },
                educationLevels: {
                    BAC: "A Level",
                    BAC1: "First year university level",
                    BAC2: "BTEC",
                    BAC3: " Bachelor",
                    BAC4: "MA / MS / Master’s degree",
                    BAC5: "MA / MS / Master’s degree",
                    BAC67: "Advanced Master",
                    BAC8: "PhD",
                },
                sectorActivite: {
                    Agroalimentaire: "Food Industry",
                    Aeronautique: "Aeronautics & Aerospace",
                    ArtsSpectacle: "Arts/Entertainment",
                    Assurance: "Insurance",
                    Automobile: "Automotive",
                    Banque: "Banking & Wealth Management",
                    BTP: "Construction",
                    Distribution: "Retail",
                    Energies: "Energy",
                    Enseignement: "Education & Training",
                    Etudes: "Research and Advisory",
                    Services: "Financial services",
                    Immobilier: "Real estate",
                    Pharmaceutique: "Pharmaceutical industry",
                    Informatiques: "IT services",
                    InstitutionsEuropeennes: "European institutions",
                    Logistique: "Logistics & Supply Chain",
                    Industries: "Industries",
                    Sante: "Healthcare",
                    SecteurPublic: "Public Sector & Utilities",
                    Restauration: "Hotel & Catering",
                    Tourisme: "Tourism",
                    Transport: "Transportation",
                },
                myCvSpace: "My CV space",
                makeMyCvAnonymous: "Make my CV anonymous",
                makeExpCacher: "Hide professional experience",
                selectAnOption: "Select an option",
                confirm: "Save as draft",
                selectACategory: "Select a category",
                selectASkill: "Select a skill",
                workExperience: "Professional experience: {experienceYears} year(s) and {experienceMonths} month(s)",
                informManager: "Save and notify the manager my resume is ready",
                cvLanguage: "CV language",
                french: "French",
                english: "English",
                spanish: "Spanish",
                luxembourgish: "Luxembourgish",
                chinese: "Chinese",
                arabic: "Arabic",
                portuguese: "Portuguese",
                russian: "Russian",
                german: "German",
                italien: "Italian",
                neerlandais: "Dutch",
                danois: "Danish",
                grec: "Greek",
                polonais: "Polish",
                hindi: "Hindi",
                turc: "Turkish",
                preview: "Preview",
                inTouch: "Let's stay in touch",
                years: 'years',
                months: 'months',
                and: 'and',
                titreSkills: "Skills Category",
                search: "Advanced Search",
                searchBy: "Search by",
                filterBy: "Filter by",
                levelExp: "Experience level",
                levelForm: "Formation level",
                countryCo: "Country Connection",
                metier: "Business",
                role: "Role",
                suisse: "Switzerland",
                belgium: "Belgium",
                criteria: "Plus criteria",
                result: "Result",
                clear: "Clear search",
                trouver: "Can't find a skill?",
                traiter: "A manager will process your request",
                infobulle: {
                    rechercheAvance: "No results found for the specified search criteria.",
                    expContext: "Context in which you worked.",
                    expAchieve: "Specify the positive results you achieved",
                    expActions: "List the tasks, actions, daily responsibilities.",
                    expEnv: "List the technical skills you used.",
                },
            });
        } else if (language === "FR") {
            setTranslations({
                cvPreview: "Aperçu du CV",
                businessSkills: "Compétences & certifications",
                languages: "Langues",
                experiences: "Expériences",
                formations: "Formations",
                hobbies: "Loisirs",
                duplicate: "Dupliquer",
                download: "Télécharger",
                share: "Partager",
                cvTitle: "CV",
                labels: {
                    jobTitle: "Intitulé de poste",
                    cvName: "Nom du CV",
                    presentation: "Présentation en quelques lignes",
                    company: "Employeur",
                    sector: "Secteur d'activité",
                    location: "Lieu",
                    startDate: "Date de début",
                    endDate: "Date de fin",
                    position: "Poste",
                    mission: "en mission chez",
                    contractType: "Type de contrat",
                    context: "Contexte",
                    realisation: "Réussites",
                    responsibilities: "Actions",
                    techEnvironment: "Environnement technique",
                    skillsOptions: "Avez-vous oublié d'inclure une compétence ? Trouvez-la ici :",
                    skillsRecherche: "Recherche une compétence",
                    school: "Ecole",
                    level: "Niveau",
                    title: "Intitulé",
                    now: "Maintenant",
                    ongoing: "En cours",
                    motherTongue: "Langue maternelle",
                    selectALanguage: "Sélectionnez une langue",
                    addLanguage: "Ajouter langue",
                    addCategory: "Ajouter catégorie",
                    addSkill: "Ajouter compétence",
                    addAction: "Ajouter action",
                    understand: "Comprendre",
                    express: "S'exprimer",
                    speak: "Oral",
                    write: "Écrit"
                },
                contractTypes: {
                    CDI: "CDI",
                    CDD: "CDD",
                    Stage: "Stage",
                    Alternance: "Alternance",
                    Freelance: "Freelance",
                },
                educationLevels: {
                    BAC: "Bac",
                    BAC1: "Bac +1",
                    BAC2: "Bac +2 DUT / BTS",
                    BAC3: "Bac +3 Licence / Bachelor",
                    BAC4: "Bac +4 Bachelor / Maitrise",
                    BAC5: "Bac +5 Master",
                    BAC67: "Bac +6/+7 Master spécialisé",
                    BAC8: "Bac +8 et plus Doctorat",
                },
                sectorActivite: {
                    Agroalimentaire: "Agroalimentaire",
                    Aeronautique: "Aéronautique & Aérospatial",
                    ArtsSpectacle: "Arts & Spectacle",
                    Assurance: "Assurance",
                    Automobile: "Automobile",
                    Banque: "Banque & Gestion de Fortune",
                    BTP: "BTP",
                    Distribution: "Distribution",
                    Energies: "Énergies",
                    Enseignement: "Enseignement & Formation",
                    Etudes: "Études & conseils",
                    Services: "Services Financiers",
                    Immobilier: "Immobilier",
                    Pharmaceutique: "Industrie pharmaceutique",
                    Informatiques: "Services Informatiques",
                    InstitutionsEuropeennes: "Institutions Européennes",
                    Logistique: "Logistique",
                    Industries: "Industries",
                    Sante: "Santé",
                    SecteurPublic: "Secteur public",
                    Restauration: "Hôtellerie & Restauration",
                    Tourisme: "Tourisme",
                    Transport: "Transport de passagers",
                },
                myCvSpace: "Mon espace CV",
                makeMyCvAnonymous: "Rendre mon CV anonyme",
                makeExpCacher: "Cacher l'expérience professionnelle",
                selectAnOption: "Sélectionnez une option",
                confirm: "Sauvegarder en brouillon",
                selectACategory: "Sélectionnez une catégorie",
                selectASkill: "Choisir une compétence",
                workExperience: "Expérience professionnelle : {experienceYears} an(s) et {experienceMonths} mois",
                informManager: "Sauvegarder et informer le Manager que mon CV est prêt",
                cvLanguage: "Langue de CV",
                french: "Français",
                english: "Anglais",
                spanish: "Espagnol",
                luxembourgish: "Luxembourgeois",
                chinese: "Chinois",
                arabic: "Arabe",
                portuguese: "Portugais",
                russian: "Russe",
                german: "Allemand",
                italien: "Italien",
                neerlandais: "Néerlandais",
                danois: "Danois",
                grec: "Grec",
                polonais: "Polonais",
                hindi: "Hindi",
                turc: "Turc",
                preview: "Prévisualiser",
                inTouch: "Restons en contact",
                years: 'ans',
                months: 'mois',
                and: 'et',
                titreSkills: "Catégorie compétences",
                search: "Recherche avancée",
                searchBy: "Recherche par",
                filterBy: "Filtrer par",
                levelExp: "Niveau de expérience",
                levelForm: "Niveau de formation",
                countryCo: "Pays de rattachement",
                metier: "Métier",
                role: "Rôle",
                suisse: "Suisse",
                belgium: "Belgique",
                criteria: "Plus de critères",
                result: "Résultat",
                clear: "Tout effacer",
                trouver: "Vous ne trouvez pas une compétence ?",
                traiter: "Un manager va traiter votre demande.",
                infobulle: {
                    rechercheAvance: "Aucun résultat trouvé pour les critères de recherche spécifiés.",
                    expContext: "Contexte dans lequel vous avez travaillé",
                    expAchieve: "Précisez les résultats positifs que vous avez obtenus.",
                    expActions: "Énumérez les tâches, actions, responsabilités quotidiennes.",
                    expEnv: "Listez vos compétences techniques que vous avez utilisés.",
                },
            });
        }
    };

    return {
        translations,
        updateTranslations
    };
};
