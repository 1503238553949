import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function CircularProgressBar() {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1);
      }
    }, 50);
  }, [percentage]);

  return (

    <div className="d-flex flex-md-column flex-sm-row justify-content-sm-around">
      <div style={{ width: 150, margin: 'auto' }}>
        <CircularProgressbar value={percentage} text={`${percentage}%`} />
        <p className='text-center text-muted'>Savoir faire</p>
      </div>
      <div style={{ width: 150, margin: 'auto' }}>
        <CircularProgressbar value={percentage} text={`${percentage}%`} />
        <p className='text-center text-muted'>Savoir être</p>
      </div>
    </div>

  );
}
export default CircularProgressBar;