import React, { useEffect, useRef } from 'react';

function Parallax({ children, speed = 0.5 }) {
    const ref = useRef();

    useEffect(() => {
        const handleScroll = () => {
            if (!ref.current) return;
            
            const scrollTop = window.pageYOffset;
            const parallaxOffset = scrollTop * speed;
            ref.current.style.transform = `translateY(${parallaxOffset}px)`;
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [speed]);

    return (
        <div ref={ref}>
            {children}
        </div>
    );
}

export default Parallax;
