import React, { useEffect, useState } from 'react';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import useAuth from './useAuth';
import Loader from "../assets/Loader";

export const AuthGuard = ({ children }) => {
    const auth = useAuth();
    const history = useHistory();
    const location = useLocation();
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {

            if (location.pathname.startsWith('/create-password/') || location.pathname.startsWith('/inscription')) {
                setIsCheckingAuth(false);
                return;
            }

            
            if (location.pathname.startsWith('/inscription')) {
                setIsCheckingAuth(false);
                return;
            }

            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const existingToken = localStorage.getItem('token');

            if (!existingToken && code) {
                await auth.retrieveToken(code);
                    history.replace('/');
            } else if (!existingToken) {
                auth.displayLogin();
                return;
            }

            setIsCheckingAuth(false);
        };

        checkAuth();
    }, [auth, history, location]);

    if (isCheckingAuth) {
        return <Loader />;
    }

    return auth.isAuthenticated() ? children : null;
};

export default AuthGuard;
