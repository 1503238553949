import React, { useState, useEffect } from "react";

import {
    MDBContainer,
    MDBIcon,
    MDBCard
} from "mdb-react-ui-kit";
import { useDarkMode } from '../../components/assets/DarkModeContext';

export default function Mentions() {
    const { isDarkMode } = useDarkMode();

    return (
        <MDBContainer fluid className={isDarkMode ? 'bg-dark-mode' : 'bg-light'}>
            <MDBContainer className="pb-5">
                <section className="text-center text-md-start bg-nav animation-fade-in">
                    <div
                        className="p-5 bg-image"
                        style={{
                            height: "300px",
                            backgroundImage: 'url("./bg/legal.jpg")',
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "bottom",
                            backgroundAttachment: "fixed",
                        }}
                    ></div>
                    <MDBCard className="h-100 p-5" alignment="center">
                        <MDBIcon fas icon="balance-scale" size="3x" style={{ color: "#09B4BF" }} />
                        <h1 className="text-muted pt-2">
                            POLITIQUE DE CONFIDENTIALITE
                        </h1>
                        <div className="pt-3">
                            <p className="text-start fw-bold">Introduction</p>
                            <p className="text-start">Alcyone Consulting (ci-dessous « Société ») propose une large gamme de services auprès de clients grands comptes (banques, compagnies d’assurance, …) dans la conception, le déploiement et la maintenance de leurs systèmes d’information (I.T) et / ou de gestion du risque (Compliance), en mettant à leur disposition le capital humain adapté sur les lignes de métiers suivantes (ci-dessous « services »), tels que, sans que la liste ci-dessous soit limitative :</p>
                            <p className="text-start">Société propriétaire du Site :</p>
                            <ul className="text-start">
                                <li>
                                    Gouvernance des systèmes d’information
                                </li>
                                <li>
                                    Expertise Technique / IT
                                </li>
                                <li>
                                    Compliance et Audit
                                </li>
                            </ul>
                            <p className="text-start">Devant le développement des nouveaux outils de communication et informatiques, nous estimons nécessaire de porter la plus grande attention à la protection de la vie privée.</p>
                            <p className="text-start fw-bold">Les informations personnelles que nous recueillons</p>
                            <p className="text-start">Nous pouvons être amenés à recueillir vos informations personnelles pour différentes raisons : fourniture de nos services, obligations professionnelles, exécution d’un contrat, traitement de vos dossiers, de votre correspondance ou de votre candidature à un poste, etc.</p>
                            <p className="text-start">Nous traitons notamment les données suivantes :</p>

                            <ul className="text-start">
                                <li>
                                    Alcyone Consulting
                                </li>
                            </ul>
                            <p className="text-start">- Hébergeur :</p>
                            <ul className="text-start">
                                <li>
                                    Données de facturation, données commerciales, données de fournisseurs
                                </li>
                                <li>
                                    Identité, adresses, coordonnées personnelles et professionnelles, état-civil,
                                </li>
                                <li>
                                    Professions, diplômes, autorisations, certifications, catégorie économique, activités
                                </li>
                                <li>
                                    Comptes et relevés bancaires
                                </li>
                                <li>
                                    Revenus, salaires, matricules, données de pointage, temps de travail, primes, commissions, frais de déplacement, avantages en nature
                                </li>
                                <li>
                                    Cartes d'identité, passeports, extraits de casier judiciaire (si autorisés)
                                </li>
                                <li>
                                    Informations relatives aux absences, congés, maladies de longue durée
                                </li>
                                <li>
                                    Informations de saisie sur salaire et de cession
                                </li>
                                <li>
                                    Embauche et recrutement : date, objet, situation professionnelle, diplômes, autorisations, certifications, simulation de carrière, évaluation professionnelle, etc.
                                </li>
                                <li>
                                    Photographies à des fins d'identification ou marketing
                                </li>
                            </ul>
                            <p className="text-start">Nous pouvons combiner les informations que nous recueillons auprès de vous avec les données obtenues d'autres sources, afin de les compléter pour fournir nos services et personnaliser nos interactions avec vous.</p>
                            <p className="text-start">De plus, nous recueillons des informations liées à votre utilisation de notre site Web à l'aide de différentes technologies, comme les cookies.</p>
                            <p className="text-start fw-bold">Comment nous utilisons vos données personnelles ?</p>
                            <p className="text-start">
                                Les données à caractère personnel, communiquées par vous-même ou par l’entreprise qui vous emploie, ou reçues légitimement par notre Société, par des entreprises en relation avec celle-ci ou par des tiers, peuvent être traitées par notre Société en vue de :
                            </p>
                            <ul className="text-start">
                                <li>
                                    Fournir les services
                                </li>
                                <li>
                                    Activer les services
                                </li>
                                <li>
                                    Améliorer les services
                                </li>
                                <li>
                                    Assurer le fonctionnement de l’activité et les performances
                                </li>
                                <li>
                                    Gérer les contrats
                                </li>
                                <li>
                                    Assurer le service à la clientèle
                                </li>
                                <li>
                                    Gérer la relation commerciale et mettre à jour le fichier client
                                </li>
                                <li>
                                    Détecter, prévenir et lutter contre la fraude
                                </li>
                                <li>
                                    Etablir des statistiques
                                </li>
                                <li>
                                    Gérer les litiges et recouvrir les créances
                                </li>
                                <li>
                                    Régler des prestations.
                                </li>
                            </ul>
                            <p className="text-start">
                                Les données personnelles fournies à notre Société par des candidats en réponse à nos annonces ciblées, lors de candidatures spontanées ou présentes dans nos bases de réserve de recrutement avec l’accord préalable des candidats peuvent être communiquées à nos clients et prospects dans le cadre d’actions commerciales.
                            </p>
                            <p className="text-start">
                                Les autres données personnelles communiquées peuvent être traitées par notre Société à des fins de marketing direct (actions commerciales, publicités personnalisées, couplage de données, notoriété), en vue d'améliorer la connaissance de nos clients et prospects et pour les informer à propos de nos activités, produits et services.
                            </p>
                            <p className="text-start fw-bold">Comment nous partageons vos données personnelles ?</p>
                            <p className="text-start">
                                Nous partageons vos données personnelles avec votre accord ou si celles-ci sont nécessaires pour effectuer une transaction, pour fournir un produit ou un service que vous avez demandé ou autorisé, ou pour des satisfaire à des obligations légales.
                                Vos données personnelles peuvent être communiquées à des prestataires travaillant en notre nom, intervenant en qualité de sous-traitants au bénéfice de la Société et qui peuvent avoir besoin d’accéder à des données personnelles afin de fournir un soutien au service à la clientèle ou aider à protéger et sécuriser nos systèmes et nos services. Dans de tels cas, ces sociétés doivent se conformer à nos exigences en matière de confidentialité des données et de sécurité, et ne sont pas autorisées à utiliser les données personnelles que nous leur transmettons pour d’autres finalités.
                                Nous partageons également vos données lorsque cela est exigé par la loi, pour répondre à une procédure judiciaire, pour répondre à nos obligations vis-à-vis des autorités de contrôle, pour protéger nos clients, pour maintenir la sécurité de nos services, pour protéger les droits ou la propriété de la Société, pour la gestion des contentieux et des recouvrements de créances, ainsi que pour le règlement des prestations.
                            </p>
                            <p className="text-start fw-bold">Transfert des données hors de l’Union Européenne</p>
                            <p className="text-start">
                                Le cas échéant, les entreprises et/ou les personnes en relation avec la Société à laquelle les données personnelles sont communiquées, peuvent être situées dans l’Union Européenne ou en dehors. En cas de transferts de données à des tiers situés en dehors de l’Union Européenne, la Société se conforme aux dispositions légales et réglementaires en vigueur en matière de tels transferts et, notamment, assure un niveau de protection adéquat aux données personnelles ainsi transférées, sur base des mécanismes alternatifs mis en place par la Commission européenne ou autres dispositions contractuelles.
                            </p>
                            <p className="text-start fw-bold">Comment nous protégeons vos données personnelles ?</p>
                            <p className="text-start">
                                Les renseignements personnels que nous collectons sont conservés dans un environnement sécurisé.
                                Les personnes travaillant pour nous sont formées afin de respecter la confidentialité de vos informations.
                                Pour assurer la sécurité de vos données personnelles, nous les limitons au strict minimum et nous avons notamment recours aux mesures suivantes :
                            </p>
                            <ul className="text-start">
                                <li>
                                    Gestion des accès et des habilitations des personnes autorisées et des personnes concernées</li>
                                <li>
                                    Sécurisation des postes de travail : anti-spam, anti-virus, détection de fuites</li>
                                <li>
                                    Sécurisation de l’informatique mobile et des téléphones</li>
                                <li>
                                    Protection du réseau informatique et des serveurs : chiffrement, sauvegardes, gestion des flux, pare-feu (Firewalls)</li>
                                <li>
                                    Plan de continuité des activités
                                </li>
                                <li>
                                    Encadrement des développements
                                </li>
                                <li>
                                    Protection des locaux : détection d’incendie, système anti-intrusion, caméras de surveillance</li>
                                <li>
                                    Echanges sécurisés des informations</li>
                            </ul>
                            <p className="text-start">
                                Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières innovations technologiques permettant d'assurer la confidentialité de vos transactions.
                            </p>
                            <p className="text-start fw-bold">Conservation de vos données personnelles </p>
                            <p className="text-start">
                                Nous conservons vos données personnelles aussi longtemps que nécessaire pour fournir les services et réaliser les transactions que vous avez demandées, ou à d’autres fins essentielles telles que se conformer à nos obligations légales, résoudre les litiges, et appliquer nos accords. Dans la mesure où ces besoins peuvent varier selon les types de données dans le contexte des différents produits, les périodes réelles de rétention peuvent varier considérablement.
                                Les critères pris en compte pour déterminer les périodes de rétention sont notamment les suivants :
                            </p>
                            <ul className="text-start">
                                <li>
                                    Nécessité de conservation pour fournir les services demandés</li>
                                <li>
                                    Sensibilité des données recueillies ou confiées</li>
                                <li>
                                    Consentement particulier ou limitation convenue</li>
                                <li>
                                    Obligation légale ou contractuelle
                                </li>
                                <li>
                                    Politique de rétention adoptée par la Société en fonction de ses intérêts légitimes
                                </li>
                            </ul>
                            <p className="text-start">
                                Ces durées de rétention peuvent être :
                            </p>
                            <p className="text-start">
                                -	10 ans pour les données relatives à la comptabilité, à la gestion commerciale, aux registres d’achat et de vente, aux biens immobiliers, aux baux commerciaux
                            </p>
                            <p className="text-start">
                                -	5 ans pour les documents généraux de société : résolutions d'actionnaires, délibérations des conseils, procès-verbaux d'assemblées, statuts constitutifs, registres d'actionnaires, règlements intérieurs, documents d'avocats                            </p>
                            <p className="text-start">
                                -	Jusqu’à 10 ans pour les documents relatifs aux ressources humaines, contrats de travail et salaires: fiches de paie, salaires, impôts, sécurité sociale, heures supplémentaires, primes, avantages en nature, indemnités de départ, préavis, accord des autorités compétentes, décisions des juridictions de travail, correspondances, indemnités de licenciement, déclarations à la CCSS et ACD
                            </p>
                            <p className="text-start">
                                -	3 ans pour les données relatives aux accidents de travail, aux conditions de travail, inspection du travail, comité d'hygiène et de sécurité                            </p>
                            <p className="text-start">
                                -	3 ans pour les données nécessaires aux soins médicaux d'urgence, accords individuels de traitement, degré d'incapacité de travail, plans de réinsertion sociale, aménagement sur le lieu de travail, déclarations à la CNS                            </p>
                            <p className="text-start">
                                -	Pour les données transmises par un candidat :</p>
                            <p className="text-start">
                                o	1 mois après la fin de la procédure de recrutement et/ou de l’offre de mission à un client/ prospect</p>
                            <p className="text-start">
                                o	18 mois lorsque le candidat a accepté de figurer dans notre base de réserve de recrutement</p>
                            <p className="text-start fw-bold">
                                Droit d’accès, de rectification, de limitation et d’opposition</p>
                            <p className="text-start ">La Société adhère au Règlement Général sur la Protection des Données à caractère personnel EU/2016/679 en matière de protection des données et vous garantit les droits suivants :</p>
                            <ul className="text-start">
                                <li>
                                    Si le traitement des données à caractère personnel est fondé sur votre consentement, vous avez le droit de retirer votre consentement à tout moment</li>
                                <li>
                                    Vous pouvez consulter vos données personnelles, les faire rectifier et les limiter</li>
                                <li>
                                    Vous avez le droit de vous opposer au traitement de vos données personnelles</li>
                                <li>
                                    Vous avez le droit de déposer une plainte auprès d’une autorité chargée de la protection des données.</li>
                            </ul>
                            <p className="text-start fw-bold">Comment nous contacter ?</p>
                            <p className="text-start">
                                Pour toute question concernant la confidentialité de vos données, plainte ou question relative à vos droits, vous pouvez adresser votre demande datée et signée accompagnée d'une photocopie recto verso de votre carte d'identité à :                            </p>
                            <p className="text-start text-decoration-underline">
                                Par courrier postal à la Société</p>
                            <p className="text-start">
                                2-8, rue Julien Vesque – L-2668 LUXEMBOURG</p>
                            <p className="text-start text-decoration-underline">Par courrier électronique à la Société</p>
                            <p className="text-start">
                                dpo@alcyone-consulting.lu
                            </p>
                            <p className="text-start text-decoration-underline">
                                Par téléphone à la Société</p>
                            <p className="text-start">
                                (+352) 27 86 17 46</p>
                            <p className="text-start text-decoration-underline">
                                A la Commission Nationale pour la Protection des Données (CNPD)</p>
                            <p className="text-start">
                                15, Boulevard du Jazz - L-4370 Belvaux - Tél. : (+352) 26 10 60 -1 – info@cnpd.lu</p>
                        </div>
                    </MDBCard>
                </section>
            </MDBContainer>
        </MDBContainer>
    );
}
