import { languageMap } from "../../components/assets/languageMap";

const SelectLevelLanguage = ({ id, selectedValue, handleChange, translations }) => {
  const levels = [
    translations.labels.motherTongue,
    "A1",
    "A2",
    "B1",
    "B2",
    "C1",
    "C2"
  ];

  const translate = (key) => {
    if (translations[key]) {
      return translations[key];
    }

    if (translations.labels && translations.labels[key]) {
      return translations.labels[key];
    }

    const translationKey = languageMap[key] || key.toLowerCase().replace(/\s+/g, '_');
    return translations[translationKey] || translations.labels[translationKey] || key;
  };

  return (
    <select id={id} className="form-select select-input" value={translate(selectedValue)} onChange={handleChange}>
      <option value="">{translations.labels.level}</option>
      {levels.map((level) => (
        <option value={level} key={level}>{level}</option>
      ))}
    </select>
  );
};

export default SelectLevelLanguage;
