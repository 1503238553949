import React, { useState, useEffect } from "react";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

function TestsCompliance() {
  const questions = [
    {
      questionText: "Lorem ipsum ?",
      image: "/Schéma 1.jpg",
      answerOptions: [
        {
          answerText: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
          isCorrect: false,
        },
        {
          answerText: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
          isCorrect: false,
        },
        {
          answerText: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
          isCorrect: true,
        },
        {
          answerText: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
          isCorrect: false,
        },
      ],
    },
    {
      questionText: "Lorem ipsum ?",
      image: "/schema-1.png",
      answerOptions: [
        {
          answerText: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
          isCorrect: false,
        },
        {
          answerText: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
          isCorrect: true,
        },
        {
          answerText: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
          isCorrect: false,
        },
        {
          answerText: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
          isCorrect: false,
        },
      ],
    },
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [checkedState, setCheckedState] = useState(
    new Array(questions[currentQuestion].answerOptions.length).fill(false)
  );

  const [timeLeft, setTimeLeft] = useState(30);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } else {
      nextQuestion();
    }
  }, [timeLeft]);

  const handleAnswerOptionClick = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };

  const nextQuestion = () => {
    let scoreIncrement = 0;
    checkedState.forEach((isChecked, index) => {
      if (
        isChecked &&
        questions[currentQuestion].answerOptions[index].isCorrect
      ) {
        scoreIncrement += 1;
      }
    });

    setScore(score + scoreIncrement);

    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
      setCheckedState(
        new Array(questions[currentQuestion + 1].answerOptions.length).fill(
          false
        )
      );
    } else {
      setShowScore(true);
    }

    setTimeLeft(30);
  };

  return (
    <MDBContainer className="animation-fade-in mb-5">
      <div className="test">
        {showScore ? (
          <div className="score-section text-center">
            <div
              className="p-5 bg-image"
              style={{
                height: "300px",
                backgroundImage: 'url("./bg/bg-accueil-aco.jpg")',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundAttachment: "fixed",
              }}
            ></div>
            <h1 className="pt-5">Fin de test</h1>
            <p>
              Vous avez marqué {score} sur {questions.length} questions.
            </p>
            <Link to="/tests-techniques">
            <MDBBtn className="BtnCard">Retourner à Tests Techniques</MDBBtn>
            </Link>
          </div>
        ) : (
          <>
            <div className="question-section">
              <div
                className="question-count text-center pt-4 fw-bold"
                style={{ color: "#09B4BF" }}
              >
                <span>Question {currentQuestion + 1}</span>/{questions.length}
              </div>
              <div className="d-flex row justify-content-center pt-5">
                <div className="text-center col-lg-6">
                  <img
                    className="img-fluid"
                    src={questions[currentQuestion].image}
                    alt={questions[currentQuestion].questionText}
                  />
                </div>
                <div className="py-5 text-center col-lg-6">
                  <div className="question-text fw-bold">
                    {questions[currentQuestion].questionText}
                  </div>
                  <div
                    className="border border-5 p-2 m-auto"
                    style={{ width: "300px" }}
                  >
                    <i className="fas fa-clock mx-2" style={{ color: "#09B4BF" }}></i>
                    Temps restant : {timeLeft} secondes
                  </div>
                  <div className="answer-section pt-3">
                    {questions[currentQuestion].answerOptions.map(
                      (answerOption, index) => (
                        <div key={index}>
                          <input
                            type="checkbox"
                            id={`custom-checkbox-${index}`}
                            name={answerOption.answerText}
                            value={answerOption.answerText}
                            checked={checkedState[index]}
                            onChange={() => handleAnswerOptionClick(index)}
                          />
                          <label htmlFor={`custom-checkbox-${index}`}>
                            {answerOption.answerText}
                          </label>
                        </div>
                      )
                    )}
                    <MDBBtn className="BtnCard" onClick={nextQuestion}>
                      Suivante
                    </MDBBtn>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </MDBContainer>
  );
}

export default TestsCompliance;
