import React from 'react';
import NavbarManager from "./NavbarManager";
import NavbarCandidat from "./NavbarCandidat";
import NavbarClient from "./NavbarClient";
import NavbarGuest from "./NavbarGuest";

const Navbar = ({ userProfile }) => {

    if (!userProfile) {
        return <NavbarGuest />;
    } else if (userProfile.role === 'CANDIDAT' || userProfile.role === 'CONSULTANT') {
        return <NavbarCandidat />;
    } else if (userProfile.role === 'MANAGER' || userProfile.role === 'SUPERADMIN') {
        return <NavbarManager />;
    } else if (userProfile.role === 'CLIENT') {
        return <NavbarClient />;
    } else {
        return <NavbarGuest />;
    }
};

export default Navbar;
