import React, { useState, useRef, useEffect } from 'react';

const UserSearch = ({
    onSearch,
    isDisabled,
    activeSuggestionIndex,
    updateActiveSuggestionIndex,
    suggestionsCount,
    onSelection,
    disableEnter,
    suggestions,
    isTransitionDisabled,
    placeholder,
}) => {
    const [query, setQuery] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (isExpanded && inputRef.current) {
            inputRef.current.focus(); 
        }
        
    }, [isExpanded]);

    const handleSearch = (event) => {
        setQuery(event.target.value);
        onSearch(event.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "ArrowUp" && activeSuggestionIndex > 0) {
            updateActiveSuggestionIndex(activeSuggestionIndex - 1);
            const selectedUser = suggestions[activeSuggestionIndex - 1];
            setQuery(`${selectedUser.nom.toUpperCase()} ${selectedUser.prenom.charAt(0).toUpperCase() + selectedUser.prenom.slice(1).toLowerCase()}`);
        } else if (e.key === "ArrowDown" && activeSuggestionIndex < suggestionsCount - 1) {
            updateActiveSuggestionIndex(activeSuggestionIndex + 1);
            const selectedUser = suggestions[activeSuggestionIndex + 1];
            setQuery(`${selectedUser.nom.toUpperCase()} ${selectedUser.prenom.charAt(0).toUpperCase() + selectedUser.prenom.slice(1).toLowerCase()}`);
        } else if (e.key === "Enter" && !disableEnter && activeSuggestionIndex !== -1) {
            onSelection();
        }
    };
    
    const noTransitionClass = isTransitionDisabled ? 'no-transition' : '';

    const toggleSearch = () => {
        setIsExpanded(prev => !prev);
    };

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target) && query === '') {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [query]);

    return (
        <div
            className={`search ${isExpanded ? 'open' : ''} ${noTransitionClass}`} 
        >
            <input
                ref={inputRef}
                className={`search-box ${noTransitionClass}`} 
                placeholder={placeholder} 
                type="text"
                value={query}
                onChange={handleSearch}
                onFocus={() => setIsExpanded(true)}
                disabled={isDisabled && query !== ''}
                onKeyDown={handleKeyDown}
            />
            <div className={`search-button ${noTransitionClass}`} 
                onClick={toggleSearch}
            >
                <div className="search-icon"></div>
            </div>
        </div>
    );
};

export default UserSearch;
