import { useState, useEffect } from 'react';
import { http } from '../../components/services/httpService';
import {
    MDBBtn,
} from 'mdb-react-ui-kit';
import { toast } from 'react-toastify';

function NewCompetenceForm({ onNewCompetence }) {
    const [newCompetenceData, setNewCompetenceData] = useState({
        nameFR: '',
        nameEN: '',
        descriptionFR: '',
        descriptionEN: '',
        categoryId: '',
    });
    const [categories, setCategories] = useState([]);

    const requestOptions = {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    };

    useEffect(() => {
        // Cargar las categorías
        const fetchCategories = async () => {
            try {
                const response = await http('categories?language=FR', requestOptions);
                if (!response.ok) {
                    throw new Error('Error al cargar categorías');
                }
                const categoriesData = await response.json();
                setCategories(categoriesData);
            } catch (error) {
                console.error('Error al cargar categorías:', error);
                // Manejar el error aquí, por ejemplo, mostrar un mensaje al usuario
            }
        };

        fetchCategories();
    }, []);

    const handleNewCompetenceChange = (e) => {
        const { name, value } = e.target;
        setNewCompetenceData({ ...newCompetenceData, [name]: value });
    };

    const handleNewCompetenceSubmit = async (e) => {
        e.preventDefault();

        if (!newCompetenceData.nameEN || !newCompetenceData.descriptionEN) {
            toast.warning("Remplissez les champs de traduction en anglais.");
            return; 
        }

        try {
            const response = await http(`competences/add-with-category/${newCompetenceData.categoryId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nameFR: newCompetenceData.nameFR,
                    descriptionFR: newCompetenceData.descriptionFR,
                    nameEN: newCompetenceData.nameEN,
                    descriptionEN: newCompetenceData.descriptionEN,
                    categoryId: newCompetenceData.categoryId,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.log('Error Data:', errorData);  
                throw new Error(errorData.message || "La compétence existe déjà.");
            }

            toast.success("Compétence ajoutée avec succès");
        } catch (error) {
            console.error('Erreur lors de l’ajout de la compétence:', error);
            toast.warning("Erreur lors de l'ajout de la compétence: " + error.message);
        }
    };

    return (
        <div className='container'>
            <form onSubmit={handleNewCompetenceSubmit}>
                <div className='row justify-content-center'>
                    <div className='col-4 d-flex flex-column'>
                        <i className="flag flag-france"></i>
                        <input
                            type="text"
                            name="nameFR"
                            value={newCompetenceData.nameFR}
                            onChange={handleNewCompetenceChange}
                            placeholder="Nom"
                            style={{ padding: '10px', fontSize: '16px' }}
                        />
                        <textarea
                            name="descriptionFR"
                            value={newCompetenceData.descriptionFR}
                            onChange={handleNewCompetenceChange}
                            placeholder="Description"
                            style={{ padding: '10px' }}
                            className='mt-2'
                        />
                    </div>
                    <div className='col-4 d-flex flex-column'>
                        <i className="flag flag-united-kingdom"></i>
                        <input
                            type="text"
                            name="nameEN"
                            value={newCompetenceData.nameEN}
                            onChange={handleNewCompetenceChange}
                            placeholder="Name"
                            style={{ padding: '10px', fontSize: '16px' }}
                        />
                        <textarea
                            name="descriptionEN"
                            value={newCompetenceData.descriptionEN}
                            onChange={handleNewCompetenceChange}
                            placeholder="Description"
                            style={{ padding: '10px' }}
                            className='mt-2'
                        />
                    </div>
                </div>
                <div className='d-flex flex-column pt-2'>
                    <select
                        name="categoryId"
                        value={newCompetenceData.categoryId}
                        onChange={handleNewCompetenceChange}
                        style={{ padding: '10px', fontSize: '16px' }}
                        className="m-auto w-25 mt-2"
                        required
                    >
                        <option value="">Catégorie</option>
                        {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                    <MDBBtn className="BtnCard m-auto w-25 mt-2" type="submit" style={{ padding: '10px 15px', fontSize: '16px', cursor: 'pointer' }}>Ajouter</MDBBtn>
                </div>
            </form>
        </div>
    );
}

export default NewCompetenceForm;