import React, { useState } from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBBtn,
    MDBTextArea,
} from 'mdb-react-ui-kit';
import "../../Pages/Accueil/Accueil.css";
import { useDarkMode } from '../../components/assets/DarkModeContext';
import { http } from '../services/httpService';
import { toast } from 'react-toastify';

function Contact() {
    const { isDarkMode } = useDarkMode();
    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('contact@alcyone-consulting.lu');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await http('send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: email,
                    subject: `Message de ${nom}`,
                    content: message
                }),
            });

            if (response.headers.get("content-type").includes("application/json")) {
                const data = await response.json();
                toast.success("Message envoyé avec succès");
            } else {
                const textResponse = await response.text();
                console.log(textResponse);
                // Gérez ici la réponse qui n'est pas au format JSON
                toast.success("Message envoyé avec succès");
            }

        } catch (error) {
            console.error("Erreur lors de l'envoi de l'email", error);
            toast.error("Erreur lors de l'envoi du message");
        }
    };

    return (
        <MDBContainer fluid className={isDarkMode ? 'bg-dark-mode' : 'bg-light'}>
            <MDBContainer>
                <section className='text-center'>
                    <div
                        className='p-5 bg-image'
                        style={{ backgroundImage: "url('./bg/contact.png')", height: '300px' }}
                    ></div>

                    <div
                        className='card mx-4 mx-md-5 shadow-5-strong'
                        style={{ marginTop: '-100px', background: 'hsla(0, 0%, 100%, 0.8)', backdropFilter: 'blur(30px)' }}
                    >
                        <div className='card-body py-5 px-md-5'>
                            <MDBRow className='mb-5'>
                                <MDBCol lg='4' md='6' className='mb-5 mb-lg-0 position-relative'>
                                    <MDBIcon icon='globe-americas' size='3x' className='mb-4' style={{ color: "#09B4BF" }} />
                                    <h6 className='fw-normal mb-0'>Luxembourg</h6>
                                    <div className='vr vr-blurry position-absolute my-0 h-100 d-none d-md-block top-0 end-0'></div>
                                </MDBCol>

                                <MDBCol lg='4' md='6' className='mb-5 mb-lg-0 position-relative'>
                                    <MDBIcon icon='map-marker-alt' size='3x' className='mb-4' style={{ color: "#09B4BF" }} />
                                    <h6 className='fw-normal mb-0'>Luxembourg Ville, L-2668</h6>
                                    <div className='vr vr-blurry position-absolute my-0 h-100 d-none d-md-block top-0 end-0'></div>
                                </MDBCol>

                                <MDBCol lg='4' md='6' className='mb-4 mb-lg-0 position-relative'>
                                    <MDBIcon icon='phone' size='3x' style={{ color: "#09B4BF" }} className='mb-4' />
                                    <h6 className='fw-normal mb-0'>+ 352 27 86 17 46</h6>
                                    <div className='vr vr-blurry position-absolute my-0 h-100 d-none d-md-block top-0 end-0'></div>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className='d-flex justify-content-center'>
                                <MDBCol lg='6' className='col-lg-6'>
                                    <form onSubmit={handleSubmit}>
                                        <MDBInput className='mb-4' name='nom' label='Nom et prénom' value={nom} onChange={(e) => setNom(e.target.value)} />
                                        <MDBTextArea label='Message' rows={4} className='mb-4' name='message' value={message} onChange={(e) => setMessage(e.target.value)} />
                                        <MDBBtn type='submit' block className='BtnCard mb-4'>
                                            Envoyer
                                        </MDBBtn>
                                    </form>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </div>
                </section>
            </MDBContainer>
        </MDBContainer>
    );
}
export default Contact;
