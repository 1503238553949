import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { http } from '../../components/services/httpService';

const Activation = () => {
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState('');
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      activateAccount(token);
    } else {
      // Rediriger vers une page d'erreur si le token n'est pas présent dans l'URL
      history.push('/erreur');
    }
  }, []);

  // Fonction pour activer le compte utilisateur
  const activateAccount = async (token) => {
    try {
      const response = await http('api/v1/auth/activate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error details:', errorText);
        throw new Error('Erreur lors de l\'activation du compte');
      }

      const data = await response.json();
      const email = data.email;

      // Après avoir activé le compte avec succès
      // history.push(`/create-password/${email}`);
      history.push(`/create-password?email=${email}`);

    } catch (error) {
      console.error(error);
      setError('Erreur lors de l\'activation du compte');
    }
  };

  return (
    <>
      <div className='container py-5'>
        <h1>Activation du compte</h1>
        <p>Veuillez patienter pendant l'activation de votre compte...</p>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </>
  );
};

export default Activation;

