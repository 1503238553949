import React from "react";

const Loader = () => {
    return (
      <div className="loader-container">
      <img  className="loader-image"  src="/spinner-alcyone.png" alt="Loading..." />
      <img  className="logo-loader"  src="/logo-loader.png" alt="Loading..." />
      </div>
    );
  };
  
  export default Loader;