import React from 'react';

function LoisirsPreview({ loisirs= [] }) {
  const filteredLoisirs = loisirs.filter(loisir => loisir.trim() !== '');

  return (
    <div className="d-flex flex-wrap gap-2" style={{ maxWidth: "100%" }}>
      {filteredLoisirs.map((loisir, index) => (
        <span className="text-black" key={index} style={{ fontSize: "12px" }}>
          {loisir}{index < filteredLoisirs.length - 1 && ", "}
        </span>
      ))}
    </div>
  );
}

export default LoisirsPreview;
