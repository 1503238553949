import { useState } from 'react';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';

const useAuth = () => {
    const [accessToken, setAccessToken] = useState(localStorage.getItem('token'));

    const environment = {
        auth: {
            authorizationUrl: process.env.REACT_APP_AUTH_AUTHORIZATION_URL,
            clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
            clientPassword: process.env.REACT_APP_AUTH_CLIENT_PASSWORD,
            redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
            challengeMethod: process.env.REACT_APP_AUTH_CHALLENGE_METHOD,
            tokenUrl: process.env.REACT_APP_AUTH_TOKEN_URL,
            logoutUrl: process.env.REACT_APP_AUTH_LOGOUT_URL,
            updatePasswordPage: process.env.REACT_APP_AUTH_UPDATE_PASSWORD_PAGE
        }
    };

    const displayLogin = () => {
        let code = Math.random().toString(36);
        localStorage.setItem('codeVerifier', code);

        let codeChallenge = CryptoJS.enc.Base64url.stringify(CryptoJS.SHA256(code));

        window.location.href =
            `${environment.auth.authorizationUrl}?response_type=code&scope=email&client_id=${environment.auth.clientId}&redirect_uri=${environment.auth.redirectUri}&code_challenge_method=${environment.auth.challengeMethod}&code_challenge=${codeChallenge}`;
    };

    const retrieveToken = async (code) => {
        const codeVerifier = localStorage.getItem('codeVerifier'); // localStorage 
        let params = new URLSearchParams();
        params.append('grant_type', 'authorization_code');
        params.append('client_id', process.env.REACT_APP_AUTH_CLIENT_ID);
        params.append('client_secret', process.env.REACT_APP_AUTH_CLIENT_PASSWORD);
        params.append('redirect_uri', process.env.REACT_APP_AUTH_REDIRECT_URI);
        params.append('code_verifier', codeVerifier);
        params.append('code', code);

        try {
            const response = await fetch(process.env.REACT_APP_AUTH_TOKEN_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
                body: params.toString()
            });

            if (!response.ok) {
                throw new Error('Invalid Credentials');
            }

            const data = await response.json();
            saveToken(data);
        } catch (error) {
            toast.warning('Invalid Credentials');
            console.error(error);
        }
    };

    const saveToken = (data) => {
        if (data && data.access_token) {
            localStorage.setItem("token", data.access_token);
            setAccessToken(data.access_token);
        } else {
            console.error("Token d'accès manquant dans la réponse");
        }
    };

    const isAuthenticated = () => {
        return accessToken != null;
    };

    const logout = async (code) => {
        let params = new URLSearchParams();
        params.append('token', localStorage.getItem("token"));
        params.append('client_id', process.env.REACT_APP_AUTH_CLIENT_ID);
        params.append('client_secret', process.env.REACT_APP_AUTH_CLIENT_PASSWORD);

        const response = await fetch(process.env.REACT_APP_AUTH_REVOKE_TOKEN_URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
            body: params.toString()
        });

        localStorage.removeItem("token"); 
        setAccessToken(null); 
        window.location.href = environment.auth.logoutUrl;
    };

    return {
        displayLogin,
        retrieveToken,
        isAuthenticated,
        logout
    };
};

export default useAuth;
