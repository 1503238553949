import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { startOfMonth, format, getYear, parseISO } from 'date-fns';

const MyMonthInput = ({ value, onChange, min, disabled, className }) => {
  const [startDate, setStartDate] = useState(value ? parseISO(value) : null);

  const handleDateChange = (date) => {
    if (date) {
      const isoDate = format(date, 'yyyy-MM') + '-01';
      setStartDate(date);
      onChange({ target: { value: isoDate } });
    } else {
      setStartDate(null);
      onChange({ target: { value: '' } });
    }
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={handleDateChange}
      showMonthYearPicker
      dateFormat="MM/yyyy"
      minDate={min ? parseISO(min) : null}
      disabled={disabled}
      className={`${className} ${disabled ? 'disabled-input' : ''}`}
    />
  );
};


export default MyMonthInput;
