import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from "react-router-dom";
import CVPreviewComponent from '../EditionCv/CVPreviewComponent';
import { http } from '../../components/services/httpService';
import { useTranslations } from '../../components/hooks/useTranslations';

const CVPreview = ({
    isAnonymous,
    showExp,
    // languageSkills,
    // groupedCompetences,
    competenceDurations,
    experiences,
    formatDate,
    calculateDifference,
    formations,
    loisirs,
    experienceYears,
    experienceMonths,
    moveAction,
    isManagerRole,
    isCandidateRole,
    currentUser,
}) => {
    const [userData, setUserData] = useState(null);
    const [cvData, setCvData] = useState(null);
    const { userId, cvId } = useParams();
    const { translations, updateTranslations } = useTranslations();
    const [languageSkills, setLanguageSkills] = useState([]);
    const [groupedCompetences, setGroupedCompetences] = useState({});

    const fetchData = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await http(`user/find-with-cvs/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Network response was not ok, status: ${response.status}`);
            }
            const data = await response.json();
            const user = data;
            const cv = user.cvs.find(cv => cv.id === parseInt(cvId));

            const competencesData = cv.cvCompetences.map(competence => ({
                id: competence.id,
                rating: competence.rating,
                cvId: competence.cvId,
                competences: competence.competences, 
            }));

            setUserData(user);
            setCvData(cv || {});
        } catch (error) {
            console.error(`Error al recuperar los datos del CV: ${error}`);
        }
    };

    useEffect(() => {
        fetchData();
    }, [userId, cvId]);

    useEffect(() => {
        if (cvData && cvData.langues) {
            updateTranslations(cvData.langues);
        }
        if (cvData && Array.isArray(cvData.languages)) {

            const skills = cvData.languages.map(lang => ({
                id: lang.id,
                name: lang.name || "",
                oralComprehension: lang.oralComprehension || "",
                writtenComprehension: lang.writtenComprehension || "",
                oralExpression: lang.oralExpression || "",
                writtenExpression: lang.writtenExpression || "",
            }));

            if (skills.length === 0 || Object.values(skills[skills.length - 1]).some(value => typeof value === 'string' && value.trim())) {
                skills.push({
                    id: null,
                    name: "",
                    oralComprehension: "",
                    writtenComprehension: "",
                    oralExpression: "",
                    writtenExpression: ""
                });
            }

            setLanguageSkills(skills);
        }

        // if (cvData && cvData.cvCompetences) {
        //     const competences = cvData.cvCompetences.map(competence => ({
        //         id: competence.competences?.id,
        //         competenceName: competence.competences.competenceName || "",
        //         rating: competence.rating || 0,
        //         categorie: {
        //             id: competence.competences.categorie?.id,
        //             name: competence.competences.categorie?.name
        //         }
        //     }));

        //     const grouped = competences.reduce((acc, competence) => {
        //         const category = competence.categorie.name;
        //         if (!acc[category]) {
        //             acc[category] = [];
        //         }
        //         acc[category].push(competence);
        //         return acc;
        //     }, {});
        //     setGroupedCompetences(grouped);
        // }

    }, [cvData]);

    console.log(cvData);

    return (
        <>
            <div className='w-50 m-auto my-5'>
                <CVPreviewComponent
                    isAnonymous={isAnonymous}
                    userData={userData}
                    cvData={cvData}
                    showExp={showExp}
                    languageSkills={languageSkills}
                    translations={translations}
                    groupedCompetences={groupedCompetences}
                    competenceDurations={competenceDurations}
                    experiences={experiences}
                    formatDate={formatDate}
                    calculateDifference={calculateDifference}
                    formations={formations}
                    loisirs={loisirs}
                    experienceYears={experienceYears}
                    experienceMonths={experienceMonths}
                    moveAction={moveAction}
                    isManagerRole={isManagerRole}
                    isCandidateRole={isCandidateRole}
                    currentUser={currentUser}
                />
            </div>
        </>
    );
};

export default CVPreview;
