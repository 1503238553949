import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import CircularProgressBar from "../../components/assets/CircularProgressBar";
import PDFViewer from "../../components/assets/PDFViewer";
import CustomPDFDisplay from "../../components/assets/CustomPDFDisplay";
import { useCurrentUser } from "../../components/hooks/useCurrentUser";

import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBadge,
} from "mdb-react-ui-kit";
import {
  getUserWithCvsById,
  http,
  addCv,
} from "../../components/services/httpService";
import { useDarkMode } from "../../components/assets/DarkModeContext";
import Information from "../../Pages/MonCompte/Information";

function UserCVs() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("Mes CVS");
  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { isDarkMode } = useDarkMode();
  const history = useHistory();

  useEffect(() => {
    getUserWithCvsById(userId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUser(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userId]);

  const handleShare = (event) => {
    event.preventDefault();
    const mailtoLink = `mailto:?subject=Mon CV&body=Bonjour,%0A%0AJ'aimerais partager le CV de ${user.prenom
      } ${user.nom.toUpperCase()} avec vous.%0A%0AVoici le lien : ${window.location.href
      }`;
    window.location.href = mailtoLink;
  };

  const handleDeleteCv = (cvId) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce CV ?"
    );
    if (!confirmed) {
      return;
    }

    const token = localStorage.getItem("token");
    http(`cv/delete/${cvId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          setUser((user) => ({
            ...user,
            cvs: user.cvs.filter((cv) => cv.id !== cvId),
          }));
        } else {
          response.json().then((errorData) => {
            console.error("Erreur lors de la suppression du CV :", errorData);
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const [showBadge, setShowBadge] = useState({});

  const handleCopy = (event, cvId) => {
    event.preventDefault();

    http(`cv/url/${cvId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.text())
      .then((cvUrl) => {
        navigator.clipboard.writeText(cvUrl);
        setShowBadge((prev) => ({ ...prev, [cvId]: true }));
        setTimeout(
          () => setShowBadge((prev) => ({ ...prev, [cvId]: false })),
          3000
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async function handleClone(cvId) {
    try {
      const response = await http(`cv/clone/${cvId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const clonedCv = await response.json();

      setUser((prevUser) => {
        return {
          ...prevUser,
          cvs: [...prevUser.cvs, clonedCv],
        };
      });
    } catch (error) {
      console.error(
        "There was a problem with the fetch operation: " + error.message
      );
    }
  }

  const [pdfFile, setPdfFile] = useState(null);
  const currentUser = useCurrentUser();
  const [filteredUser, setFilteredUser] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(true);

  const [pdfUrl, setPdfUrl] = useState(null);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(function () {
        console.log("Texte copié dans le presse-papiers");
      })
      .catch((err) => {
        console.error("Erreur lors de la copie du texte : ", err);
      });
  };

  const handleCopyPdfLink = (user, cvId, mot_cle) => {
    const langue = user?.cvs?.find(cv => cv.id === cvId)?.langues || "FR";  // Défaut à "FR" si la langue n'est pas trouvée
    const filename = generatePdfFilename(user, langue, mot_cle);

    const fullPdfUrl = `${baseUrl}cv/pdf-uploads/${filename}`;

    copyToClipboard(fullPdfUrl);

    setShowBadge((prevState) => ({ ...prevState, [cvId]: true }));

    setTimeout(() => {
      setShowBadge((prevState) => ({ ...prevState, [cvId]: false }));
    }, 3000);
  };

  function generatePdfFilename(user, langue, mot_cle) {
    return "ACO_" + user.prenom + "_" + user.nom.toUpperCase() + "_CV_" + langue?.toUpperCase() + "_" + mot_cle + ".pdf";
  }

  function handleEmailShare(user, cvId, mot_cle) {
    const langue = user?.cvs?.find(cv => cv.id === cvId)?.langues || "FR";
    const filename = generatePdfFilename(user, langue, mot_cle);
    const fullPdfUrl = `${baseUrl}cv/pdf-uploads/${filename}`;
    const subject = encodeURIComponent("Lien vers mon CV");
    const body = encodeURIComponent(`Consultez mon CV ici: ${fullPdfUrl}`);
    window.open(`mailto:?subject=${subject}&body=${body}`, "_blank");
  }

  function handlePdfPreview(user, cvId, mot_cle) {
    const langue = user?.cvs?.find(cv => cv.id === cvId)?.langues || "FR";
    const filename = generatePdfFilename(user, langue, mot_cle);
    const fullPdfUrl = `${baseUrl}cv/pdf-uploads/${filename}`;
    window.open(fullPdfUrl, '_blank');
  }

  function handleOriginPdfPreview(docUrl) {
    if (docUrl) {
      const filename = encodeURIComponent(docUrl);
      const fullPdfUrl = `${baseUrl}user/origin-pdf-uploads/${filename}`;

      const token = localStorage.getItem('token');

      fetch(fullPdfUrl, {
        method: 'GET',
        headers: new Headers({
          'Authorization': `Bearer ${token}`
        })
      })
        .then(response => {
          if (response.ok) {
            return response.blob();
          } else {
            throw new Error(`Error: ${response.statusText}`);
          }
        })
        .then(blob => {
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else {
      alert("Aucun CV n'a été déposé.");
    }
  }

  function formatPrenom(prenom) {
    if (!prenom) return "";

    return prenom
      .split(/[-\s]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(prenom.includes("-") ? "-" : " ");
  }

  const createCv = (event) => {
    if (event) {
      event.preventDefault();
    }
    const token = localStorage.getItem("token");

    const isoDate = new Date().toISOString();

    const cvData = {
      metier: "",
      poste: "",
      presentation: "",
      langues: "",
      languageSkills: [],
      cvCompetences: [],
      experiences: [],
      formations: [],
      loisirs: [],
      date_creation: isoDate,
    };

    addCv(user.id, cvData)
      .then(response => response.json())
      .then(data => {
        history.push(`/mon-espace-cv/${user.id}/${data.id}`);
      })
      .catch(error => console.error(error));

  };

  return (
    <MDBContainer fluid className={isDarkMode ? "bg-dark-mode" : "bg-light"}>
      <MDBContainer className="pb-5">
        <section className="text-center text-md-start bg-nav animation-fade-in">
          <div
            className="p-5 bg-image"
            style={{
              height: "300px",
              backgroundImage: 'url("/bg/bg-mon-compte.png")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
            }}
          ></div>
          <div
            className="pb-2"
            style={{ backgroundColor: "background-color: hsl(0, 0%, 98%)" }}
          >
            <MDBContainer>
              <MDBRow className="d-flex justify-content-center align-items-center">
                <MDBCol lg="8" md="8" className="mb-4 mb-md-0 pt-4">
                  <img
                    src="/spinner-alcyone.png"
                    className="rounded-circle float-none float-md-start me-4 mb-3 bg-white"
                    alt=""
                    style={{
                      width: "168px",
                      marginTop: "-110px",
                      border: "4px solid hsl(0, 0%, 98%)",
                      position: "relative",
                      zIndex: 1000,
                    }}
                  />
                  {user && (
                    <>
                      <h1 className={isDarkMode ? "text-white" : "text-muted"}>
                        {formatPrenom(user.prenom)}{" "}
                        <span className="text-uppercase">{user.nom}</span>
                      </h1>
                      <h2 className={isDarkMode ? "text-white" : "text-muted"}>
                        {user.role}
                      </h2>
                    </>
                  )}
                </MDBCol>
                <MDBCol
                  lg="4"
                  md="8"
                  className="mb-4 mb-md-0 text-center text-lg-end"
                ></MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </section>
        <MDBContainer className="mb-5">
          <div>
            <ul
              className="nav nav-tabs mb-5 justify-content-center"
              id="issuesTabs"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === "Mes CVS" ? "active" : ""
                    } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                  id="tab-2"
                  onClick={() => setActiveTab("Mes CVS")}
                >
                  Liste des CVs
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === "cvOrigine" ? "active" : ""
                    } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                  id="tab-2"
                  onClick={() => setActiveTab("cvOrigine")}
                >
                  CV d'origine
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === "Evaluation" ? "active" : ""
                    } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                  id="tab-1"
                  onClick={() => setActiveTab("Evaluation")}
                >
                  Evaluation des performances
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === "Information" ? "active" : ""
                    } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                  id="tab-1"
                  onClick={() => setActiveTab("Information")}
                >
                  Mes Informations
                </button>
              </li>
            </ul>
          </div>
          {activeTab === "Mes CVS" && (
            <div>
              <div className="d-flex justify-content-between mb-3">
                <div></div>
                <div className="d-flex align-items-center" style={{ cursor: "pointer" }}>
                  <MDBBadge
                    className="mx-2 ms-5"
                    color="info"
                    light
                    onClick={createCv}
                  >
                    Créer un CV
                  </MDBBadge>
                  <div onClick={createCv}>
                    <MDBIcon
                      fas
                      icon="plus-circle"
                      size="2x"
                      style={{ color: "#09B4BF" }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="shadow-4 rounded-5 mt-2 table-container"
                style={{ overflowX: "auto" }}
              >
                <h2
                  className={`text-center py-2 ${isDarkMode ? "text-white" : ""
                    }`}
                >
                  CVs
                </h2>
                <div className="d-flex text-center justify-content-center align-items-center">
                  <Link to="/envoyer-message">
                    <MDBBtn className="BtnCard">Envoyer un message</MDBBtn>
                  </Link>
                </div>
                <MDBTable>
                  <MDBTableHead
                    className={`${isDarkMode ? "bg-dark-mode" : ""}`}
                  >
                    <tr style={{ color: "#09B4BF" }}>
                      <th>
                        <span title="Ceci est le titre d'un CV">
                          Nom du CV
                          <i className="fas fa-info-circle ms-1"></i>
                        </span>
                      </th>
                      <th>Anonyme</th>
                      <th>Mot clé</th>
                      <th>Langue</th>
                      <th>Date de création</th>
                      <th>Date de modification</th>
                      <th>Statut</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody style={{ verticalAlign: "middle" }}>
                    {user &&
                      user.cvs &&
                      user.cvs.map((cv, index) => {
                        if (!user.id || !cv.id) {
                          return null;
                        }
                        const mot_cle = cv.mot_cle;
                        return (
                          <tr
                            key={index}
                            className={isDarkMode ? "text-white" : "text-muted"}
                          >
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src="/spinner-alcyone.png"
                                  alt=""
                                  style={{ width: "45px", height: "45px" }}
                                  className="rounded-circle"
                                />
                                {/* <div
                                  className="ms-3"
                                  onClick={() => handlePdfPreview(user, cv.id, mot_cle)}
                                  style={{ cursor: "pointer" }}
                                > */}
                                <Link
                                  to={`/mon-espace-cv/${user.id}/${cv.id}`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <MDBBadge color="info" light>
                                    ACO_
                                    {user.prenom
                                      ? formatPrenom(user.prenom)
                                      : "-"}
                                    _
                                    <span className="text-uppercase">
                                      {user.nom ? user.nom : "-"}
                                    </span>
                                    _CV_
                                    <span className="text-uppercase">
                                      {cv.langues ? cv.langues : "-"}
                                    </span>
                                  </MDBBadge>
                                </Link>
                                {/* </div> */}
                              </div>
                            </td>
                            <td>
                              <div className="text-center">
                                {cv.anonymous ? (
                                  <MDBIcon
                                    fas
                                    icon="user-secret"
                                    size="lg"
                                    style={{ color: "#09B4BF" }}
                                    title="oui"
                                  />
                                ) : (
                                  <MDBIcon
                                    fas
                                    icon="user-alt"
                                    size="lg"
                                    style={{ color: "#09B4BF" }}
                                    title="non"
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              {/* <p className={`fw-normal mb-1 truncate-text`}>{cv.presentation ? cv.presentation : "N/A"}</p> */}
                              <p className="mb-0">
                                {cv.mot_cle ? cv.mot_cle : "-"}
                              </p>
                            </td>
                            <td>
                              <p className="fw-normal mb-1 text-uppercase">
                                {cv.langues ? cv.langues : "-"}
                              </p>
                            </td>
                            <td>
                              <p className="fw-normal mb-1">
                                {cv.date_creation
                                  ? new Date(cv.date_creation).toLocaleDateString(
                                    "fr-FR"
                                  )
                                  : "-"}
                              </p>
                            </td>
                            <td>
                              <p className="fw-normal mb-1">
                                {cv.date_modification &&
                                  cv.date_modification !== 0
                                  ? new Date(
                                    cv.date_modification
                                  ).toLocaleDateString("fr-FR")
                                  : "-"}
                              </p>
                            </td>
                            <td>
                              <MDBBadge
                                light
                                color={(() => {
                                  switch (cv.statut) {
                                    case "VALIDE":
                                      return "success";
                                    case "BROUILLON":
                                      return "warning";
                                    case "NON_COMMENCE":
                                      return "danger";
                                    default:
                                      return "secondary";
                                  }
                                })()}
                                pill
                              >
                                {cv.statut}
                              </MDBBadge>
                            </td>
                            <td>
                              <div className="d-flex">
                                {user && cv && (
                                  <Link to={`/mon-espace-cv/${user.id}/${cv.id}`}>
                                    <MDBBtn
                                      className="fw-bold"
                                      color="link"
                                      rounded
                                      size="sm"
                                      rippleColor="dark"
                                      title="Éditer le CV"
                                    >
                                      <MDBIcon fas icon="edit" />
                                    </MDBBtn>
                                  </Link>
                                )}
                                <div style={{ position: "relative" }}>
                                  <MDBBtn
                                    className="fw-bold"
                                    color="link"
                                    rounded
                                    size="sm"
                                    rippleColor="dark"
                                    title="Copier lien"
                                    onClick={() => handleCopyPdfLink(user, cv.id, mot_cle)}
                                  >
                                    <MDBIcon far icon="clone" />
                                  </MDBBtn>

                                  {showBadge[cv.id] && (
                                    <MDBBadge
                                      color="info"
                                      className="position-absolute"
                                      style={{ top: "10px", right: "10px" }}
                                    >
                                      Lien copié !
                                    </MDBBadge>
                                  )}
                                </div>
                                <MDBBtn
                                  className="fw-bold"
                                  color="link"
                                  rounded
                                  size="sm"
                                  rippleColor="dark"
                                  onClick={() => handleClone(cv.id)}
                                  title="Cloner"
                                >
                                  <MDBIcon far icon="copy" />
                                </MDBBtn>
                              </div>
                              <MDBBtn
                                className="fw-bold"
                                color="link"
                                rounded
                                size="sm"
                                rippleColor="dark"
                                title="Effacer"
                                onClick={() => handleDeleteCv(cv.id)}
                                aria-label="Supprimer le CV"
                              >
                                <MDBIcon far icon="trash-alt" />
                              </MDBBtn>
                              <MDBBtn
                                className="fw-bold"
                                color="link"
                                rounded
                                size="sm"
                                rippleColor="dark"
                                title="Partager"
                                onClick={() => handleEmailShare(user, cv.id, mot_cle)}
                              >
                                <MDBIcon fas icon="share-square" />
                              </MDBBtn>
                              <MDBBtn
                                className="fw-bold"
                                color="link"
                                rounded
                                size="sm"
                                rippleColor="dark"
                                title="Prévisualiser"
                                onClick={() => handlePdfPreview(user, cv.id, mot_cle)}
                              >
                                <MDBIcon far icon="eye" />
                              </MDBBtn>
                            </td>
                          </tr>
                        );
                      })}
                  </MDBTableBody>
                </MDBTable>
              </div>
            </div>
          )}
          {activeTab === "cvOrigine" && (
            <div>
              <div className="d-flex justify-content-between mb-3" style={{ cursor: "pointer" }}>
                <div></div>
                <div className="d-flex align-items-center">
                  <MDBBadge className="mx-2" color="info" light onClick={() => {
                    setPopupVisible(true);
                  }}>
                    Déposer PDF
                  </MDBBadge>
                  <MDBIcon
                    fas
                    icon="plus-circle"
                    size="2x"
                    style={{ color: "#09B4BF" }}
                    onClick={() => setPopupVisible(true)}
                  />
                </div>
              </div>
              <div className="">
                {isPopupVisible && (
                  <div className="popup">
                    <div className="popup-content text-center">
                      <MDBBtn onClick={() => setPopupVisible(false)}>x</MDBBtn>
                      {(currentUser.role === "MANAGER" || currentUser.role === "SUPERADMIN") ? (
                        <div className="m-auto w-50 pt-5">
                          <PDFViewer
                            pdfFile={pdfFile}
                            setPdfFile={setPdfFile}
                            filteredUser={filteredUser}
                            overrideRoleBehavior={true}
                            viewingUserId={userId}
                          />
                          <div>
                            <CustomPDFDisplay
                              pdfFile={pdfFile}
                              filteredUser={filteredUser}
                              overrideRoleBehavior={true}
                              viewingUserId={userId}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="text-center">
                          <PDFViewer
                            pdfFile={pdfFile}
                            setPdfFile={setPdfFile}
                            currentUser={currentUser}
                          />
                          <div>
                            <CustomPDFDisplay
                              pdfFile={pdfFile}
                              currentUser={currentUser}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                className="shadow-4 rounded-5 table-container"
                style={{ overflowX: "auto" }}
              >
                <MDBTable>
                  <MDBTableHead
                    className={`${isDarkMode ? "bg-dark-mode" : ""}`}
                  >
                    <tr style={{ color: "#09B4BF" }}>
                      <th>Nom et prénom</th>
                      <th className="text-center">Nom du fichier</th>
                      <th className="text-center">Fichier</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody style={{ verticalAlign: "middle" }}>
                    {user && (
                      <tr className={isDarkMode ? "text-white" : "text-muted"}>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src="/spinner-alcyone.png"
                              alt=""
                              style={{ width: "45px", height: "45px" }}
                              className="rounded-circle"
                            />
                            <p className="fw-bold mb-1">
                              <span className="text-uppercase">{user.nom}</span>{" "}
                              {formatPrenom(user.prenom)}
                            </p>
                          </div>
                        </td>
                        <td className="text-center">
                          {user.docUrl ? (
                            user.docUrl.split('\\').pop()
                          ) : (
                            "Aucun fichier"
                          )}
                        </td>
                        <td className="text-center">
                          <p className="mb-0">
                            {user.docUrl ? (
                              <MDBIcon
                                far icon="file-pdf"
                                size="2x"
                                style={{ color: "#09B4BF", cursor: "pointer" }}
                                onClick={() => handleOriginPdfPreview(user.docUrl)}
                                title="Ouvrir le PDF"
                              />
                            ) : (
                              "Aucun CV n'a été déposé."
                            )}
                          </p>
                        </td>
                      </tr>
                    )}
                  </MDBTableBody>
                </MDBTable>
              </div>
            </div>
          )}
          {activeTab === "Evaluation" && (
            <div>
              <h2
                className={`text-center py-2 ${isDarkMode ? "text-white" : ""}`}
              >
                Evaluation des performances
              </h2>
              <div className="row">
                <div className="col-12 col-md-6 py-5">
                  <CircularProgressBar />
                </div>

                <div className="col-12 col-md-6 shadow-4 rounded-5 mt-4">
                  <div className="d-flex justify-content-around">
                    <p class="pt-2" style={{ color: "#09B4BF" }}>
                      Client :
                    </p>
                    <p class="pt-2" style={{ color: "#09B4BF" }}>
                      Période :
                    </p>
                  </div>
                  <MDBTable>
                    <MDBTableHead
                      className={`${isDarkMode ? "bg-dark-mode" : ""}`}
                    >
                      <tr style={{ color: "#09B4BF" }}>
                        <th className="">Evaluation</th>
                        <th className="">Note Correspondante</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody style={{ verticalAlign: "middle" }}>
                      <tr className={isDarkMode ? "text-white" : "text-muted"}>
                        <td>
                          <div>
                            <p className="">Ponctualité</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="">10</p>
                          </div>
                        </td>
                      </tr>
                      <tr className={isDarkMode ? "text-white" : "text-muted"}>
                        <td>
                          <div>
                            <p className="">
                              Connaissances et compétences professionnelles
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="">10</p>
                          </div>
                        </td>
                      </tr>
                      <tr className={isDarkMode ? "text-white" : "text-muted"}>
                        <td>
                          <div>
                            <p className="">Sens du collectif et coopération</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="">10</p>
                          </div>
                        </td>
                      </tr>
                      <tr className={isDarkMode ? "text-white" : "text-muted"}>
                        <td>
                          <div>
                            <p className="">
                              Capacité de résolution de problèmes
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="">10</p>
                          </div>
                        </td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </div>
              </div>
            </div>
          )}
          {activeTab === "Information" && (
            <>
              <Information userData={user} authenticatedUserRole={currentUser.role} />
            </>
          )}
        </MDBContainer>
      </MDBContainer>
    </MDBContainer>
  );
}
export default UserCVs;
