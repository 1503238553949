import React from 'react';
import {
    MDBRow,
    MDBCol
} from "mdb-react-ui-kit";
import { getUserInitials, formatPrenom } from "../../components/assets/Fonctions";

function Header({ isAnonymous, userData, cvData }) {

    return (
        <header>
            <MDBRow>
                <MDBCol md="6" className="text-start">
                    <img
                        src="/spinner-alcyone.png"
                        className="spinner-cv"
                        height="100"
                        alt=""
                        style={{
                            marginLeft: "50px",
                            marginTop: "40px",
                        }}
                    />
                    <div
                        className="content-div"
                        style={{
                            marginTop: "-62px",
                            marginLeft: "91px",
                            width: "400px",
                        }}
                    >
                        {isAnonymous ? (
                            <>
                                <p className="h4">
                                    <span className="fw-bold">
                                        {getUserInitials(userData).firstNameInitials}{" "}
                                    </span>
                                    <span className="fw-bold">
                                        {getUserInitials(userData).lastNameInitials}
                                    </span>
                                </p>
                            </>
                        ) : (
                            <>
                                <p className="h4">
                                    <span className="fw-bold">
                                        {formatPrenom(userData?.prenom)}{" "}
                                    </span>

                                    <span className="fw-bold">
                                        {userData?.nom?.toUpperCase()}
                                    </span>
                                </p>
                            </>
                        )}

                        <p
                            className="card-subtitle text-muted text-center fw-bold"
                            id="job-title-preview"
                        >
                            {cvData?.poste}
                        </p>
                    </div>
                </MDBCol>
                <MDBCol md="6" className="header-cv text-end">
                    <img
                        src="/logo-cv.png"
                        className="header-cv"
                        height="150"
                        alt=""
                        style={{
                            marginTop: "40px",
                            marginRight: "70px",
                        }}
                    />
                </MDBCol>
            </MDBRow>
        </header>
    );
}

export default Header;
