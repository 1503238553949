import React, { useState } from 'react';
import { useHistory, useParams} from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdb-react-ui-kit";
import { http } from '../../components/services/httpService';

export default function ResetPassword() {
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const { token } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== passwordConfirm) {
            alert("Les mots de passe ne correspondent pas");
            return;
        }

        try {
            const response = await http("api/v1/auth/reset-password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token, password }),
            });
            if (!response.ok) {
                throw new Error("Une erreur s'est produite lors de la réinitialisation du mot de passe");
            }
            alert('Votre mot de passe a été réinitialisé avec succès');
            history.push("/");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <MDBContainer fluid className="fond-accueil inscription">
            <MDBRow className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "90VH" }}>
                <MDBCol md="6">
                    <p className="h5 text-center mb-4">Récuperation de mot de passe</p>
                    <form onSubmit={handleSubmit}>
                        <MDBInput
                            label="Nouvelle mot de passe"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="mb-4"
                        />
                        <MDBInput
                            label="Confirmer le mot de passe"
                            type="password"
                            value={passwordConfirm}
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                            required
                            className="mb-4"
                        />
                        <div className="text-center">
                            <MDBBtn type="submit" className='BtnCard'>Confirmer</MDBBtn>
                        </div>
                    </form>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );

}