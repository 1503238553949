import React from "react";
import { useState } from "react";
import { useHistory } from 'react-router-dom';
import { http } from '../services/httpService';
import "./PDFViewer.css"
import { MDBIcon } from "mdb-react-ui-kit";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useCurrentUser } from "../../components/hooks/useCurrentUser";
import { toast } from 'react-toastify';

function PDFViewer({ pdfFile, setPdfFile, filteredUser, overrideRoleBehavior = false }) {

    const fileType = ['application/pdf'];
    const currentUser = useCurrentUser();
    const [selectedFile, setSelectedFile] = useState(null);

    const handleChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            console.log(`Selected file: ${selectedFile.name}`);
            console.log(`File type: ${selectedFile.type}`);

            if (fileType.includes(selectedFile.type)) {
                console.log("File type is valid (application/pdf)");
                let reader = new FileReader();
                reader.readAsDataURL(selectedFile);
                reader.onloadend = () => {
                    setPdfFile(reader.result);
                };
                setSelectedFile(selectedFile);
            }
        } else {
            setPdfFile(null);
            setSelectedFile(null);
            console.log("Veuillez sélectionner un userIdToUse");
        }
    };

    const history = useHistory();

    const handleSave = async (e) => {
        e.preventDefault();

        const userIdToUse = (
            overrideRoleBehavior ||
            currentUser?.role === 'CANDIDAT' ||
            currentUser?.role === 'CONSULTANT'
        ) ? currentUser.id : filteredUser?.id;

        if (selectedFile && userIdToUse) {
            const token = localStorage.getItem('token');

            try {
                const formData = new FormData();
                formData.append("file", selectedFile);
                formData.append("userId", userIdToUse);

                const response = await http("user/upload-pdf", {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error("Erreur lors de l'enregistrement du fichier PDF.");
                }

                toast.success("Le fichier PDF a été enregistré avec succès.");

            } catch (error) {
                console.error(error);
                toast.error("Erreur lors de l'enregistrement du fichier PDF.");
            }
        } else {
            toast.error("Veuillez sélectionner un fichier PDF et un utilisateur.");
        }
    };

    return (
        <div className="pdf-viewer">
            <form>
                <div className='text-center mt-5'>
                    <p className='text-white'>Voulez-vous enregistrer directement un CV depuis votre ordinateur ?</p>
                </div>
                <div className="file-card col-6 justify-content-center mx-auto">
                    <div className="file-inputs">
                        <input className="upload-input" type="file" onChange={handleChange} />
                        <button className='upload'>
                            <MDBIcon fas icon="plus-circle me-1" />
                            Upload
                        </button>
                    </div>
                    <p className="main fw-bold">Fichiers pris en charge :</p>
                    <p className="info">PDF</p>

                    <button className="btn" onClick={handleSave}>
                        Confirmer
                    </button>
                </div>
            </form>
        </div>
    );
}

export default PDFViewer;