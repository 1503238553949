// FormacionesComponent.jsx
import React from 'react';
import MyMonthInput from "./MyMonthInput";
import { MDBIcon, MDBInput, MDBTextArea } from 'mdb-react-ui-kit';

function FormationsComponent({ formations, translations, handleFormationChange, moveFormationUp, moveFormationDown, removeFormation}) {
  return (
    <>
      {formations.map((formation, index) => (
        <div key={formation.id}>
          <div className="row">
            <div className="d-flex align-items-center justify-content-end">
              <div className="d-flex align-items-center">
                {index !== 0 && (
                  <MDBIcon
                    fas
                    icon="arrow-up"
                    className="m-1"
                    onClick={() => moveFormationUp(index)}
                    role="button"
                    title="Remonter la formation"
                  />
                )}
                {index !== formations.length - 1 && (
                  <MDBIcon
                    fas
                    icon="arrow-down"
                    className="m-1"
                    onClick={() => moveFormationDown(index)}
                    role="button"
                    title="Descendre la formation"
                  />
                )}
              </div>
              <MDBIcon
                far
                icon="trash-alt"
                className={`m-1 text-end`}
                onClick={() => removeFormation(index)}
                role="button"
                size="lg"
              />
            </div>
            <div className="col-4">
              <div>
                <label>{translations.labels.school}</label>
                <MDBInput
                  type="text"
                  className="form-control-input"
                  value={formation.ecole || ""}
                  onChange={(event) =>
                    handleFormationChange(event, index, "ecole")
                  }

                />
              </div>
              <div className="label-input-container">
                <label>{translations.labels.startDate}</label>
                <MyMonthInput
                  className="transparent-input"
                  value={formations[index]?.dateDebut ?? ""}
                  onChange={(event) =>
                    handleFormationChange(
                      event,
                      index,
                      "dateDebut"
                    )
                  }
                />
                <label>{translations.labels.endDate}</label>
                <MyMonthInput
                  className={`transparent-input ${formations[index]?.isCurrentFormation ? 'disabled-input' : ''}`}
                  value={formations[index]?.dateFin ?? ""}
                  onChange={(event) => handleFormationChange(event, index, "dateFin")}
                  placeholder="Date de fin"
                  min={new Date(formations[index]?.dateDebut).setDate(-1)}
                  disabled={formations[index]?.isCurrentFormation}
                />
                <div>
                  <input
                    id={`currentFormation-${index}`}
                    type="checkbox"
                    onChange={(event) => handleFormationChange(event, index, "isCurrentFormation")}
                    checked={formations[index]?.isCurrentFormation ?? false}
                  />
                  <label htmlFor={`currentFormation-${index}`}>{translations.labels.ongoing}</label>
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="mb-2">
                <label>{translations.labels.level}</label>
                <select
                  id="niveau"
                  type="text"
                  name="niveau"
                  className="form-select select-input"
                  value={formation.niveau || ""}
                  onChange={(event) =>
                    handleFormationChange(
                      event,
                      index,
                      "niveau"
                    )
                  }
                >
                  <option defaultValue>{translations.selectAnOption}</option>
                  <option value="BAC">{translations.educationLevels.BAC}</option>
                  <option value="BAC1">{translations.educationLevels.BAC1}</option>
                  <option value="BAC2">{translations.educationLevels.BAC2}</option>
                  <option value="BAC3">{translations.educationLevels.BAC3}</option>
                  <option value="BAC4">{translations.educationLevels.BAC4}</option>
                  <option value="BAC5">{translations.educationLevels.BAC5}</option>
                  <option value="BAC67">{translations.educationLevels.BAC67}</option>
                  <option value="BAC8">{translations.educationLevels.BAC8}</option>
                </select>
              </div>
              <div>
                <label>{translations.labels.title}</label>
                <MDBTextArea
                  type="text"
                  className="form-control-input"
                  value={formation.intitule || ""}
                  onChange={(event) =>
                    handleFormationChange(
                      event,
                      index,
                      "intitule"
                    )
                  }

                />
              </div>
            </div>
          </div>
          {index !== formations.length - 1 && (
            <hr className="border border-5" />
          )}
        </div>
      ))}
    </>
  );
}

export default FormationsComponent;
