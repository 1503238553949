import "./Inscription.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBCheckbox,
  MDBBadge,
} from "mdb-react-ui-kit";
import { registerUser } from "../../components/services/httpService";
import { toast } from 'react-toastify';
export default function Inscription() {

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [poste, setPoste] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();


  const validatePassword = (password) => {
    const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{12,}/;
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    if (!validatePassword(password)) {
      setError("Le mot de passe doit avoir au moins 12 caractères, contenant des majuscules, des minuscules, des chiffres et un caractère spécial.");
      return;
    }

    try {
      const response = await registerUser({ firstname, lastname, poste, email, phone, password, role: "MANAGER" });
      if (!response.ok) {
        throw new Error("Erreur de connexion");
      }
      // Afficher un message de confirmation d'inscription
      toast.success("Inscription réussie! Veuillez vous connecter pour accéder à votre compte.");
      // Rediriger l'utilisateur vers la page de connexion
      history.push("/");
    } catch (error) {
      console.error(error);
      setError("Cet email est déjà utilisé, veuillez en choisir un autre.");
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <MDBContainer fluid className="fond-accueil inscription">
      <MDBRow className="d-flex justify-content-center align-items-center py-5"
        style={{ minHeight: "90VH" }}>
        <MDBCol md="6" className="form-card">
          <form onSubmit={handleSubmit}>
            <p className="h5 text-center mb-4">S'enregistrer</p>
            <div className="grey-text">
              <MDBInput
                label="Prénom"
                name="prenom"
                type="text"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                required
                className="mb-4"
                contrast
              />
              <MDBInput
                label="Nom"
                name="nom"
                icon="user"
                type="text"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
                className="mb-4"
                contrast
              />
              <MDBInput
                label="Poste"
                name="poste"
                icon="user"
                type="text"
                value={poste}
                onChange={(e) => setPoste(e.target.value)}
                required
                className="mb-4"
                contrast
              />
              <MDBInput
                label="Email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[A-Za-z]{2,}$"
                required
                className="mb-4"
                contrast
              />
              <MDBInput
                label="phone"
                name="phone"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                pattern="^\+\d+(\s\d+)*$"
                required
                contrast
              />
              <small className="text-white">
                *Veuillez utiliser le préfixe international (par exemple, +33 pour la France et +352 pour le Luxembourg).
              </small>
              <div className='position-relative mt-2'>
                <MDBInput
                  label="Mot de passe"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (error) setError("");
                  }}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{12,}"
                  required
                  className="mb-4"
                  contrast
                />
                <i
                  className={`fas fa-eye${showPassword ? "-slash" : ""
                    } password-toggle-icon`}
                  onClick={toggleShowPassword}
                  style={{
                    position: "absolute",
                    top: "28%",
                    right: "22px",
                    cursor: "pointer",
                    color: "white",
                  }}
                ></i>
              </div>
              <div className='position-relative'>
                <MDBInput
                  label="Confirmer le mot de passe"
                  name="confirmPassword"
                  type={showPassword ? "text" : "password"}
                  value={passwordConfirm}
                  onChange={(e) => {
                    setPasswordConfirm(e.target.value);
                    if (error) setError("");
                  }}
                  contrast
                />
                <i
                  className={`fas fa-eye${showPassword ? "-slash" : ""
                    } password-toggle-icon`}
                  onClick={toggleShowPassword}
                  style={{
                    position: "absolute",
                    top: "28%",
                    right: "22px",
                    cursor: "pointer",
                    color: "white",
                  }}
                ></i>
              </div>
              {error && <MDBBadge color='danger' light className="error-message">{error}</MDBBadge>}
              <div className="text-white pt-2">
                <h5>Besoin d'aide ?</h5>
                <p>Pour choisir un mot de passe sécurisé :</p>
                <ul>
                  <li>Utilisez au moins 12 caractères.</li>
                  <li>Mélangez majuscules et minuscules.</li>
                  <li>Utilisez au moins un chiffre.</li>
                  <li>Utilisez des caractères spéciaux comme !, @, #, $, etc.</li>
                </ul>
              </div>
              <div className="mt-4">
                <MDBCheckbox
                  wrapperClassName="d-flex justify-content-center mb-4 text-light"
                  id="form3Example5"
                  label="J’accepte qu’Alcyone dispose de mes informations […]afin de pouvoir exercer l’ensemble de ses activités commerciales."
                  required
                  contrast
                />
              </div>
            </div>
            <div className="text-center mb-4">
              <MDBBtn type="submit" className="BtnCard mb-4">Envoyer</MDBBtn>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
          </form>
        </MDBCol>
      </MDBRow>
    </MDBContainer >
  );
};
