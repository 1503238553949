import React, { useState, useEffect } from "react";

import {
    MDBContainer,
    MDBIcon,
    MDBCard
} from "mdb-react-ui-kit";
import { useDarkMode } from '../../components/assets/DarkModeContext';

export default function Mentions() {
    const { isDarkMode } = useDarkMode();

    return (
        <MDBContainer fluid className={isDarkMode ? 'bg-dark-mode' : 'bg-light'}>
            <MDBContainer className="pb-5">
                <section className="text-center text-md-start bg-nav animation-fade-in">
                    <div
                        className="p-5 bg-image"
                        style={{
                            height: "300px",
                            backgroundImage: 'url("./bg/legal.jpg")',
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "bottom",
                            backgroundAttachment: "fixed",
                        }}
                    ></div>
                    <MDBCard className="h-100 p-5" alignment="center">
                        <MDBIcon fas icon="balance-scale" size="3x" style={{ color: "#09B4BF" }}/>
                        <h1 className="text-muted pt-2">
                            MENTIONS LÉGALES
                        </h1>
                        <div className="pt-3">
                            <p className="fw-bold">STATUT SOCIAL :</p>
                            <p className="">SàRL Alcyone Consulting</p>
                            <p className="">Société à Responsabilité Limitée au capital de 12 400 euros</p>

                        </div>
                        <div className="pt-3">
                            <p className="fw-bold">ADRESSE DU SIÈGE SOCIAL</p>
                            <p className="">2-8 rue Julien Vesque</p>
                            <p className="">L-2668 LUXEMBOURG</p>
                            <p className="">LUXEMBOURG</p>
                        </div>

                        <div className="pt-3">
                            <p className="fw-bold">INFORMATIONS SUR LA SOCIÉTÉ</p>
                            <p className="">RCS LUXEMBOURG B 133 438</p>
                            <p className="">N° de TVA intracommunautaire : FR79499502557</p>
                            <p className="">Pour toute question, vous pouvez nous contacter par mail à l'adresse contact@alcyone-consulting.lu</p>

                        </div>

                        <div className="pt-3">
                            <p className="fw-bold">LE DOMAINE WWW.ALCYONE-CONSULTING.LU EST HÉBERGÉ CHEZ :</p>
                            <p className="">OVH SAS</p>
                            <p className="">2 rue Kellermann</p>
                            <p className="">59100 ROUBAIX</p>
                            <p className="">FRANCE</p>
                        </div>
                    </MDBCard>
                </section>
            </MDBContainer>
        </MDBContainer>
    );
}
