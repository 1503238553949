import React from "react";
import "../../Pages/Accueil/Accueil.css";
import {
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import { useDarkMode } from "../../components/assets/DarkModeContext";
import ManagersList from "./ManagersList";

export default function MonEquipe() {
  const { isDarkMode, isTransitionDisabled } = useDarkMode();

  return (
    <MDBContainer fluid className={isDarkMode ? "bg-dark-mode" : "bg-light"}>
      <MDBContainer className="animation-fade-in pb-5">
        <div
          className="p-5 bg-image"
          style={{
            height: "300px",
            backgroundImage: 'url("./bg/bg-equipe.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundAttachment: "fixed",
          }}
        ></div>
        <MDBRow className="text-center pt-3">
          <div className={isDarkMode ? "text-white" : "text-muted"}>
            <h1>Mon équipe</h1>
          </div>
        </MDBRow>
        <ManagersList />
      </MDBContainer>
    </MDBContainer>
  );
}
