import React, { useState, useEffect } from 'react';

const ShuffleTitle = () => {
    const titles = [
        "Découvrez notre plateforme dédiée et conçue pour valoriser votre parcours professionnel.",
        "Simple, efficace et incontournable."
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % titles.length);
        }, 5000);

        return () => clearInterval(intervalId);  
    }, []);

    return (
        <div className="rotate-container">
            {titles.map((title, index) => (
                <p 
                    key={index} 
                    className={`h4 rotate-text ${currentIndex === index ? 'front' : ''}`}
                >
                    {title}
                </p>
            ))}
        </div>
    );
};

export default ShuffleTitle;
