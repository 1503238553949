import React from 'react';
import StarRating from '../../components/assets/StarRating';

function CompetencesPreview({ groupedCompetences = {}, competenceDurations, translations }) {
    return (
        <>
            <div style={{ marginTop: "-12px" }}>
                {Object.keys(groupedCompetences).length > 0 && Object.keys(groupedCompetences).map((category, index, array) => {
                    return (
                        <div className="row no-page-break" key={index} style={{ overflowX: 'auto' }}>
                            <div className="col-sm-12 col-md-2 col-lg-2">
                                <p className="p-0 m-0" style={{ color: "#09B4BF", fontSize: "11px", width: "115%" }}>{category}</p>
                            </div>
                            <div className="col-sm-12 col-md-10 col-lg-10">
                                <div
                                    className="d-flex flex-md-wrap flex-lg-wrap flex-sm-nowrap gap-2"
                                    style={{ maxWidth: "100%", fontSize: "11px" }}
                                >
                                    {groupedCompetences[category]?.map((competence, subIndex) => (
                                        <div key={subIndex}
                                            className="gap-1 d-md-flex d-lg-flex"
                                            style={{
                                                width: "calc(50% - 1rem)",
                                                alignItems: "center",
                                                boxSizing: "border-box",
                                            }}>
                                            <span className="text-black"
                                                style={{
                                                    width: "40%",
                                                    alignItems: "center",
                                                    fontSize: "11px",
                                                }}>{competence.competenceName}</span>
                                            {competence.competenceName && (
                                                <div className="d-md-flex d-lg-flex align-items-center">
                                                    <div style={{ marginTop: "-4px", width: "60px" }}>
                                                        <StarRating rating={competence.rating} readOnly />
                                                    </div>
                                                    {competenceDurations[competence.competenceName] && (
                                                        <div style={{ fontSize: "9px", width: "40px" }}>
                                                            {`${competenceDurations[competence.competenceName].years > 0
                                                                ? `${competenceDurations[competence.competenceName].years} ${translations.years} `
                                                                : ''
                                                                }
                          ${competenceDurations[competence.competenceName].months > 0
                                                                    ? `${competenceDurations[competence.competenceName].months} ${translations.months}`
                                                                    : ''
                                                                }`}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {index < array.length - 1 && (
                                <hr className="m-0 mt-1" style={{ color: "#09B4BF" }} />
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default CompetencesPreview;
