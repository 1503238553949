import React from 'react';

const DropdownCascade = ({ data, selectedBUs, handleBUSelect, selectedTribes, handleTribeChange, selectedMetiers, handleMetierChange, handleMetierRemove }) => {

    const handleTribeChangeInternal = (buId, selectedValue) => {
        handleTribeChange(buId, selectedValue);
    };

    const handleMetierChangeInternal = (buId, tribeId, selectedValue) => {
        handleMetierChange(buId, tribeId, selectedValue);
    };

    return (
        <div>
            <small className="text-white fst-italic mb-3" style={{ fontSize: "11px" }}>
                *Veuillez choisir un ou plusieurs Business Unit
            </small>
            <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                {data.map(bu => (
                    <div key={`bu-${bu.buId}`} style={{ margin: '10px' }}>
                        <button
                            className={`btn_primary mb-2 ${selectedBUs.includes(bu.buId) ? 'active' : ''}`}
                            onClick={() => handleBUSelect(bu.buId)}
                            style={{
                                backgroundColor: selectedBUs.includes(bu.buId) ? '#fff' : '',
                                color: selectedBUs.includes(bu.buId) ? '#3c3b3b' : '',
                            }}
                        >
                            {bu.buName}
                        </button>
                        {selectedBUs.includes(bu.buId) && (
                            <div>
                                <select
                                    className='form-select select-input mb-2'
                                    style={{ width: '150px' }}
                                    value={selectedTribes[bu.buId] || ''}
                                    onChange={(e) => handleTribeChangeInternal(bu.buId, e.target.value)}
                                >
                                    <option value="">Sélec. Tribe</option>
                                    {bu.tribes.map(tribe => (
                                        <option key={`tribe-${tribe.tribeId}`} value={tribe.tribeId}>{tribe.tribeName}</option>
                                    ))}
                                </select>
                                {selectedTribes[bu.buId] && (
                                    <>
                                        <select
                                            className='form-select select-input mb-2'
                                            style={{ width: '150px' }}
                                            value=""
                                            onChange={(e) => handleMetierChangeInternal(bu.buId, selectedTribes[bu.buId], e.target.value)}
                                        >
                                            <option value="" disabled>Sélec. Métier</option>
                                            {bu.tribes
                                                .filter(tribe => tribe.tribeId.toString() === selectedTribes[bu.buId])
                                                .flatMap(tribe => tribe.metiers)
                                                .map(metier => (
                                                    <option key={`metier-${metier.metierId}`} value={metier.metierId}>{metier.metierName}</option>
                                                ))}
                                        </select>
                                        <div style={{ display: 'block', marginTop: '10px' }}>
                                            {selectedMetiers[bu.buId] && Object.entries(selectedMetiers[bu.buId]).map(([tribeId, metiers]) => (
                                                metiers.map(metierId => {
                                                    const metier = bu.tribes
                                                        .flatMap(tribe => tribe.metiers)
                                                        .find(metier => metier.metierId === parseInt(metierId, 10));
                                                    return (
                                                        <span key={`selected-metier-${metierId}`} className="badge btn_primary" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px', margin: '5px 0' }}>
                                                            {metier?.metierName}
                                                            <button
                                                                type="button"
                                                                className="btn-close btn-close-white ms-2"
                                                                aria-label="Close"
                                                                onClick={() => handleMetierRemove(bu.buId, tribeId, metierId)}
                                                            ></button>
                                                        </span>
                                                    );
                                                })
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DropdownCascade;

