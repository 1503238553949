import React, { useState, useEffect } from 'react';

const ImageSwitcher = () => {
    const images = ['./gpw-banner.png', './gpw-banner2.png'];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000); 

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <img
                src={images[currentImageIndex]}
                width={600}
                alt="cv"
                loading="lazy"
                className="animation-apparition-gauche img-fluid pb-3"
            />
        </div>
    );
};

export default ImageSwitcher;
