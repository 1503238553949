import React, { useState, useEffect } from "react";
import "../../Pages/Accueil/Accueil.css";
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBInput,
} from "mdb-react-ui-kit";
import {
  getProfile,
  http,

} from "../../components/services/httpService";
import { toast } from 'react-toastify';
import { useDarkMode } from "../../components/assets/DarkModeContext";
import DropdownCascadeSimple from "./DropdownCascadeSimple";

export default function Information({ userData, authenticatedUserRole }) {
  const [user, setUser] = useState({
    nom: "",
    role: "",
    email: "",
    phone: "",
    dateCreation: "",
    dateExpiration: "",
    manager: null,
    // tribe: "",
    pays: "Luxembourg",
    managerDetails: {
      poste: "",
    },
    businessUnits: []
  });

  const token = localStorage.getItem("token");
  const { isDarkMode } = useDarkMode();
  const [managers, setManagers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedBUs, setSelectedBUs] = useState([]);
  const [selectedTribes, setSelectedTribes] = useState({});
  const [selectedMetiers, setSelectedMetiers] = useState({});
  const [allBusinessUnits, setAllBusinessUnits] = useState([]);

  useEffect(() => {
    if (userData) {
      setUser(userData);
    } else {
      getProfile()
        .then(response => response.json())
        .then(data => setUser(data))
        .catch(error => console.error(error));
    }
  }, [userData]);

  function formatPrenom(prenom) {
    if (!prenom) return "";

    return prenom
      .split(/[-\s]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(prenom.includes("-") ? "-" : " ");
  }

  useEffect(() => {
    http(`user/managers`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => setManagers(data))
      .catch(error => console.error("Error fetching managers:", error));
  }, [token]);

  const handleRemoveMetier = async (metierId, tribeId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce métier?')) {
      try {
        const response = await http(`user/${user.id}/metier/${metierId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });

        if (response.ok) {
          const updatedUser = {
            ...user,
            businessUnits: user.businessUnits.map(bu => ({
              ...bu,
              tribes: bu.tribes.map(tribe => {
                if (tribe.tribeId === tribeId) {
                  return {
                    ...tribe,
                    metiers: tribe.metiers.filter(metier => metier.metierId !== metierId)
                  };
                }
                return tribe;
              })
            }))
          };
          setUser(updatedUser);
        } else {
          throw new Error('Erreur lors de la suppression');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const updateUserDetails = async (updatedUser) => {
    try {
      const response = await http(`user/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedUser)
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour');
      }

      const data = await response.json();
      setUser(data);
      toast.success("La mise à jour a été bien faite");
    } catch (error) {
      console.error(error);
    }
  };

  const flagClasses = {
    France: 'flag flag-france',
    Luxembourg: 'flag flag-luxembourg',
    Switzerland: 'flag flag-switzerland',
    Belgique: 'flag flag-belgium',
  };

  function renderMetiers(businessUnits) {
    if (!businessUnits) return null;

    return businessUnits.flatMap(bu =>
      bu.tribes.flatMap(tribe =>
        tribe.metiers.map(metier => (
          <div key={metier.metierId} className="chip m-2 text-white">
            {metier.metierName}
            {isEditing && (
              <span className="remove-x" onClick={() => handleRemoveMetier(metier.metierId, tribe.tribeId, metier.metierId)}>x</span>
            )}
          </div>
        ))
      )
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http('business-units/');
        const jsonData = await response.json();
        setAllBusinessUnits(jsonData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, []);

  async function handleAddMetier(buId, tribeId, metierId, metierName) {
    const buName = allBusinessUnits.find(bu => bu.buId === buId)?.buName;
    const tribeName = allBusinessUnits
      .find(bu => bu.buId === buId)?.tribes
      .find(tribe => tribe.tribeId === tribeId)?.tribeName;

    try {
      const response = await http(`user/${user.id}/tribe/${tribeId}/metier/${metierId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ buName, tribeName, metierName })
      });

      if (response.ok) {
        const data = await response.json();
        toast.success('Métier added successfully');

        setUser(data);
      } else {
        throw new Error('Failed to add the métier');
      }
    } catch (error) {
      console.error('Failed to add métier:', error);
    }
  }

  const handleBUSelect = (buId) => {
    const newSelected = selectedBUs.includes(buId) ? selectedBUs.filter(id => id !== buId) : [...selectedBUs, buId];
    setSelectedBUs(newSelected);
  };

  const handleTribeChange = (buId, tribeId) => {
    setSelectedTribes(prev => ({ ...prev, [buId]: tribeId }));
  };

  const handleMetierChange = (buId, tribeId, metierId) => {
    const selectedBu = allBusinessUnits.find(bu => bu.buId === buId);
    if (!selectedBu) return;

    const selectedTribe = selectedBu.tribes.find(tribe => tribe.tribeId.toString() === tribeId.toString());
    if (!selectedTribe) return;

    const selectedMetier = selectedTribe.metiers.find(metier => metier.metierId.toString() === metierId.toString());
    if (!selectedMetier) return;

    const metierName = selectedMetier.metierName;

    setSelectedMetiers(prev => ({
      ...prev,
      [buId]: {
        ...prev[buId],
        [tribeId]: [
          ...(prev[buId]?.[tribeId] || []),
          { metierId, metierName }
        ]
      }
    }));
    handleAddMetier(buId, tribeId, metierId, metierName);
  };

  const filteredSelectedMetiers = Object.fromEntries(
    Object.entries(selectedMetiers).map(([buId, tribes]) => [
      buId,
      Object.fromEntries(
        Object.entries(tribes).map(([tribeId, metiers]) => [
          tribeId,
          metiers.filter(metier => metier.metierName)
        ]).filter(([, metiers]) => metiers.length > 0)
      )
    ]).filter(([, tribes]) => Object.keys(tribes).length > 0)
  );

  return (
    <MDBContainer fluid className={isDarkMode ? "bg-dark-mode" : "bg-light"}>
      <MDBContainer className="py-5">
        <section className="text-center text-md-start bg-nav animation-fade-in">
          <MDBCard className="h-100 p-5" alignment="center">
            {(authenticatedUserRole === "MANAGER" || authenticatedUserRole === "SUPERADMIN") && (
              <span className="text-end" onClick={() => setIsEditing(!isEditing)}>
                <MDBIcon fas icon="edit" style={{ color: "#09B4BF" }} />
              </span>
            )}
            <MDBIcon
              fas
              icon="user-alt"
              size="3x"
              style={{ color: "#09B4BF" }}
            />
            <h1 className="d-flex gap-2 justify-content-center text-muted pt-2">
              {isEditing ? (
                <MDBInput
                  type="text"
                  className=""
                  value={user.prenom}
                  onChange={(e) => setUser({ ...user, prenom: e.target.value })}
                  placeholder="Prénom"
                />
              ) : (
                formatPrenom(user.prenom)
              )}
              {" "}
              {isEditing ? (
                <MDBInput
                  type="text"
                  className=""
                  value={user.nom}
                  onChange={(e) => setUser({ ...user, nom: e.target.value })}
                  placeholder="Nom"
                />
              ) : (
                <span className="text-uppercase" style={{ color: "#09B4BF" }}>
                  {user.nom}
                </span>
              )}
            </h1>
            {(user.role === "MANAGER" || user.role === "SUPERADMIN") && (
              <div className="pt-3">
                <p className="fw-bold">Fonction :</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={user?.managerDetails?.poste}
                    onChange={e => setUser(prevUser => ({
                      ...prevUser,
                      managerDetails: {
                        ...prevUser.managerDetails,
                        poste: e.target.value
                      }
                    }))}

                  />
                ) : (
                  <p className="me-1">{user?.managerDetails?.poste}</p>
                )}
              </div>
            )}
            {(user.role === "CLIENT") && (
              <div className="pt-3">
                <p className="fw-bold">Enterprise :</p>
                <p className="me-1">{user?.managerDetails?.poste}</p>
              </div>
            )}
            <div className="pt-3">
              <p className="fw-bold">Rôle :</p>
              {isEditing ? (
                <select
                  value={user?.role}
                  className="m-auto w-25 form-select text-muted"
                  onChange={e => setUser({ ...user, role: e.target.value })}
                >
                  <option className="text-white" value="CANDIDAT">Candidat</option>
                  <option className="text-white" value="CONSULTANT">Consultant</option>
                  <option className="text-white" value="MANAGER">Manager</option>
                  <option className="text-white" value="SUPERADMIN">Super Admin</option>
                  <option className="text-white" value="CLIENT">Client</option>
                </select>
              ) : (
                <p className="me-1">{user?.role}</p>
              )}
            </div>
            <div className="pt-3">
              <p className="fw-bold">Métiers :</p>
              {isEditing && (
                <>
                  <p className="pb-0 mb-0">Veuillez sélectionner une tribu pour chaque unité commerciale.</p>
                  <DropdownCascadeSimple
                    data={allBusinessUnits}
                    selectedBUs={selectedBUs}
                    handleBUSelect={handleBUSelect}
                    selectedTribes={selectedTribes}
                    handleTribeChange={handleTribeChange}
                    selectedMetiers={filteredSelectedMetiers}
                    handleMetierChange={handleMetierChange}
                  />
                </>
              )}
              {renderMetiers(user.businessUnits)}
            </div>
            <div className="pt-3 w-25 m-auto">
              <p className="fw-bold">E-mail :</p>
              {isEditing ? (
                <MDBInput
                  type="email"
                  value={user.email}
                  onChange={e => setUser({ ...user, email: e.target.value })}
                />
              ) : (
                <p className="me-1">{user.email}</p>
              )}
            </div>

            {(user.role === "MANAGER" || user.role === "SUPERADMIN") && (
              <div className="pt-3">
                <p className="fw-bold">Téléphone :</p>
                {isEditing ? (
                  <input
                    type="tel"
                    value={user.phone}
                    onChange={e => setUser({ ...user, phone: e.target.value })}
                  />
                ) : (
                  <p className="me-1">{user.phone}</p>
                )}
              </div>
            )}
            <div className="pt-3">
              <p className="fw-bold">Membre depuis :</p>
              {user.dateCreation ? new Date(user.dateCreation).toLocaleDateString() : ''}
            </div>
            <div className="pt-3">
              {(authenticatedUserRole === "MANAGER" || authenticatedUserRole === "SUPERADMIN") &&
                (user.role === "CONSULTANT" || user.role === "CANDIDAT") && (
                  <div className="pt-3">
                    <p className="fw-bold">Date d’expiration :</p>
                    {isEditing ? (
                      <input
                        type="date"
                        value={new Date(user.dateExpiration).toISOString().split('T')[0]}
                        onChange={(e) => {
                          const newDate = new Date(e.target.value).toISOString();
                          setUser({ ...user, dateExpiration: newDate });
                        }}
                      />
                    ) : (
                      user.dateExpiration ? new Date(user.dateExpiration).toLocaleDateString() : ''
                    )}
                  </div>
                )}
            </div>
            <div className="pt-3">
              {/* Afficher le select pour les MANAGER ou SUPERADMIN en mode d'édition lorsqu'ils consultent un profil CONSULTANT ou CANDIDAT */}
              {(isEditing && (authenticatedUserRole === "MANAGER" || authenticatedUserRole === "SUPERADMIN") &&
                (user.role === "CONSULTANT" || user.role === "CANDIDAT")) && (
                  <>
                    <p className="fw-bold">Manager :</p>
                    <select
                      className="m-auto w-25 form-select text-muted"
                      value={user.manager?.id}
                      onChange={(e) => {
                        const selectedManager = managers.find(m => m.id === parseInt(e.target.value));
                        setUser(prevUser => ({ ...prevUser, manager: selectedManager }));
                      }}
                    >
                      {managers.map(manager => (
                        <option className="text-white" key={manager.id} value={manager.id}>
                          {formatPrenom(manager.prenom)} {manager.nom.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              {(!isEditing && (user.role === "CONSULTANT" || user.role === "CANDIDAT")) && (
                <>
                  <p className="fw-bold">Manager :</p>
                  <div className="">
                    {formatPrenom(user.manager?.prenom)}{" "}
                    <span className="text-uppercase">{user.manager?.nom}</span>
                  </div>
                </>
              )}
            </div>
            <div className="pt-3">
              <p className="fw-bold">Pays de rattachement :</p>
              {isEditing ? (
                <select
                  value={user?.pays}
                  className="m-auto w-25 form-select text-muted"
                  onChange={e => setUser({ ...user, pays: e.target.value })}
                >
                  <option className="text-white">Choisissez un pays</option>
                  <option className="text-white" value="France">France</option>
                  <option className="text-white" value="Luxembourg">Luxembourg</option>
                  <option className="text-white" value="Switzerland">Suisse</option>
                  <option className="text-white" value="Belgique">Belgique</option>
                </select>
              ) : (
                <p className="me-1">{user?.pays}  <i className={flagClasses[user?.pays]}></i></p>
              )}
            </div>
            <div className="mt-3">
              {isEditing && (authenticatedUserRole === "MANAGER" || authenticatedUserRole === "SUPERADMIN") && (
                <MDBBtn className="BtnCard" onClick={() => updateUserDetails(user)}>Sauvegarder</MDBBtn>
              )}
            </div>
          </MDBCard>
        </section>
      </MDBContainer>
    </MDBContainer>
  );
}