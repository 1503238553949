import React from 'react';
import { MDBInput, MDBIcon } from "mdb-react-ui-kit"; 

function LoisirsComponent({ loisirs, onLoisirChange, onRemoveLoisir }) {
    return (
        <div className="d-flex flex-wrap gap-3" style={{ maxWidth: "100%" }}>
            {loisirs.map((loisir, index) => (
                <div key={index}>
                    <div className="d-flex align-items-center gap-1 mt-2">
                        <MDBInput
                            type="text"
                            className="form-control-input"
                            value={loisir || ""}
                            onChange={(event) => onLoisirChange(event, index)}
                        />
                        <MDBIcon
                            far
                            icon="trash-alt"
                            className="m-1 text-end text-white"
                            onClick={() => onRemoveLoisir(index)}
                            role="button"
                            size="lg"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}
export default LoisirsComponent;
