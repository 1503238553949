import React from 'react';
import SearchResult from './SearchResult';
import StarRating from '../../components/assets/StarRating';
import { toast } from 'react-toastify';
import {
    MDBIcon,
    MDBInputGroup,
} from "mdb-react-ui-kit";

function CompetencesComponent({
    categories,
    suggestions,
    groupedCompetences,
    sortedSuggestions,
    searchTerm,
    setSearchTerm,
    searchResults,
    activeSuggestionIndex,
    handleMouseEnter,
    handleKeyDown,
    addSelectedCompetence,
    handleCompetenceChange,
    removeCompetence,
    translations,
    addCompetence,
    selectedCategory,
    setSelectedCategory,
    suggestionSkills,
    setSuggestionSkills,
    handleSubmit,
    addCompetenceToCategory
}) {
    return (
        <>
            <div className="d-flex gap-2 align-items-center">
                <p className="fst-italic mt-3">
                    {translations.trouver}
                </p>
                <form onSubmit={handleSubmit} className="d-flex gap-1">
                    <input
                        type="text"
                        className="form-control text-white search-skills"
                        value={suggestionSkills}
                        onChange={(e) => setSuggestionSkills(e.target.value)}
                        placeholder="Indiquez la ici"
                        style={{ backgroundColor: 'transparent' }}
                    />
                    <button type="submit" className="btn_primary">
                        <MDBIcon far icon="paper-plane" title="Envoyer" />
                    </button>
                </form>
                <p className="fst-italic mt-3">{translations.traiter}</p>
            </div>
            <div>
                <label>{translations.titreSkills}</label>
                <div className="d-flex align-items-center gap-1">
                    <select className={`form-select select-input w-50 ${!selectedCategory ? 'border-danger' : ''}`} value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                        <option value="">{translations.selectACategory}</option>
                        {Object.keys(categories).map(categoryName => (
                            <option key={categories[categoryName]} value={categoryName}>{categoryName}</option>
                        ))}
                    </select>
                    <button type="submit" className="btn_primary" onClick={() => {
                        if (selectedCategory) {
                            addCompetence();
                        } else {
                            toast.warning(translations.selectACategory);
                        }
                    }}>
                        <MDBIcon fas icon="plus" title={translations.labels.addCategory} color='light' />
                    </button>
                    <p className="ms-3 me-1 pt-3">ou</p>
                    <div style={{ position: "relative" }}>
                        <div className="pt-3">
                            <MDBInputGroup className='mb-3' noBorder textBefore={<MDBIcon fas icon='search' className='text-white' />}>
                                <input className='form-control text-white search-skills' style={{ backgroundColor: 'transparent' }} type='text' placeholder={translations.labels.skillsRecherche} value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    onKeyDown={handleKeyDown} />
                            </MDBInputGroup>
                        </div>
                        <div className="suggestions-box-edition" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            {searchTerm && searchResults.map((result, index) => (
                                <SearchResult key={result.id} result={result} onSelect={addSelectedCompetence} isActive={index === activeSuggestionIndex} onMouseEnter={() => handleMouseEnter(index)} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {Object.keys(groupedCompetences).length > 0 && Object.keys(groupedCompetences).map((category, index) => {
                return (
                    <div className="mt-2" key={index}>
                        <div className="d-flex align-items-center gap-1">
                            <p className="h6 pt-2">{category}</p>
                            {/* <MDBIcon fas icon="plus-circle" title={translations.labels.addSkill} color='light' size='sm' onClick={() => addCompetenceToCategory(category)} /> */}
                        </div>
                        <div className="d-flex flex-wrap gap-3 mb-2" style={{ maxWidth: '100%' }}>
                            {groupedCompetences[category]?.map((competence, subIndex) => (
                                <div key={subIndex}>
                                    <div className="d-flex align-items-center gap-2" style={{ maxWidth: "100%" }}>
                                        <div className="input-container">
                                            <select
                                                autoComplete="off"
                                                className="text-white form-select select-input"
                                                style={{ width: "210px" }}
                                                value={competence.competenceName}
                                                onChange={event => handleCompetenceChange(competence.originalIndex, 'name', event.target.value)}
                                            >
                                                <option value="">{competence.competenceName ? competence.competenceName : translations.selectASkill}</option>
                                                {Array.isArray(sortedSuggestions[competence?.categorie?.id]) &&
                                                    sortedSuggestions[competence?.categorie?.id].map((suggestion, idx) => (
                                                        <option key={suggestion?.id} value={suggestion?.competenceName}>
                                                            {suggestion?.competenceName}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                            {competence.description && (
                                                <i className="fas fa-info-circle ms-1" title={competence.description}></i>
                                            )}
                                        </div>
                                        <StarRating
                                            rating={competence.rating}
                                            onChange={(rating) =>
                                                handleCompetenceChange(
                                                    competence.originalIndex,
                                                    "rating",
                                                    rating
                                                )}
                                        />
                                        <MDBIcon fas icon="trash-alt" className="pt-2" color='light' onClick={() => removeCompetence(competence.originalIndex)} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button className="btn_primary" onClick={() => addCompetenceToCategory(category)}>
                            <MDBIcon fas icon="plus-circle" color='light' size='sm' />
                            <span className='ms-1'>{translations.labels.addSkill}</span>
                        </button>
                    </div>
                );
            })}
        </>
    );
}

export default CompetencesComponent;
