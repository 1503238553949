import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import './Navbar.css';
import "../../Pages/Accueil/Accueil.css";
import { NavLink } from 'react-router-dom';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem, MDBCollapse,
  MDBIcon
} from "mdb-react-ui-kit";
import jwt_decode from 'jwt-decode';
import { http, getProfile } from "../services/httpService";
import { useDarkMode } from '../assets/DarkModeContext';
import { Switch } from "@mui/material";
import useAuth from '../SSO/useAuth';
import SimpleDropdown from "./Dropdown";

export default function NavbarClient({ setUserRole }) {
  const [showNav, setShowNav] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState({ nom: "", prenom: "", managerDetails: { poste: null } });
  const { isDarkMode, toggle } = useDarkMode();
  const [userCompany, setUserCompany] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    getProfile()
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
        fetchCompanies(data.managerDetails?.poste);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleLogout = () => {
    auth.logout();
    console.log("Déconnexion");
  };

  const fetchCompanies = async (poste) => {
    if (!poste) return;
    try {
      const token = localStorage.getItem("token");
      const response = await http('api/salesforce/aos', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const companiesData = await response.json();
      const userCompanyName = companiesData.find(company => company.accountName === poste);
      setUserCompany(userCompanyName);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decoded = jwt_decode(token);
        if (decoded.exp < Date.now() / 1000) {
          handleLogout();
        }
      }
    };

    checkTokenExpiration();

    const intervalId = setInterval(checkTokenExpiration, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const [hovered, setHovered] = useState(false);

  return (
    <MDBNavbar expand="lg" className={`bg-nav animation-fade-in ${isDarkMode ? 'navbar-dark-mode' : ''}`}
      style={{ zIndex: 1000 }}
      dark={isDarkMode}
      light={!isDarkMode}>

      <MDBContainer fluid>
        <MDBNavbarBrand href="#" className="animation-apparition-gauche">
          <div className="d-flex">
            <a href="https://www.greatplacetowork.lu/fr/certification/profils/alcyone-consulting">
              <img
                src={isDarkMode ? "/logo_1_blanc.png" : "/logo_1.png"}
                height="50"
                alt=""
                loading="lazy"
                style={{ marginBottom: "-13px", marginTop: "-14px" }}
              />
            </a>
            <a href="https://www.greatplacetowork.lu/fr/certification/palmar%C3%A8s-best-workplaces%C2%AE-luxembourg/2024?category=moyennes">
              <img
                src="/logo_gpw_2.png"
                height="50"
                alt=""
                loading="lazy"
                style={{ marginBottom: "-13px", marginTop: "-14px" }}
              />
            </a>
            <a href="https://resources.ecovadis.com/fr/documentation-solutions-ecovadis/ecovadis-medals-and-badges-fr">
              <img
                src="/logo_eco_3.png"
                height="50"
                alt=""
                loading="lazy"
                style={{ marginBottom: "-13px", marginTop: "-14px" }}
              />
            </a>
          </div>
          <Link to="/">
            <img
              src={isDarkMode ? "/logo_negatif.png" : "/logo-alcyone.png"}
              height="50"
              alt="Alcyone-logo"
              loading="lazy"
              style={{ marginLeft: "22px" }}
            />
          </Link>
        </MDBNavbarBrand>
        <MDBNavbarToggler
          type="button"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowNav(!showNav)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showNav}>
          <MDBNavbarNav className="d-flex align-items-center">
            <MDBNavbarItem>
              <NavLink
                to={`/espace-client/name/${userCompany?.accountName}`}
                activeClassName="active"
                className="navbar-link"
              >
                Mes appels d'offres
              </NavLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <NavLink
                to='/mon-compte'
                activeClassName="active"
                className="navbar-link"
              >
                Mon espace
              </NavLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
          <div className="d-flex align-items-center me-4 justify-content-center">
            <MDBIcon fas icon="sun" className={isDarkMode ? 'text-muted' : 'text-yellow'} />

            <Switch checked={isDarkMode} onChange={toggle} />

            <MDBIcon fas icon="moon" className={isDarkMode ? 'text-blue' : 'text-muted'} />
          </div>
          <SimpleDropdown />
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}
